<template>
  <div class="filter-view">
    <div class="title">Filter</div>

    <div class="group bs-flex bs-item-center">
      <el-row>
        <el-col :md="type === 'File' && isAdvisor ? 8 : 12" :xs="24">
          <div class="group-item">
            <div class="label">Subjects:</div>
            <common-prod-select
              :value="subjectArray"
              :options="subjectsStore.data"
              placeholder="All"
              multiple
              autoHeight
              @change="handleChange($event, 'subjects')"
            >
              <template v-slot:suffix>
                <div
                  v-if="subjectArray.length"
                  @click="handleChange(subjectArray, 'subjects', true)"
                  class="el-icon-circle-close"
                />
              </template>
            </common-prod-select>
          </div>
        </el-col>

        <el-col v-if="this.$authStore.isAdvisor" :md="type === 'File'  && isAdvisor ? 8 : 12" :xs="24">
          <div class="group-item">
            <div class="label">Students:</div>
            <common-prod-select
              :value="studentArray"
              :options="studentsStore.data"
              placeholder="All"
              multiple
              autoHeight
              @change="handleChange($event, 'users')"
            >
              <template v-slot:suffix>
                <div
                  v-if="studentArray.length"
                  @click="handleChange(studentArray, 'users', true)"
                  class="el-icon-circle-close"
                />
              </template>
            </common-prod-select>
          </div>
        </el-col>

        <el-col v-if="type === 'File'" :md="isAdvisor ? 8 : 12" :xs="24">
          <div class="group-item">
            <div class="label">Assignments:</div>
            <common-prod-select
              :value="taskArray"
              :options="tasksStore.data"
              placeholder="All"
              multiple
              autoHeight
              @change="handleChange($event, 'tasks')"
            >
              <template v-slot:suffix>
                <div
                  v-if="taskArray.length"
                  @click="handleChange(taskArray, 'tasks', true)"
                  class="el-icon-circle-close"
                />
              </template>
            </common-prod-select>
          </div>
        </el-col>
      </el-row>
    </div>

    <div v-if="viewings.length" class="group bs-flex bs-item-center">
      <div class="label">Viewing:</div>
      <div class="tag-group bs-flex bs-item-center">
        <div v-for="(tag, index) in viewings" :key="index" class="tag">
          <common-prod-tag :tag="tag" @delete="handleDelete" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { subjectsStore, studentsStore, tasksStore } from '@/stores';
  import { getQueryArray } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class FilterView extends Vue {
    @Prop({ type: String, default: 'Task' }) type // Task、Note、File
    @Prop({ type: Boolean, default: false }) isAdvisor

    subjectsStore = subjectsStore
    studentsStore = studentsStore
    tasksStore = tasksStore

    get viewings() {
      const obj = {
        subjects: { array: this.subjectArray, store: this.subjectsStore },
        users: { array: this.studentArray, store: this.studentsStore },
        tasks: { array: this.taskArray, store: this.tasksStore }
      };

      const list = Object.keys(obj).map((key) => {
        return obj[key].store.data.filter(item => {
          return obj[key].array.find(id => +id === +item.id);
        });
      });

      return _.flatten(list);
    }

    get subjectArray() {
      if (this.type === 'File') {
        return getQueryArray('document_taggings_taggable_of_Subject_type_id_in').map(id => +id);
      }
      return getQueryArray('subject_taggings_subject_id_in').map(id => +id);
    }

    get studentArray() {
      return getQueryArray('student_id_in').map(id => +id);
    }

    get taskArray() {
      return getQueryArray('document_taggings_taggable_of_Task_type_id_in').map(id => +id);
    }

    /**
     * inputType: subjects、users、tasks
     * val: current input value
     * isClear
     */
    handleChange(val, inputType, isClear) {
      switch (inputType) {
        case 'subjects': {
          if (this.type === 'File') {
            this.replaceQuery('document_taggings_taggable_of_Subject_type_id_in', val, isClear);
          } else {
            this.replaceQuery('subject_taggings_subject_id_in', val, isClear);
          }
          break;
        }
        case 'users': {
          this.replaceQuery('student_id_in', val, isClear);
          break;
        }
        case 'tasks': {
          this.replaceQuery('document_taggings_taggable_of_Task_type_id_in', val, isClear);
          break;
        }
      }
    }

    replaceQuery(key, array, isClear) {
      const query = {
        ...this.$route.query,
        page: 1,
      };
      let includeQuery = getQueryArray(key);

      if (_.isEmpty(array) || isClear) {
        array.length = 0;
        query[key] = undefined;
      }


      array.forEach(id => {
        if (!includeQuery.includes(String(id))) {
          query[key] = [...includeQuery, ..._.difference(array, includeQuery)];
        } else {
          _.remove(includeQuery, item => String(item) !== String(id));
          query[key] = includeQuery;
        }
      });
      this.$router.replace({ query });
    }

    handleDelete(tag) {
      const listKey = {
        subjects: 'subjectArray',
        users: 'studentArray',
        tasks: 'taskArray'
      }[tag.tn];

      this[listKey].splice(this[listKey].findIndex(id => +id === +tag.id), 1);
      this.handleChange(this[listKey], tag.tn);
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    margin-bottom: 23px;
    font-size: 24px;
    color: var(--font-secondary-black);
  }

  .group {
    ::v-deep .el-col {
      @include media-md-and-up {
        &:not(:first-child) {
          padding-left: 30px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 34px;
    }

    .label {
      flex-shrink: 0;
      width: 100px;
      font-size: 18px;
      color: var(--font-third-black);
    }
  }

  .group-item {
    display: flex;
    align-items: center;

    @include media-md-and-down {
      margin-bottom: 20px;
    }
  }

  .tag {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .el-icon-circle-close {
    cursor: pointer;
  }

  ::v-deep .input {
    width: 260PX;

    .select-wrap {
      height: 37px;
    }

    .el-input__inner {
      font-size: 18px;
    }
  }

  ::v-deep .el-select__tags {
    .el-tag {
      display: flex;
      align-items: center;
      max-width: 60%;
    }

    .el-select__tags-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
