<template>
  <div class="guide-card font-regular">
    <div class="link mobile-tip hidden-sm-and-up" @click="handleClickBtn('skip')">Skip tour</div>
    <div class="title">{{ title }}</div>
    <div class="body">
      <slot/>
    </div>
    <div class="footer bs-flex bs-content-between bs-item-center">
      <div v-if="currentStep === length - 1" class="bs-flex bs-item-center">
        <common-prod-button border @click.native="handleClickBtn('toWork')">LET'S GET TO WORK</common-prod-button>
        <div class="indicator">{{ currentStep + 1 }}/{{ length }}</div>
        <span class="link" @click="handleClickBtn('back')">Back</span>
      </div>
      <div v-else class="not-last bs-flex bs-item-center">
        <common-prod-button border @click.native="handleClickBtn('next')">NEXT</common-prod-button>
        <div class="indicator">{{ currentStep + 1 }}/{{ length }}</div>
        <span class="link" v-if="currentStep !== 0" @click="handleClickBtn('back')">Back</span>
      </div>

      <span class="link hidden-xs-only" @click="handleClickBtn('skip')">Skip tour</span>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class GuideCard extends Vue {
    @Prop(String) title;
    @Prop({ type: [String, Number], default: 0 }) currentStep;
    @Prop({ type: [String, Number], default: 0 }) length;

    handleClickBtn(val) {
      this.$emit('click-btn', val);
    }
  }
</script>

<style lang="scss" scoped>
  .guide-card {
    padding: 0 30px;

    @include media-sm-and-up {
      box-sizing: border-box;
      max-width: 663px;
      padding: 32px 39px 40px;
      background: var(--bg-primary-white) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 70px rgba(0, 0, 0, 0.16);
    }

    .title {
      font-size: 18px;
      font-family: Radwave-Regular;
      color: var(--font-primary-red);

      @include media-xs-only {
        color: var(--font-primary-white);
      }
    }

    .body {
      margin-top: 27px;
      font-size: 24px;
      color: var(--font-primary-blue);

      @include media-xs-only {
        padding-bottom: 42px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.75);
      }
    }

    .footer {
      margin-top: 30px;
      margin-bottom: 0;
    }

    ::v-deep .ato-button {
      @include media-xs-only {
        color: var(--font-seventh-purple);
        background: var(--bg-primary-white);
      }
    }

    ::v-deep .ato-button--small {
      min-width: 290px;
    }

    .not-last {
      ::v-deep .ato-button--small {
        min-width: 114px;
      }
    }

    .indicator {
      margin-left: 10px;
      color: #8a98a1;
    }

    .link {
      margin-left: 24px;
      font-weight: 500;
      font-size: 18px;
      text-decoration: underline;
      color: var(--font-primary-purple);
      cursor: pointer;

      &.mobile-tip {
        margin: 0 0 20px;
        font-size: 14px;
      }

      @include media-xs-only {
        color: var(--font-primary-gray);
      }
    }
  }
</style>
