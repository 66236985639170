<template>
  <div>
    <el-select
      :value="value"
      @change="handleChange"
      size="mini"
      placeholder="no repeat"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <common-prod-dialog :visible.sync="showCustom" append-to-body>
      <RepeatCustomForm @cancel="showCustom = false" @confirm="handleCustomConfirm"/>
    </common-prod-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import { RRule } from 'rrule';
  import RepeatCustomForm from '@/components/prod/repeat-custom-form.vue';
  import { getMonthSelectOptions } from '@/utils';
  import _ from 'lodash';

  @Component({
    components: {
      RepeatCustomForm,
    }
  })
  export default class RepeatSelect extends Vue {
    @Model('change', { type: String, default: '' }) recurrence
    @Prop() startDate

    showCustom = false
    value = ''

    get options() {
      const date = dayjs(this.startDate);
      return [
        { label: 'Does not repeat', value: '' },
        { label: 'Daily', value: 'RRULE:INTERVAL=1;FREQ=DAILY' },
        { label: `Weekly on ${date.format('dddd')}`, value: RRule.fromText(`Every Week on ${date.format('dddd')}`).toString() },
        ...getMonthSelectOptions(this.startDate),
        {
          label: `Annually on ${date.format('MMM')} ${date.format('D')}`,
          value: RRule.fromText(`Every ${date.format('MMM')} on the ${date.format('Do')}`).toString()
        },
        { label: 'Every weekday (Monday to Friday)', value: RRule.fromText('Every weekday').toString() },
        ...this.customSelectOptions,
        { label: 'Custom...', value: 'custom' },
      ];
    }

    customSelectOptions = []

    @Watch('recurrence', { immediate: true })
    startRecurrenceChange(val, old) {
      this.value = val;
      if (val && val !== old) {
        if (!this.options.find(({ value }) => val === value)) {
          this.customSelectOptions.push({
            label: _.upperFirst(RRule.fromString(val).toText()),
            value: val,
          });
        }
      }
    }

    handleChange(e) {
      if (e === 'custom') {
        this.showCustom = true;
      } else {
        this.value = e;
        this.$emit('change', e);
      }
    }

    handleCustomConfirm(item) {
      this.value = item.value;
      if (!this.options.find(({ value }) => item.value === value)) {
        this.customSelectOptions.push(item);
      }
      this.$emit('change', this.value);
      this.showCustom = false;
    }
  }
</script>

<style lang="scss" scoped>
</style>
