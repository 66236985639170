import { authStore } from '@/stores';
import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';
import _ from 'lodash';
import dayjs from 'dayjs';

class CalendarEvents extends SimpleStore {
  dataObject = {}

  get data() {
    return _.map(this.dataObject, events => events).flat();
  }

  /**
   * 按月获取数据，也按照月份缓存数据
   *
   * @param  {Date | String} start  开始时间
   * @param  {Date | String} end    结束时间
   * @param  {Number} userId        用户id
   * @param  {Boolean} beforeClear   请求到数据后，是否需要清空缓存数据，防止重复数据没有被正确缓存
   *
   * @return {Array}      时间范围内的所有events
   */
  async fetchData({ start, end, userId, beforeClear = false }) {
    const { startAt, endAt } = this.getParamsDate(start, end);
    const { data } = await request.get('schedule/events', {
      params: {
        page: 0,
        start_at_gteq: startAt,
        start_at_lteq: endAt,
        student_id_eq: userId || undefined,
      }
    });
    const events = data.map(item => this.translateApiDataToEvent(item));
    if (beforeClear) {
      this.clearEvents();
    }
    if (!end) {
      // 防止出现时间重复数据，只缓存整月数据
      _.set(this.dataObject, `${startAt} - ${endAt}`, events);
    }
    return events;
  }

  clearEvents() {
    _.map(this.dataObject, (events, key) => _.set(this.dataObject, key, null));
  }

  // 通过当前日期，获取当月数据
  fetchCurrentData(current, userId) {
    const { startAt, endAt } = this.getParamsDate(current);
    if (this.dataObject[`${startAt} - ${endAt}`] && !userId) {
      return this.dataObject[`${startAt} - ${endAt}`];
    } else {
      return this.fetchData({ start: current, userId, beforeClear: true });
    }
  }

  async tryFetchData(current, userId, isBeforeAndAfter = true) {
    if (isBeforeAndAfter) {
      const [before, middle, next] = await Promise.all([
        this.fetchCurrentData(dayjs(current).subtract(1, 'M'), userId),
        this.fetchCurrentData(current, userId),
        this.fetchCurrentData(dayjs(current).add(1, 'M'), userId),
      ]);
      return [...before, ...middle, ...next];
    } else {
      return this.fetchCurrentData(current, userId);
    }
  }

  // 只添加或者更新 event，成功后，需要手动清空缓存数据，并且重新获取event，防止重复的event没有正确显示
  async addAndUpdateEvent(body) {
    if (!body.title) {
      throw new Error('Please fill in the title');
    }
    if (authStore.isAdvisor && body.kind === 'task' && body.attendances_attributes.length === 0) {
      throw new Error('Please select a guest');
    }

    const overrides = _.get(body, 'reminders.overrides');
    if (overrides) {
      // 去掉重复的提醒
      body.reminders.overrides = [...new Set(overrides.map(item => item.minutes))].map(minutes => ({ minutes, method: 'email' }));
    }
    if (body.id) {
      await request.put(`schedule/events/${body.id}`, _.omit(body, ['id']));
    } else {
      await request.post('schedule/events', body);
    }
  }
  // 删除 event，成功后，需要手动清空缓存数据，并且重新获取event，防止重复的event没有正确显示
  async deleteEvent(id) {
    await request.delete(`schedule/events/${id}`);
  }

  translateApiDataToEvent(data) {
    const {
      title,
      start_date,
      start_time,
      end_date,
      end_time,
      is_all_day,
    } = data;
    return {
      ...data,
      name: title,
      start: is_all_day ? new Date(`${start_date}`) : new Date(`${start_date} ${start_time}`),
      end: is_all_day ? new Date(`${end_date}`) : new Date(`${end_date} ${end_time}`),
      timed: !is_all_day,
    };
  }

  getParamsDate(start, end) {
    if (end) {
      // 如果有 end 参数，就直接使用开始时间和结束时间获取数据，
      return {
        startAt: dayjs(start).format(),
        endAt: dayjs(end).format(),
      };
    } else {
      // 如果没有传end，直接使用开始时间，获取整月数据
      return {
        startAt: dayjs(start).startOf('M').format(),
        endAt: dayjs(start).endOf('M').format(),
      };
    }
  }

  /**
   * 切换任务事件的状态
   * @param  {Object} task 任务事件
   * @param  {Boolean} reopen 是否重新打开
   */
  async toggleTaskEventStatus(task, reopen) {
    const isAdvisor = _.get(authStore, 'isAdvisor');
    if (reopen) {
      await request.put(`tasks/${task.id}/reopen`);
      return;
    }
    switch (task.status) {
      case 'init':
      case 'wait_advisor_review':
        await request.put(`tasks/${task.id}/${isAdvisor ? 'advisor_complete' : 'student_complete'}`);
        break;
    }
  }
}

export const calendarEventsStore = CalendarEvents.create();
