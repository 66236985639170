<template>
  <div class="upload-input">
    <div class="label" v-if="label">{{ label }} <span class="size-tip">(A single file cannot exceed 100 MB)</span></div>
    <div class="relative upload-btn-wrapper">
      <slot v-if="$slots.default"/>
      <el-button v-else size="small" type="primary">ADD FILES</el-button>
      <input type="file" class="hide-input" :multiple="multiple" @change="handleFileChange" :accept="accept">
    </div>
    <span v-if="sizeTip" class="size-tip">{{ sizeTip }}</span>
    <div class="" v-for="item in progress" :key="item.id">
      <label class="file-input-wrapper bs-flex bs-item-center bs-content-between">
        <span class="text-overflow">{{ item.filename || item.attachment.filename }}</span>
      </label>
      <el-progress
        :percentage="item.progress"
        style="margin: 0.52466rem 0;"
      />
    </div>
    <template v-if="showFiles">
      <div v-for="item in files" :key="item.id || item.signed_id">
        <label class="file-input-wrapper bs-flex bs-item-center bs-content-between" v-if="!item._destroy">
          <span class="text-overflow text">{{ item.filename || item.attachment.filename }}</span>
          <img src="@/assets/icon-close.png" class="icon-close" @click="handleDelete(item)">
        </label>
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
  import { uploadFile } from '@/utils';
  import _ from 'lodash';

  const defaultAccepts = [
  'image/*',
  'video/*',
  'audio/*',
  'application/*',
  ];

  @Component
  export default class UnloadInput extends Vue {
    @Prop({ type: String, default: 'Upload a file' }) label
    @Prop({ type: String, default: defaultAccepts.join(',') }) accept
    @Prop({ type: Boolean, default: true }) showFiles
    @Prop({ type: Boolean, default: true }) multiple
    @Prop({ type: String, default: '' }) sizeTip
    @Model('input', { type: Array, default: () => [] }) value

    progress = {}
    files = []

    @Watch('value', { immediate: true })
    valueChange(val) {
      if (val === this.files) {
        return;
      }
      const files = [];
      _.map(val, item => {
        const attachment = _.get(item, 'attachment') || {};
        files.push({
          ..._.omit(item, ['attachment']),
          ...attachment,
          attachment: attachment.signed_id
        });
      });
      this.files = files;
      this.$emit('input', this.files);
    }

    handleFileChange(e) {
      Array.from(e.target.files).map(file => this.uploadFile(file));
      e.target.value = '';
    }

    async uploadFile(file) {
      if ((file.size / 1024 / 1024) > 100) {
        return setTimeout(() => {
          this.$message({ message: `${file.name} cannot exceed 100 MB`, type: 'error' });
        });
      }
      const res = await uploadFile(file, { onProgress: (progress, { checksum, filename }) => {
        this.$set(this.progress, checksum, { progress, filename });
      } });
      const fileInfo = {
        ..._.pick(res, ['filename', 'signed_id', 'content_type']),
        attachment: res.signed_id,
      };
      this.$emit('upload-success', { ...fileInfo });
      this.$delete(this.progress, res.checksum);
      this.files.unshift(fileInfo);
      this.$emit('input', this.files);
    }

    handleDelete(file) {
      if (file.id) {
        this.$set(file, '_destroy', true);
      } else {
        const index = this.files.findIndex(item => item.signed_id === file.id);
        this.files.splice(index, 1);
      }
      this.$emit('input', this.files);
    }
  }
</script>

<style lang="scss" scoped>
  .upload-input {
    @include media-xs-only {
      display: flex;
      flex-direction: column;
    }
  }

  .label {
    padding: 0 0 6px 17px;
    font-weight: 600;
    font-size: 18px;
    color: var(--font-secondary-black);

    @include media-xs-only {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .size-tip {
    padding-left: 12px;
    font-size: 12px;
    color: var(--font-forth-blue);

    @include media-xs-only {
      padding: 12px 0 0;
    }
  }

  .upload-btn-wrapper {
    display: inline-block;
    margin: 10px 0;

    .hide-input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .file-input-wrapper {
    box-sizing: border-box;
    padding: 10px;

    &:hover {
      background: var(--bg-primary-gray);

      .icon-close {
        display: inline-block;
      }
    }

    .text {
      font-size: 18px;
    }

    .icon-close {
      display: none;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  ::v-deep .el-button {
    color: var(--font-primary-white);
  }
</style>
