<template>
  <div class="start-page">
    <setting-nickname v-if="step === 'settingNickname'"/>
    <info-page v-if="step === 'info'"/>
    <bind-google-account v-if="step === 'bindGoogle'" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import settingNickname from './components/setting-nickname.vue';
  import infoPage from './components/info-page.vue';
  import bindGoogleAccount from './components/bind-google-account.vue';

  @Component({
    components: {
      'setting-nickname': settingNickname,
      'info-page': infoPage,
      'bind-google-account': bindGoogleAccount
    }
  })
  export default class StartPage extends Vue {
    get step() {
      return ['info', 'settingNickname', 'bindGoogle'].includes(this.$route.query.step) ? this.$route.query.step : 'info';
    }

    @Vue.autoLoadingProgress
    async created() {
    }
  }
</script>

<style lang="scss" scoped>
</style>
