import { Collection } from './helper/collection';
import { request } from '@/utils';

export class CategoriesStore extends Collection {
  fetch(params) {
    return request.get('task_categories', { params: { ...params, page: 0 } });
  }
}

export const categoriesStore = CategoriesStore.create();
