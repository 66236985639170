<template>
  <div>
    <!-- HTML -->
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';
  import { REDIRECT_URL_SESSION_KEY } from '@/constants';

  @Component
  export default class CheckLoginStatus extends Vue {
    async created() {
      try {
        await this.$authStore.tryFetchData();
      } finally {
        window.parent && window.parent.postMessage({
          isLogin: !!_.get(this.$authStore, 'user.id'),
          id: this.$route.query.id,
          url: sessionStorage.getItem(REDIRECT_URL_SESSION_KEY) || location.origin,
        }, '*');
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
