<template>
  <div>
    <div>
      <NotificationSelect
        v-for="(item, index) in notificationList"
        :key="item.key"
        :is-all-day="isAllDay"
        v-model="item.value"
        @remove="handleRemoveNotification(index)"
      />
    </div>
    <div v-show="showAddBtn">
      <el-button size="mini" plain @click="handleAddNotification">Add notification</el-button>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import _ from 'lodash';
  import NotificationSelect from './notification-select.vue';
  import { getRandomUuid } from '@/utils';

  @Component({
    components: {
      NotificationSelect
    }
  })
  export default class Notification extends Vue {
    @Model('change', { type: Array, default: () => [] }) value
    @Prop(Boolean) isAllDay

    notificationList = []

    get showAddBtn() {
      return this.notificationList.length < 5;
    }

    @Watch('isAllDay', { immediate: true })
    isAllDayChange(val, old) {
      if (val !== old) {
        this.notificationList = [];
      }
    }

    @Watch('value', { immediate: true, deep: true })
    valueChange(val) {
      const notificationList = _.map(val, item => {
        return {
          // 需要一个唯一的key 以确保顺序和删除不错删错
          key: item.key || getRandomUuid(),
          value: item.minutes
        };
      });
      if (!_.isEqual(this.notificationList, notificationList)) {
        this.notificationList = notificationList;
      }
    }

    @Watch('notificationList', { deep: true })
    notificationListChange(val) {
      this.$emit('change', _.map(val, item => {
        return {
          key: item.key,
          method: 'email',
          minutes: item.value,
        };
      }));
    }

    handleAddNotification() {
      // 最多只能添加5个提醒
      if (this.notificationList.length < 5) {
        this.notificationList.push({
          key: getRandomUuid(),
          value: this.isAllDay ? 900 : 5,
        });
      }
    }

    handleRemoveNotification(index) {
      this.notificationList.splice(index, 1);
    }
  }
</script>

<style lang="scss" scoped>
</style>
