<template>
  <transition name="el-fade-in">
    <div class="mask-wrap" v-if="showGuide && !$authStore.isAdvisor">
      <div v-for="(i, index) in tips" :key="index">
        <transition name="el-fade-in">
          <div :class="`tips tip-${i}`" v-if="index === step">
            <img class="icon-arrow hidden-xs-only" mode="widthFix" src="@/assets/icon-left.png"/>
            <img class="icon-arrow hidden-sm-and-up" mode="widthFix" src="@/assets/icon-down.png"/>
            <common-prod-guide-card class="guide"
              :title="stepContent.title"
              :currentStep="step"
              :length="tips.length"
              @click-btn="handleClick"
            >
              {{ stepContent.tip }}
            </common-prod-guide-card>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Component, Vue, Model, Watch } from 'vue-property-decorator';

  @Component
  export default class GuideTips extends Vue {
    @Model('input', Boolean) showGuide;
    step = 0
    tips = ['one', 'two', 'three', 'four', 'five', 'six']

    get stepContent() {
      return [
        {
          title: 'Log in to sessions',
          tip: 'This link will open your personal video session room so you can meet with your tutors.'
        },
        {
          title: 'FIND YOUR WAY AROUND',
          tip: 'On your Dashboard, easily find your most important upcoming tasks and see recent activity.'
        },
        {
          title: 'Stay organized',
          tip: 'Keep track of your tasks, notes, and files for all of your subjects and extracurriculars.'
        },
        {
          title: 'MANAGE YOUR TASKS AND FILES',
          tip: 'In the "Assignments" and "Files" tabs, see and discuss tasks you\'ve been assigned, and upload and access files related to them, like essay drafts.'
        },
        {
          title: 'CONTACT YOUR HEAD ADVISOR',
          tip: `${this.$get(this.$authStore, 'user.head_advisor.name')} is your Head Advisor. If you have questions or need help at any time you can send a message or request a call.`
        },
        {
          title: 'STAY ON TOP OF IT',
          tip: `When you log in, you'll be able to see tasks that are assigned to you. ${this.$get(this.$authStore, 'user.head_advisor.name')} has assigned one to you already!`
        }
      ][this.step];
    }

    @Watch('showGuide', { immediate: true })
    valueChange(val) {
      document.body.classList[val ? 'add' : 'remove']('disabled-scrolling');
    }

    @Watch('step', { immediate: true })
    stepChange() {
      this.$emit('step-change', this.step);
    }

    async handleClick(val) {
      switch (val) {
        case 'skip':
          await this.$authStore.updateIsShow('show_tool_guide', false);
          this.$emit('input', false);
          break;
        case 'back':
          this.step = --this.step;
          break;
        case 'next':
          this.step = ++this.step;
          break;
        case 'toWork': {
          await this.$authStore.updateIsShow('show_tool_guide', false);
          this.$emit('input', false);
          // “Let’s get to work” button will disable tutorial for subsequent log ins -
          this.$emit('guide-btn', val);
          break;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-wrap {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);

    @include media-xs-only {
      background: rgba(0, 0, 0, 0.75);
    }
  }

  .icon-arrow {
    width: 90px;
    height: 66px;

    @include media-xs-only {
      position: absolute;
      bottom: -88px;
      width: 22px;
      height: 64px;
    }
  }

  .guide-card {
    margin-top: -25px;
    margin-left: 64px;

    @include media-xs-only {
      margin-left: 0;
    }
  }

  .tips {
    position: absolute;
    z-index: 1200;

    @include media-xs-only {
      bottom: 176px;
      left: 0;
    }
  }

  .tip-one {
    @include media-lg-and-up {
      position: static;

      .icon-arrow {
        position: absolute;
        top: 10rem;
        right: 12%;
        transform: scaleY(-1) rotate(-120deg);
      }

      .guide {
        position: absolute;
        top: 16rem;
        right: 12%;
      }
    }

    @include media-lg-and-down {
      position: static;

      .icon-arrow {
        position: absolute;
        top: 10rem;
        left: 70%;
        transform: scaleY(-1) rotate(-120deg);
      }

      .guide {
        position: absolute;
        top: 16rem;
        left: 43%;
      }
    }

    @include media-xs-only {
      .guide {
        position: absolute;
        top: 20rem;
        left: 0;
      }

      .icon-arrow {
        top: 16rem;
        left: 30%;
        transform: scaleY(1) rotate(180deg);
      }
    }
  }

  .tip-two {
    @include media-lg-and-up {
      top: 49px;
      left: 170px;
    }

    @include media-lg-and-down {
      top: 3.5rem;
      left: 4rem;
    }

    @include media-xs-only {
      top: unset;
      left: unset;

      .icon-arrow {
        left: 5%;
      }
    }
  }

  .tip-three {
    @include media-lg-and-up {
      top: 10.5rem;
      left: 10rem;
    }

    @include media-lg-and-down {
      top: 10.5rem;
      left: 4rem;
    }

    @include media-xs-only {
      top: unset;
      left: 10%;

      .icon-arrow {
        left: 26%;
      }
    }
  }

  .tip-four {
    @include media-lg-and-up {
      top: 17.5rem;
      left: 7rem;
    }

    @include media-lg-and-down {
      top: 17.5rem;
      left: 4rem;
    }

    @include media-xs-only {
      top: unset;
      left: 10%;

      .icon-arrow {
        left: 57%;
      }
    }
  }

  .tip-five {
    @include media-sm-and-up {
      bottom: 60px;
      right: 110px;

      .icon-arrow {
        transform: rotateY(180deg);
      }

      .guide-card {
        margin-top: -176px;
        margin-left: -680px;
      }
    }

    @include media-xs-only {
      bottom: 260px;

      .icon-arrow {
        right: 8%;
      }
    }
  }

  .tip-six {
    @include media-lg-and-up {
      position: static;

      .icon-arrow {
        position: absolute;
        top: 28rem;
        left: 52%;
        transform: scaleY(-1) rotate(-120deg);
      }

      .guide {
        position: absolute;
        top: 35rem;
        left: 25%;
      }
    }

    @include media-lg-and-down {
      position: static;

      .icon-arrow {
        position: absolute;
        top: 30rem;
        left: 49%;
        transform: scaleY(-1) rotate(-120deg);
      }

      .guide {
        position: absolute;
        top: 35rem;
        left: 6%;
      }
    }

    @include media-xs-only {
      .guide {
        position: absolute;
        top: 20rem;
        left: 0;
      }

      .icon-arrow {
        top: 32rem;
        left: 14%;
        transform: scaleY(1) rotate(0);
      }
    }
  }
</style>
