<template>
  <el-select
    placeholder="请选择"
    :value="time"
    @input="$emit('change', $event)"
    :readonly="false"
    class="time-picker"
    :popper-append-to-body="false"
  >
    <input slot="prefix" :value="timeText" @change="handleChange" />
    <el-option
      v-for="(item, index) in items"
      :key="index"
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
    />
  </el-select>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import {
    nextTime,
    compareTime,
    parseText,
  } from './time-utiles';
  import dayjs from 'dayjs';


  @Component
  export default class DatePicker extends Vue {
    @Model('change', { type: String, default: '' }) value
    @Prop({ type: String, default: '-1:-1' }) minTime // 最小时间，小于该时间的时间段将被禁用
    @Prop({ type: String, default: '100:100' }) maxTime // 最大时间，大于该时间的时间段将被禁用
    @Prop({ type: String }) size

    time = null

    get timeText() {
      return dayjs(this.time, 'HH:mm').format('hh:mma');
    }

    get items() {
      const start = '00:00';
      const end = '23:59';
      const step = '00:15';
      const result = [];
      if (start && end && step) {
        let current = start;
        while (compareTime(current, end) <= 0) {
          result.push({
            value: current,
            label: dayjs(current, 'HH:mm').format('hh:mma'),
            disabled: compareTime(current, this.minTime || '-1:-1') <= 0 ||
              compareTime(current, this.maxTime || '100:100') >= 0
          });
          current = nextTime(current, step);
        }
      }
      return result;
    }

    @Watch('value', { immediate: true })
    handleValueChange(val, old) {
      if (old !== val) {
        this.time = val;
      }
    }

    handleChange(e) {
      const value  = parseText(e.target.value);
      if (value && value !== this.time) {
        this.$emit('change', value);
      } else {
        return e.target.value = this.timeText;
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep.time-picker {
    width: 90px;

    .el-scrollbar__view.el-select-dropdown__list {
      padding-left: 0;
      text-align: center;
    }

    .el-input__prefix {
      position: initial;
      display: block !important;

      input {
        width: 100%;
        height: auto;
        padding: 0;
        outline: none;
        font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
        line-height: 1.6;
        text-align: center;
        color: var(--font-primary-blue);
      }
    }

    .el-input__suffix,
    .el-input__inner {
      display: none;
    }
  }
</style>
