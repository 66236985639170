<template>
  <div class="region-input-group">
    <el-row :gutter="gutter">
      <el-col :sm="12" :xs="24" :md="md" class="input-group">
        <common-prod-input type="onlySelect" inputTitle="Country" :required="countryRequire">
          <template v-slot:selectInput>
            <el-select
              v-model="form.country_id"
              :popper-append-to-body="false"
              :placeholder="placeholder"
              filterable
              @change="handleCountryChange"
              @input="$emit('hide-error')"
            >
              <el-option
                v-for="(item, index) in countriesStore.data"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
        <slot name="errorTip"/>
      </el-col>
      <el-col :sm="12" :xs="24" :md="md" class="input-group">
        <common-prod-input type="onlySelect" inputTitle="State">
          <template v-slot:selectInput>
            <el-select
              v-model="form.state_id"
              :popper-append-to-body="false"
              :placeholder="placeholder"
              filterable
              @focus="handleFetchRegion('state')"
              @change="handleStateChange"
            >
              <el-option
                v-for="item in statesStore.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :sm="12" :xs="24" :md="md" class="input-group">
        <common-prod-input type="onlySelect" inputTitle="City">
          <template v-slot:selectInput>
            <el-select
              v-model="form.city_id"
              :popper-append-to-body="false"
              :placeholder="placeholder"
              filterable
              @focus="handleFetchRegion('city')"
            >
              <el-option
                v-for="(item, index) in citiesStore.data"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
  import { countriesStore, statesStore, citiesStore } from '@/stores';
  import _ from 'lodash';

  @Component
  export default class RegionInputGroup extends Vue {
    @Prop({ type: Number, default: 24 }) gutter
    @Prop({ type: Number, default: 12 }) md
    @Prop({ type: String, default: '' }) placeholder
    @Prop({ type: Boolean, default: false }) countryRequire

    countriesStore = countriesStore
    statesStore = statesStore
    citiesStore = citiesStore

    form = {
      country_id: '',
      state_id: '',
      city_id: ''
    }

    @Watch('$authStore.user.profile', { immediate: true })
    async profileChange(val, oldVal) {
      // profile为空代表此用户还没填写任何信息，需要拉取一下countriesStore
      if (!val) {
        countriesStore.fetchData();
        return;
      }

      if (_.isEqual(val, oldVal)) { return; }

      // 先获取一下数据，再赋值上去
      let { country_id, state_id, city_id } = this.$get(this.$authStore, 'user.profile', {});

      Object.assign(statesStore.params, { country_id_eq: country_id });
      Object.assign(citiesStore.params, { state_id_eq: state_id });

      await Promise.all([
        countriesStore.fetchData(),
        statesStore.fetchData(),
        citiesStore.fetchData()
      ]);

      this.form.country_id = country_id;
      this.form.state_id = state_id;
      this.form.city_id = city_id;
    }

    @Watch('form', { immediate: true, deep: true })
    formChange() {
      this.$emit('form-change', this.form);
    }

    async handleCountryChange() {
      await this.handleFetchRegion('state');
      this.form.state_id = this.$get(statesStore, 'data.0.id');
      if (!statesStore.isEmpty) {
        await this.handleFetchRegion('city');
        // 找不到city id的时候要传null
        this.form.city_id = this.$get(citiesStore, 'data.0.id', null);
       } else {
         this.form.state_id = null;
         this.form.city_id = null;
       }
    }

    async handleStateChange() {
      await this.handleFetchRegion('city');
      this.form.city_id = this.$get(this.citiesStore, 'data.0.id', null);
    }

    @Vue.autoLoadingProgress
    async handleFetchRegion(type) {
      switch (type) {
        case 'state': {
          Object.assign(statesStore.params, { country_id_eq: +this.form.country_id });
          await statesStore.fetchData();
          return;
        }
        case 'city': {
          Object.assign(citiesStore.params, { state_id_eq: +this.form.state_id });
          await citiesStore.fetchData();
          return;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
