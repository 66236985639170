<template>
  <div class="activity-msg-wrapper">
    <!-- dashboard消息 -->
    <el-row v-if="isDashboard" class="dashboard-msg">
      <el-col :xs="24" :md="16" :lg="18" :xl="20">
      <div class="main-content bs-flex bs-item-start">
        <div class="icon-avatar-box bs-flex bs-item-center">
          <img :src="kindIcon" alt="" class="icon-hook"/>
          <img :src="avatar" class="avatar"/>
        </div>
        <div class="text-box font-regular">
          <span v-if="welcome">
            <span v-if="!$authStore.isAdvisor">You created your account.</span> Welcome to AtomicMind!
          </span>
          <div v-else class="text-overflow">
            <span>{{ message.beforeText }}</span>
            <span class="pointer underline primary" @click="handleNav">{{ message.linkText }}</span>
            <span v-if="message.middleText" style="margin: 0.26233rem">{{ message.middleText }}</span>
            <router-link v-if="message.router2" :to="message.router2" class="pointer underline primary" style="margin-right: 0.26233rem">
              {{ message.linkText2 }}
            </router-link>
            <span v-if="message.afterText">{{ message.afterText }}</span>
          </div>
          <div v-if="plainDesc" class="desc-content font-medium">{{ plainDesc }}</div>
        </div>
      </div>
      </el-col>
      <el-col :xs="24" :md="8" :lg="6" :xl="4">
        <div class="time">{{ time | formatTime }}</div>
      </el-col>
    </el-row>
    <!-- 顶部header栏消息 -->
    <div v-else :class="['header-msg', { read: activity.read_at }]" @click="handleScanToRead">
      <img :src="avatar" class="avatar">
      <div>
        <div>
          <span>{{ message.beforeText }}</span>
          <span class="primary">{{ message.linkText }}</span>
          <span v-if="message.afterText">{{ message.afterText }}</span>
        </div>
        <div class="time">{{ time | formatTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import defaultAvatar from '@/assets/default-avatar.png';
  import { toPlainDesc } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class AcitvityMsg extends Vue {
    @Prop({ type: Object, default: () => ({}) }) activity
    @Prop(Boolean) welcome
    @Prop({ type: Boolean, default: true }) isDashboard

    get kindIcon() {
      const icon = {
        // task
        create_task: require('@/assets/message/purple-task.png'),
        update_task: require('@/assets/message/update.png'),
        reopen_task: require('@/assets/message/reopen-task.png'),
        destroy_task: require('@/assets/message/destroy.png'),
        submit_task_for_review: require('@/assets/message/purple-pending.png'),
        toggle_complete_task: require('@/assets/message/purple-completed.png'),
        // task reminder
        create_reminder: require('@/assets/message/purple-task.png'),
        update_reminder: require('@/assets/message/update.png'),
        destroy_reminder: require('@/assets/message/destroy.png'),
        submit_reminder_for_review: require('@/assets/message/purple-pending.png'),
        toggle_complete_reminder: require('@/assets/message/purple-completed.png'),
        reopen_reminder: require('@/assets/message/reopen-task.png'),
        // task comment
        create_comment: require('@/assets/message/purple-msg.png'),
        // note
        create_note: require('@/assets/message/purple-note.png'),
        update_note: require('@/assets/message/update.png'),
        destroy_note: require('@/assets/message/destroy.png'),
        // document
        create_document: require('@/assets/message/purple-file.png'),
        destroy_document: require('@/assets/message/destroy.png')
      }[this.activity.kind] || '';

      return this.welcome ? require('@/assets/message/purple-completed.png') : icon;
    }

    get message() {
      const { kind, actor } = this.activity;
      const name = this.$get(this.$authStore, 'user.id') === actor.id ? 'You' : actor.name;
      const targetId = _.get(this.activity, 'target.id');
      const documentTagging = _.get(this.activity, 'target.document_taggings.0');
      const isTask = _.get(documentTagging, 'taggable_type') === 'Task';

      return {
        // task
        create_task: {
          beforeText: `${name} added an assignment: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        update_task: {
          beforeText: `${name} updated an assignment: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        destroy_task: {
          beforeText: `${name} deleted an assignment: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        submit_task_for_review: {
          beforeText: `${name} submitted an assignment for review: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        toggle_complete_task: {
          beforeText: `${name} marked an assignment as completed: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        reopen_task: {
          beforeText: `${name} reopened an assignment: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        // task reminder
        create_reminder: {
          beforeText: `${name} added a reminder: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        update_reminder: {
          beforeText: `${name} updated a reminder: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        destroy_reminder: {
          beforeText: `${name} deleted a reminder: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        submit_reminder_for_review: {
          beforeText: `${name} submitted a reminder for review: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        toggle_complete_reminder: {
          beforeText: `${name} marked a reminder as completed: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        reopen_reminder: {
          beforeText: `${name} reopened a reminder: `,
          linkText: _.get(this.activity, 'target.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: targetId } },
        },
        // task comment
        create_comment: {
          beforeText: `${name} added a comment on: `,
          linkText: _.get(this.activity, 'target.commentable.name'),
          afterText: '.',
          router: { name: 'task.detail', params: { id: _.get(this.activity, 'target.commentable.id') } },
        },
        // note
        create_note: {
          beforeText: `${name} added a note: `,
          linkText: _.get(this.activity, 'target.title'),
          afterText: '.',
          router: { name: 'note.detail', params: { id: _.get(this.activity, 'target.id') } },
        },
        update_note: {
          beforeText: `${name} updated a note: `,
          linkText: _.get(this.activity, 'target.title'),
          afterText: '.',
          router: { name: 'note.detail', params: { id: _.get(this.activity, 'target.id') } },
        },
        destroy_note: {
          beforeText: `${name} deleted a note: `,
          linkText: _.get(this.activity, 'target.title'),
          afterText: '.',
          router: { name: 'note.detail', params: { id: _.get(this.activity, 'target.id') } },
        },
        // document
        create_document: {
          beforeText: `${name} uploaded a file: `,
          linkText: _.get(this.activity, 'target.attachment.filename'),
          router: _.get(this.activity, 'target.attachment.url'),
          middleText: isTask ? 'to' : '',
          linkText2: isTask ? `${_.get(documentTagging, 'taggable_name')}` : '',
          afterText: isTask ? 'assignment' : '',
          router2: isTask ? { name: 'task.detail', params: { id: _.get(documentTagging, 'taggable_id') } } : '',
        }
      }[kind] || {};
    }

    get plainDesc() {
      const { plain_desc, content } = _.get(this.activity, 'target');
      return toPlainDesc(plain_desc || content);
    }

    get avatar() {
      let header;
      if (this.welcome) {
        header = this.$authStore.isAdvisor ? this.$get(this.$authStore, 'user.current_role.avatar.url') : this.$get(this.$authStore, 'user.avatar.url');
      } else {
        header = this.$get(this.activity, 'actor.avatar.url');
      }
      return header || defaultAvatar;
    }

    get time() {
      return this.welcome ? new Date() : this.activity.created_at;
    }

    async handleScanToRead() {
      if (!this.activity.read_at) {
        this.activity.read_at = true;
        await this.$autoLoading(this.$request.put(`/message/items/${this.activity.id}/read`));
        _.set(this.$authStore, 'user.unread_message_items_count', this.$get(this.$authStore, 'user.unread_message_items_count') - 1);
      }
      this.handleNav();
    }

    async handleNav() {
      const { router } = this.message;
      if (this.activity.kind === 'create_document') {
        window.open(router);
      } else if (!_.isEqual(router, _.pick(this.$route, ['name', 'params']))) {
        this.$router.push(router);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .underline {
    text-decoration: underline;
  }

  .pointer {
    cursor: pointer;
  }

  .primary {
    color: var(--font-primary-purple);
  }

  .dashboard-msg {
    font-size: 18px;

    .main-content {
      @include media-xs-only {
        align-items: flex-start;
      }
    }

    .icon-avatar-box {
      @include media-sm-and-up {
        width: 120px;
      }

      .icon-hook {
        width: 32px;
        height: 32px;

        @include media-xs-only {
          width: 25px;
          height: 25px;
        }
      }

      .avatar {
        width: 50px;
        height: 50px;
        margin: 0 16px;
        border-radius: 50%;
        object-fit: cover;

        @include media-xs-only {
          width: 32px;
          height: 32px;
          margin: 0 9px 0 11px;
        }
      }
    }

    .text-box {
      flex: 1;
      padding-top: 2px;
      overflow: hidden;
      font-size: 22px;
      color: var(--font-primary-blue);

      @include media-sm-and-up {
        font-size: 24px;
      }
    }

    .time {
      font-size: 16px;
      text-align: right;
      color: var(--font-secondary-black);

      @include media-xs-only {
        padding-top: 14px;
        padding-left: 80px;
        font-size: 12px;
        text-align: left;
      }
    }

    .desc-content {
      box-sizing: border-box;
      margin-top: 2px;
      font-size: 16px;
      color: var(--font-primary-blue);

      @include text-overflow(2);

      @include media-xs-only {
        font-size: 14px;
      }
    }
  }

  .header-msg {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;

    &::before {
      flex-shrink: 0;
      width: 1PX;
      height: 1PX;
      margin-left: 5px;
      border-radius: 50%;
      background: var(--bg-primary-red);
      transform: scale(7.5);
      content: '';
    }

    &.read:before {
      background: var(--bg-secondary-gray);
    }

    .avatar {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      margin: 0 5px 0 13px;
      border-radius: 50%;
    }

    .time {
      margin-top: 4px;
      font-size: 12px;
      color: var(--font-forth-blue);
    }
  }
</style>
