import Vue from 'vue';
import { themeStore } from '@/stores';

Vue.mixin({
  data() {
    return {
      screenHeight: ''
    };
  },
  computed: {
    $isDarkTheme: () => themeStore.isDarkTheme
  },
  methods: {
    // 切换黑夜模式
    $toggleDarkTheme: () => themeStore.toggleDarkTheme(),

    // 获取当前页面的高度
    getScreenHeight() {
      this.screenHeight = `${window.innerHeight}px`;
      return `${window.innerHeight}px`;
    },

    // 初始化页面高度并监听resize事件
    $initScreenHeight() {
      this.getScreenHeight();
      window.addEventListener('resize', this.getScreenHeight);
    },

    // 移除监听resize事件
    $destroyScreenHeight() {
      window.removeEventListener('resize', this.getScreenHeight);
    },
  }
});
