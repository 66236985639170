import { Collection } from './helper/collection';
import { request } from '@/utils';

export class SchoolStudentsStore extends Collection {
  fetch(params) {
    // 一个学生的学校不会特别多，这里一次性全部拿完
    return request.get('school_students', { params: { ...params, page: 0 } });
  }

  async add(school_id) {
    const { data } = await request.post('school_students', {
      school_id,
      is_target: this.params.is_target_eq,
    });
    this.unshift(data);
  }

  async update(id) {
    const { data } = await request.put(`school_students/${id}`, { is_target: this.params.is_target_eq });
    this.unshift(data);
  }
}

export const schoolStudentsStore = SchoolStudentsStore.create();
