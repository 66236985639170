<template>
  <div class="group bs-flex bs-item-center">
    <el-select
      style="width: 14.16579rem;"
      size="mini"
      :value="value"
      @change="$event => handleSelectChange($event)"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <el-button
      class="close-btn"
      size="mini"
      circle
      icon="el-icon-close"
      @click="$emit('remove')"
    />
    <common-prod-dialog :visible.sync="showCustom" append-to-body title="Custom notification">
      <div class="custom-form">
        <div class="bs-flex bs-item-center">
          <el-input
            v-model="customFrom.value"
            min="1"
            size="mini"
            type="number"
            class="number-input"
          />
          <el-select
            class="select"
            size="mini"
            v-model="customFrom.type"
          >
            <el-option
              v-for="item in customFormOptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <TimeSelect v-model="customFrom.time" v-if="isAllDay" size="mini"/>
        </div>
        <div class="btns-wrapper">
          <el-button plain size="mini" type="info" @click="showCustom = false">Cancel</el-button>
          <el-button plain size="mini" type="primary" @click="handleCustomSubmit">Done</el-button>
        </div>
      </div>
    </common-prod-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import TimeSelect from '../date-picker/time.vue';
  import { translateNotificationLabel } from '../../utils/translate-notification-label';

  @Component({
    components: {
      TimeSelect,
    }
  })
  export default class NotificationSelect extends Vue {
    @Model('change', { type: [Number, String], default: '' }) value
    @Prop({ type: Boolean }) isAllDay

    showCustom = false
    customFrom = {
      value: 1,
      type: 24 * 60,
      time: '09:00',
    }
    get customFormOptions() {
      const options = [{ label: 'days', value: 24 * 60 }, { label: 'weeks', value: 7 * 24 * 60 }];
      return this.isAllDay ? options : [{ label: 'minutes', value: 1 }, { label: 'hours', value: 60 }, ...options];
    }

    allDayCustomOptions = []
    customOptions = []

    get options() {
      return this.isAllDay ? [
        // 查看 Google event 数据，当天的提醒，reminders 没有 overrides 字段，只有"useDefault": false
        // 暂不清楚 Google 是如何实现的当天提醒
        // { label: 'On the same day at 09:00', value: 0 },
        { label: 'The day before at 09:00', value: 15 * 60 },
        { label: '2 days day before at 09:00', value: (15 + 24) * 60 },
        { label: '1 week day before at 09:00', value: (15 + 24 * 6) * 60 },
        ...this.allDayCustomOptions,
        { label: 'Custom...', value: 'custom' },
      ] : [
        { label: '5 minutes before', value: 5 },
        { label: '10 minutes before', value: 10 },
        { label: '15 minutes before', value: 15 },
        { label: '30 minutes before', value: 30 },
        { label: '1 hour before', value: 60 },
        { label: '1 day before', value: 24 * 60 },
        ...this.customOptions,
        { label: 'Custom...', value: 'custom' },
      ];
    }

    @Watch('isAllDay', { immediate: true })
    isAllDayChange(val, old) {
      if (typeof old === 'boolean' && val !== old) {
        this.$emit('change', this.options[0].value);
      }
    }

    @Watch('value', { immediate: true })
    valueChange(val) {
      if (!val) {
        this.$emit('change', this.options[0].value);
        return;
      } else if (!this.options.find(item => item.value === val)) {
        const options = this.isAllDay ? this.allDayCustomOptions : this.customOptions;
        options.push({ label: translateNotificationLabel(val, this.isAllDay), value: val });
      }
    }

    handleSelectChange(value) {
      if (value === 'custom') {
        // 自定义弹窗
        this.showCustom = true;
        this.customFrom = this.isAllDay ? {
          value: 1,
          type: 24 * 60,
          time: '09:00',
        } : {
          value: 10,
          type: 1,
        };
      } else {
        this.$emit('change', value);
      }
    }

    handleCustomSubmit() {
      let minutes = this.customFrom.value * this.customFrom.type;
      if (this.isAllDay) {
        const [h, m] = this.customFrom.time.split(':');
        // 如果是全天，通知时间需要减去一天，显示时间需要比通知时间多一天
        minutes -= (Number(h) * 60 + Number(m));
      }
      this.checkMinutes(minutes, this.customFrom.type);
      if (!this.options.find(({ value }) => value === minutes)) {
        const options = this.isAllDay ? this.allDayCustomOptions : this.customOptions;
        options.push({ label: translateNotificationLabel(minutes, this.isAllDay), value: minutes });
      }
      this.$emit('change', minutes);
      this.showCustom = false;
    }

    checkMinutes(minutes, type) {
      if (minutes > 40320) {
        let errMsg = '';
        switch (type) {
          case 1:
            errMsg = 'Must be between 1 and 40,320 minutes';
            break;
          case 60:
            errMsg = 'Must be between 1 and 672 hours';
            break;
          case 24 * 60:
            errMsg = 'Must be between 1 and 28 days';
            break;
          case 7 * 24 * 60:
            errMsg = 'Must be between 1 and 4 weeks';
            break;
        }
        this.$alertMsg(errMsg);
        throw new Error(errMsg);
      }
    }
  }
</script>

<style lang="scss" scoped>
.group {
  .close-btn {
    display: none;
    margin-left: 20px;
  }

  &:hover {
    .close-btn {
      display: block;
    }
  }
}

.custom-form {
  .number-input {
    width: 100px;
    margin-right: 10px;
  }

  .select {
    width: 120px;
    margin-right: 10px;
  }

  ::v-deep {
    .el-input__inner {
      line-height: 34px;
    }
  }

  .btns-wrapper {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
