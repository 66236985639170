<template>
  <div class="header-search bs-flex bs-item-center">
    <common-prod-input
      v-model.trim="searchForm.keyword"
      class="header-input bs-flex-1"
      :placeholder="`Search for ${searchType}`"
      @keyup.enter.native="handleSearch"
    >
      <template v-slot:selectInput>
        <el-select
          v-model.trim="searchForm.type"
          :popper-append-to-body="false"
          placeholder=""
        >
          <el-option
            v-for="(item, index) in searchCatList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-slot:suffix>
        <i class="el-icon-search search-icon" @click="handleSearch"/>
      </template>
    </common-prod-input>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class SearchInput extends Vue {
    searchCatList = [
      { label: 'Assignments', value: 'tasks' },
      { label: 'Notes', value: 'notes' },
      { label: 'Files', value: 'files' },
      { label: 'Reminders', value: 'reminders' },
      // 隐藏schedule入口
      // { label: 'Event', value: 'schedule' }
    ]
    searchForm = {
      type: 'tasks',
      keyword: ''
    }

    get searchType() {
      return _.toLower(_.find(this.searchCatList, ({ value }) => value === this.searchForm.type).label || '');
    }

    handleSearch() {
      // 如果学生没有 advisor 提示去联系 team
      if (this.$authStore.isUnpaidStudent) {
        return this.$alertMsg('We are setting up your account. Thanks for your patience.');
      }
      if (this.$route.name !== this.searchForm.type || this.$get(this.$route, 'query.keyword') !== this.searchForm.keyword) {
        this.$router.push({ name: this.searchForm.type, query: { ...this.$route.query, keyword: this.searchForm.keyword } });
      }
      this.$emit('close');
    }
  }
</script>

<style lang="scss" scoped>
  .header-search {
    background-color: var(--bg-primary-white);

    .search-icon {
      display: inline-block;
      width: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  ::v-deep .input {
    .input-wrap {
      height: 35px;
      border: 1px solid var(--border-secondary-black);
      border-radius: 4px;
      font-size: 20px;

      .select-wrap {
        &.only {
          width: 45%;

          @include media-xs-only {
            width: 40%;
          }
        }
      }
    }

    input {
      padding-left: 0;
      font-size: 20px;
    }

    .el-select {
      .el-input__inner {
        padding: 0;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
      }

      .el-select-dropdown {
        border: 1px solid var(--border-secondary-black);
      }

      .el-scrollbar {
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          font-size: 20px;

          @include media-xs-only {
            padding: 0 10px;
          }
        }
      }
    }

    .el-input__icon {
      line-height: 35px;
    }
  }
</style>
