<template>
  <div class="card bs-flex bs-column">
    <div v-if="!$slots.header" class="title">{{ title }}</div>
    <slot name="header"/>
    <div class="body bs-flex-1 bs-flex bs-column">
      <span v-if="isNothing" class="nothing">Nothing to see here...</span>
      <slot/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Card extends Vue {
    @Prop(String) title // 卡片标题
    @Prop(Boolean) isNothing
  }
</script>

<style lang="scss" scoped>
  .card {
    padding: 35px;
    border-radius: 6px;
    background: var(--bg-primary-gray);

    @include media-xs-only {
      padding: 27px 16px 20px;
      margin-bottom: 28px;
    }

    .title {
      font-size: 18px;
      font-family: 'Radwave-Regular';
      color: var(--font-primary-red);
    }

    .body {
      padding: 40px 0;

      @include media-xs-only {
        padding: 20px 0;
      }
    }

    .nothing {
      font-style: italic;
      font-size: 24px;
      color: var(--font-secondary-gray);
    }
  }
</style>
