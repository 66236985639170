import { REDIRECT_URL_SESSION_KEY, GOOGLE_REDIRECT_URL } from '@/constants';
import { request } from './request';
import router from '../router';

export async function handleBindGoogle() {
  const { data } = await request.get('auth/google/authorize_url', {
    params: {
      redirect_url: GOOGLE_REDIRECT_URL,
    }
  });
  const route = router.currentRoute;
  route.query.redirect_uri && sessionStorage.setItem(REDIRECT_URL_SESSION_KEY, route.query.redirect_uri);
  location.href = data;
}
