<template>
  <div class="started-header">
    <div class="logo-wrap">
      <img class="logo" src="@/assets/logo-color.png" alt="">
    </div>

    <div @click="handleLogout" class="sign-out">Sign out</div>

  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import DropdownCard from '@/components/common-layout/dropdown-card.vue';

  @Component({
    components: {
      'dropdown-card': DropdownCard
    }
  })
  export default class StartHeader extends Vue {
    async handleLogout() {
      await this.$confirm('Confirm logout？');
      await this.$autoLoading(this.$authStore.logout());
      this.$router.replace({ name: 'login', query: { logout: Date.now() } });
    }
  }
</script>

<style lang="scss" scoped>
  .started-header {
    position: relative;
    padding: 26px;
    text-align: center;
    background-color: var(--bg-primary-gray);

    @include media-xs-only {
      padding: 16px 0;
    }

    .logo-wrap {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }

    .logo {
      width: 304px;
      height: 26px;

      @include media-xs-only {
        width: 220px;
        height: 19px;
      }
    }

    .sign-out {
      float: right;
      font-size: 18px;
      color: var(--font-primary-red);
      cursor: pointer;
    }
  }

  .dropdown-card {
    top: 80px;
  }
</style>
