import dayjs from 'dayjs';
import { RRule } from 'rrule';

export function getMonthSelectOptions(date) {
  date = dayjs.isDayjs(date) ? date : dayjs(date);
  // 获取当前为本月第几周 1 - 5
  const weekNumber = Math.ceil(date.date() / 7);
  const weekOptions = [];
  // 如果当前周数是第五周，只需要显示最后一周的选项,
  if (weekNumber >= 5) {
    weekOptions.push({
      label: `Monthly on the last ${date.format('dddd')}`,
      value: RRule.fromText(`Every month on the last ${date.format('dddd')}`).toString(),
    });
  } else {
    const week = ['first', 'second', 'third', 'fourth'][weekNumber - 1];
    weekOptions.push({
      label: `Monthly on the ${week} ${date.format('dddd')}`,
      value: RRule.fromText(`Every month on the ${weekNumber}nd ${date.format('dddd')}`).toString(),
    });
    // 获取当前月总天数 1 - 31
    const endDate = date.clone().endOf('month').date();
    // 如果当前周数是第四周，同时也是本月最后七天内，就需要同时显示第四周和最后一周的选项
    if (endDate - date.date() <= 7) {
      weekOptions.push({
        label: `Monthly on the last ${date.format('dddd')}`,
        value: RRule.fromText(`Every month on the last ${date.format('dddd')}`).toString(),
      });
    }
  }
  return weekOptions;
}
