import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: require('@/views/dashboard').default,
    meta: {
      needAdvisor: true,
      hiddenLayout: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: require('@/views/login').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/login/simple_sso',
    name: 'login.simpleSso',
    component: require('@/views/login/simple-sso').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/reset_password',
    name: 'resetPassword',
    component: require('@/views/reset-password').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/check_login_status',
    name: 'checkLoginStatus',
    component: require('@/views/check-login-status').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/welcome_page',
    name: 'welcomePage',
    component: require('@/views/welcome-page').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/start_page',
    name: 'startPage',
    component: require('@/views/start-page').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/callback_google',
    name: 'callbackGoogle',
    component: require('@/views/start-page/redirect').default,
    meta: {
      hiddenLayout: true
    }
  },
  {
    path: '/assignments',
    name: 'tasks',
    component: require('@/views/tasks').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/assignments/reminders',
    name: 'reminders',
    component: require('@/views/tasks/reminders').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/assignments/:id',
    name: 'task.detail',
    component: require('@/views/tasks/detail').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/files',
    name: 'files',
    component: require('@/views/files').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/students',
    name: 'students',
    component: require('@/views/students').default,
  },
  {
    path: '/students/:id',
    name: 'student.detail',
    component: require('@/views/students/detail').default,
  },
  {
    path: '/profile',
    name: 'profile',
    component: require('@/views/profile').default,
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: require('@/views/preferences').default,
  },
  {
    path: '/billing',
    name: 'billing',
    component: require('@/views/billing').default,
  },
  {
    path: '/message_list',
    name: 'messageList',
    component: require('@/views/message-list').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/backpack',
    name: 'backpack',
    component: require('@/views/my-backpack').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/team',
    name: 'team',
    component: require('@/views/my-team').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: require('@/views/calendar').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/notes',
    name: 'notes',
    component: require('@/views/notes').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '/notes/:id',
    name: 'note.detail',
    component: require('@/views/notes/detail').default,
    meta: {
      needAdvisor: true,
    }
  },
  {
    path: '*',
    name: '404',
    component: require('@/views/404').default,
    meta: {
      hiddenLayout: true
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
});

router.beforeEach(async (to, form, next) => {
  const paramsId = to.params.id;
  // 统一params.id 为number 类型
  paramsId && (to.params.id = parseInt(paramsId));

  // 切换路由时，允许滚动
  document.body.classList.remove('disabled-scrolling');

  const { authStore } = require('@/stores');
  if (new RegExp(`https?://${location.host}/?`).test(process.env.VUE_APP_LOGIN_HOST) && to.name !== 'login') {
    next({ name: 'login' });
  } else if (['login', 'login.simpleSso', 'welcomePage', 'startPage', 'callbackGoogle'].includes(to.name)) {
    next();
  } else if (to.name === 'resetPassword') {
    // 防止非首次登录，输入网址后略过当前密码去修改密码
    await authStore.tryFetchData();
    _.get(authStore.user, 'force_update_password') ? next() : router.replace({ name: '404' });
  } else if (authStore.isFulfilled) {
    if (_.get(to, 'meta.needAdvisor') && authStore.isUnpaidStudent) {
      return next({ name: 'billing' });
    }
    next(_.get(authStore.user, 'extended_info.show_start_page') ? { name: 'welcomePage' } : undefined);
  } else {
    // 防止首次进入 401，获取不到当前页面正确的 path
    next();
    await authStore.tryFetchData();
    if (_.get(authStore.user, 'extended_info.show_start_page')) {
      router.replace({ name: 'welcomePage' });
    }
  }
});

export default router;
