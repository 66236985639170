<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class Dialog extends Vue {
    render() {
      const props = {
        width: window.innerWidth > 500 ? '50%' : '90%',
        destroyOnClose: true,
        ...this.$attrs
      };

      return <el-dialog
        class="calendar-dialog"
        props={props}
        on={this.$listeners}
      >
        { _.map(this.$scopedSlots, item => item()) }
      </el-dialog>;
    }
  }
</script>

<style lang="scss" scoped>
  .calendar-dialog {
    ::v-deep {
      .el-dialog__title {
        color: inherit;
      }
    }
  }
</style>
