<template>
  <div>
    <router-link :to="{ name: 'notes' }" class="back-btn">Back to all notes</router-link>

    <div v-if="note.id" class="header-wrap bs-flex bs-item-center bs-content-between">
      <div class="main-info bs-flex bs-item-center">
        <h1 class="title text-overflow-2">{{ note.title }}</h1>
        <common-switch v-if="!$authStore.isAdvisor" v-model="note.is_public"
          activeText="Public"
          inactiveText="Private"
          class="switch-wrap"
          @click-change="handleSwitch"
        />
      </div>

      <div class="time-and-buttons bs-flex bs-item-center bs-content-between">
        <div class="time bs-flex bs-column bs-item-center">
          <div>{{ note.updated_at | formatTime('MMM DD, YYYY') }}</div>
          <div v-if="$get(note, 'user.id') !== $authStore.user.id">(created by {{ $get(note, 'user.name') }})</div>
        </div>
        <div v-if="note.user_id === $authStore.user.id" class="btn-group bs-flex bs-item-center bs-content-between">
          <div @click="showEditNote = true" class="note-btn">Edit</div>
          <div @click="handleDelete" class="note-btn">Delete</div>
        </div>
      </div>
    </div>

    <common-richtext-editor v-if="note.content" v-model="note.content" class="content-wrap" onlyRead/>
    <div v-else class="empty-tip">not described</div>

    <common-popup v-model="showEditNote" title="Edit a note">
      <note-form :noteForm="note" :submit="handleEditNote" @close="showEditNote = false" />
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import NoteForm from './components/note-from.vue';

  @Component({
    components: {
      'note-form': NoteForm
    }
  })
  export default class Detail extends Vue {
    note = {}
    showEditNote = false

    @Vue.autoLoadingProgress
    async created() {
      try {
        const { data } = await this.$request.get(`notes/${this.$route.params.id}`);
        this.note = data;
      } catch (e) {
        // 该note删除之后会找不到，去到404页面
        if (e.status === 404) {
          this.$MessageBox({
            title: 'Tips',
            message: 'The note has been deleted or the resource is not found',
            type: 'error',
          }).then(() => {
            this.$router.go(-1);
          });
        }
      }
    }

    async handleSwitch() {
      await this.$autoLoading(this.$request.put(`notes/${this.$route.params.id}`, { is_public: this.note.is_public }));
    }

    async handleDelete() {
      await this.$confirm('Are you sure to delete this note?');
      await this.$autoLoading(this.$request.delete(`notes/${this.$route.params.id}`));
      this.$message.success({ message: 'Congratulations, the note is deleted!' });
      this.$router.replace({ name: 'notes' });
    }

    @Vue.autoLoading
    async handleEditNote(note) {
      const { data } = await this.$request.put(`notes/${this.$route.params.id}`, note);
      this.note = data;
    }
  }
</script>

<style lang="scss" scoped>
  .back-btn {
    font-size: 18px;
    text-decoration: underline;
    color: var(--font-seventh-purple);
  }

  .header-wrap {
    margin: 13px 0 23px;

    @include media-xs-only {
      flex-direction: column;
    }

    .main-info {
      width: 70%;

      @include media-xs-only {
        justify-content: space-between;
        width: 100%;
      }
    }

    .time-and-buttons {
      width: 30%;

      @include media-xs-only {
        width: 100%;
        margin-top: 16px;
      }
    }

    .title {
      margin: 0;
      font-weight: normal;
      font-size: 40px;
      font-family: 'Neue Montreal Medium';
      line-height: 44px;
      color: var(--font-secondary-black);
    }

    .switch-wrap {
      height: 30px;
      margin: 0 57px;
      border: 2px solid var(--border-primary-black);
      font-size: 13px;
      background: var(--bg-primary-white);

      @include media-xs-only {
        margin: 0;
      }

      &.active {
        ::v-deep .cancel {
            color: var(--font-primary-black);
        }
      }

      ::v-deep .confirm,
      ::v-deep .cancel {
        padding: 8px 20px;
      }

      ::v-deep .cancel {
          color: var(--font-primary-white);
      }

      ::v-deep .switch-inner {
        background: var(--bg-primary-purple);
      }
    }

    .time {
      white-space: nowrap;
      color: var(--font-seventh-gray);
    }

    .btn-group {
      margin-left: 51px;

      .note-btn {
        font-size: 16px;
        text-align: center;
        text-decoration: underline;
        color: var(--font-seventh-gray);
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 51px;
        }
      }
    }
  }

  .empty-tip {
    padding: 20px;
    font-size: 24px;
    text-align: center;
    color: var(--font-forth-blue);
  }

  .content-wrap {
    width: 50%;

    @include media-md-and-down {
      width: 100%;
    }
  }
</style>
