<template>
  <div class="bs-flex bs-item-center" :class="{ overdue }">
    <div v-if="task.status" class="bs-flex bs-item-center">
      <span
        v-if="task.status === 'init' || task.status === 'completed'"
        @click="handleFinishTask"
        class="checkbox"
        :class="{ 'finish': task.status === 'completed'}"
      >
        <img v-if="task.status === 'completed'" src="@/assets/complete.png" alt="">
      </span>
      <span v-if="task.status === 'wait_advisor_review'" class="review">
        <img src="@/assets/icon-review.png" alt="">
      </span>
    </div>
    <component
      :is="to ? 'router-link' : 'div'"
      :to="to"
      class="text-overflow list-name"
      :class="{ 'finish-link': task.status === 'completed' }"
      @click="$emit('click-link')"
    >
      {{ task.name }}
    </component>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { studentsStore } from '@/stores';
  import dayjs from 'dayjs';

  @Component
  export default class TaskStatus extends Vue {
    @Prop([String, Object]) to
    @Prop({ type: Object, default: () => ({}) }) task
    @Prop(Boolean) showOverdueStyle

    get overdue() {
      const { status, due_at } = this.task;
      return this.showOverdueStyle && status === 'init' && dayjs(due_at).isBefore(dayjs());
    }

    async handleFinishTask() {
      const isAdvisor = this.$authStore.isAdvisor;
      const confirmTexts = isAdvisor ? {
        title: 'Confirmation',
        text: `Confirm that ${studentsStore.findItemById(this.task.student_id).name} has completed this assignment?`,
        cancelText: 'No, it still needs work'
      } : {
        title: 'Done?',
        text: 'Submit all files associated with this assignment to your advisior for review?',
        cancelText: 'No, I forgot something'
      };

      await this.$confirm(confirmTexts.text, confirmTexts.title, {
        confirmButtonText: 'YES',
        cancelButtonText: confirmTexts.cancelText,
      });
      this.$emit('finish');
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox {
    display: inline-block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-right: 33px;
    border: 2PX solid var(--border-primary-purple);
    background: transparent;
    transition: all .3s;
    cursor: pointer;

    &.finish {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--border-fifth-gray);
      text-align: center;
      vertical-align: middle;
      background: var(--bg-fifth-gray);
      pointer-events: none;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .review {
    display: inline-block;
    margin-right: 33px;
    pointer-events: none;

    img {
      width: 22px;
      height: 22px;
    }
  }

  .list-name {
    font-size: 24px;
    text-decoration: underline;
    color: var(--font-primary-purple);
    cursor: pointer;

    &.finish-link {
      text-decoration: line-through;
      color: var(--font-fifth-gray);
    }
  }

  .overdue {
    .checkbox {
      border-color: var(--border-primary-red);
    }

    .list-name {
      color: var(--border-primary-red);
    }
  }
</style>
