import { SimpleStore } from './simple-store';
import router from '@/router';
import _ from 'lodash';
import { getQueryArray } from '@/utils';

export class Collection extends SimpleStore {
  static defaultParams = {
    page: 1,
    per_page: 10,
  }

  meta = { total: 0, page: 1, offset: 0 }
  data = []
  $params = Object.assign({}, this.constructor.defaultParams, this.params)

  fetchData(params) {
    const page = _.get(router, 'currentRoute.query.page', 1);
    const per_page = _.get(router, 'currentRoute.query.per_page');
    _.merge(this.params, {
      offset: 0,
      page,
      per_page,
    });
    return this.fetching(async () => {
      const { data, meta } = await this.fetch({ ...this.params, ...params });
      this.meta = meta;
      this.data = data;
    });
  }

  /**
   * 处理query参数
   *
   * @param  {Array || String} keys 需要处理的参数或参数数组
   *
   */
  setQueryToParams(keys) {
    if (keys instanceof Array) {
      keys.map(key => {
        this.params[key] = /_in$/g.test(key) ? getQueryArray(key) : _.get(router, `currentRoute.query.${key}`);
      });
    } else if (keys && typeof keys === 'string') {
      this.params[keys] = /_in$/g.test(keys) ? getQueryArray(keys) : _.get(router, `currentRoute.query.${keys}`);
    }
  }

  fetchMoreData(params = {}) {
    if (this.isFetching || this.isComplete) {
      return;
    }
    this.params.offset = this.data.length;
    return this.fetching(async () => {
      const { data, meta } = await this.fetch({ ...this.params, ...params });
      this.meta = meta;
      this.data.push(...data);
    });
  }

  resetData() {
    this.isFulfilled = false;
    this.data = [];
  }

  unshift(item) {
    this.data.unshift(item);
    this.meta.total += 1;
  }

  findItemById(id) {
    return this.data.find(item => +item.id === +id);
  }

  removeItemById(id) {
    const index = this.data.findIndex(item => Number(item.id) === Number(id));
    if (index !== -1) {
      this.data.splice(index, 1);
    }
  }

  replaceItem(newItem) {
    const index = this.data.findIndex(item => +item.id === +newItem.id);
    if (index > -1) {
      this.data.splice(index, 1, newItem);
    }
  }

  get params() {
    return this.$params;
  }

  set params(properties) {
    this.$params = Object.assign({}, this.constructor.defaultParams, properties);
  }

  get isComplete() {
    return this.isFulfilled && this.data.length >= this.meta.total;
  }

  get isEmpty() {
    return this.isFulfilled && this.data.length === 0;
  }
}
