<template>
  <div class="common-header">
    <div class="content" :class="{'show-only-icon': showOnlyIcon}">
      <router-link :to="{ name: 'home' }" v-show="showOnlyIcon" class="hidden-sm-and-up">
        <img class="logo" src="@/assets/atomicmind-grow-logo.svg" mode="widthFix"/>
      </router-link>

      <!-- <div class="hidden-xs-only">
        <clock :timezone="$authStore.studentTimeZone" />
      </div> -->

      <div class="header-row bs-flex bs-item-center bs-content-end">
        <search-input class="hidden-xs-only"></search-input>
        <img @click="showSearchCard = !showSearchCard" class="white-search-icon hidden-sm-and-up" src="@/assets/header/white-search.png" alt=""/>
        <div class="hidden-xs-only header-msg" @click="showMsgCard = !showMsgCard">
          <template v-if="$get($authStore, 'user.unread_message_items_count')">
            <img src="@/assets/header/message.png" alt=""/>
            <span class="msg-text">{{ $get($authStore, 'user.unread_message_items_count') > 99 ? '99+' : $get($authStore, 'user.unread_message_items_count') }}</span>
          </template>
          <template v-else>
            <img src="@/assets/header/allRead.png" alt=""/>
          </template>
        </div>
        <router-link :to="{ name: 'messageList' }" class="header-msg hidden-sm-and-up" @click="showMsgCard = !showMsgCard">
          <template v-if="$get($authStore, 'user.unread_message_items_count')">
            <img src="@/assets/header/message.png" alt=""/>
            <span class="msg-text">{{ $get($authStore, 'user.unread_message_items_count') > 99 ? '99+' : $get($authStore, 'user.unread_message_items_count') }}</span>
          </template>
          <template v-else>
            <img src="@/assets/header/allRead.png" alt=""/>
          </template>
        </router-link>
        <div class="avatar" @click="showDropCard = !showDropCard">
          <img :src="$get($authStore, 'user.avatar.url')|| require('@/assets/default-avatar.png')" alt=""/>
        </div>
      </div>
    </div>

    <dropdown-card v-model="showDropCard"/>
    <message-list v-model="showMsgCard" :store="store"/>
    <transition name="el-fade-in">
      <div v-show="showSearchCard" class="hidden-sm-and-up">
        <div class="mask" @click="showSearchCard = false"></div>
        <div class="search-card">
          <search-input @close="showSearchCard = false"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import DropdownCard from './dropdown-card';
  import MessageList from './message-list';
  import SearchInput from './search-input.vue';
  import clock from './clock.vue';

  @Component({
    components: {
      'dropdown-card': DropdownCard,
      'message-list': MessageList,
      'search-input': SearchInput,
      clock
    }
  })
  export default class CommonHeader extends Vue {
    showDropCard = false
    showMsgCard = false
    showSearchCard = false
    showOnlyIcon = true

    store = this.$Collection.create({
      fetch: params => this.$request.get('message/items', { params })
    })

    async created() {
      await this.$authStore.tryFetchData();
      if (this.$authStore.isAdvisor) {
        Object.assign(this.store.params, { actor_id_eq: this.$authStore.currentStudentId });
      }
      await this.store.fetchData();
    }

    handleClick() {
      this.showOnlyIcon = false;
    }
  }
</script>

<style lang="scss" scoped>
  .common-header {
    position: relative;

    .content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 64px;
      padding: 0 26px;
      color: var(--font-secondary-black);
      background-color: var(--bg-eighth-gray);

      @include media-xs-only {
        z-index: 1000;
        height: 58px;
        padding: 0;
        background: var(--bg-seventh-purple);

        &.show-only-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .logo {
        width: 80px;
        height: auto;
        padding-left: 22px;
      }

      .header-row {
        height: 100%;

        @include media-xs-only {
          padding: 0 22px;
        }

        ::v-deep .header-search {
          width: 420px;
        }

        .white-search-icon {
          width: 42px;
          height: 42px;
          margin-right: -18px;
        }

        .header-msg,
        .avatar {
          margin-left: 34px;

          @include media-xs-only {
            margin-left: 20px;
          }
        }

        .header-msg {
          position: relative;
          cursor: pointer;

          img {
            width: 35px;
            height: 35px;

            @include media-xs-only {
              width: 30px;
              height: 30px;
            }
          }

          .msg-text {
            position: absolute;
            top: 5px;
            right: -30px;
            display: inline-block;
            width: 25px;
            height: 17px;
            padding: 2px 5px;
            border-radius: 12px;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;
            color: var(--font-primary-white);
            background-color: $primary-purple;
            transform: translate(-50%, -50%);

            @include media-xs-only {
              top: 8px;
              right: -30px;
              height: 14px;
              padding: 2px 3px;
              font-size: 12px;
            }
          }
        }

        .avatar {
          cursor: pointer;

          img {
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            border: 1px solid var(--border-primary-white);
            border-radius: 50%;
            object-fit: cover;

            @include media-xs-only {
              width: 38px;
              height: 38px;
            }
          }
        }
      }
    }
  }

  .search-card {
    position: fixed;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 12px;
    background: var(--bg-secondary-gray);
  }

  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
