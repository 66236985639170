<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import dayjs from 'dayjs';

  @Component
  export default class Countdown extends Vue {
    @Prop({ type: String, required: true }) name
    @Prop({ type: Number, required: true }) duration
    @Prop({ type: [Date, Number, String] }) startTime

    newStartTime = null
    time = ''

    get storageKey() {
      return 'common_countdown_' + this.name;
    }

    @Watch('name')
    @Watch('startTime')
    startTimeChange(val, oldVal) {
      if (val && val !== oldVal) {
        this.initStartTime();
        this.startCountdown();
      } else if (!this.startTime) {
        this.time = 0;
        localStorage.setItem(this.storageKey, '');
        this.stopCountdown();
        this.$emit('end');
      }
    }

    created() {
      this.initStartTime();
      this.startCountdown();
    }

    destroyed() {
      this.stopCountdown();
    }

    initStartTime() {
      if (this.startTime) {
        this.newStartTime = dayjs(this.startTime).valueOf();
      } else {
        const time = localStorage.getItem(this.storageKey);
        this.newStartTime = time;
      }
      localStorage.setItem(this.storageKey, this.newStartTime);
    }

    startCountdown() {
      if (!this.newStartTime) {
        return;
      }
      this.stopCountdown();
      const time = this.duration - (Date.now() - this.newStartTime);
      if (time > 0) {
        this.$emit('start', time);
        this.time = time;
        this.handleCountdown();
      }
    }

    handleCountdown() {
      this.timer = setTimeout(() => {
        this.time = this.duration - (Date.now() - this.newStartTime);
        if (this.time <= 0) {
          this.stopCountdown();
          this.time = 0;
          this.$emit('end');
        } else {
          this.handleCountdown();
          this.$emit('change', this.time);
        }
      }, 1000);
    }

    stopCountdown() {
      clearTimeout(this.timer);
    }

    clearCountdown(isManual) {
      if (isManual) {
        this.time = 0;
        this.$emit('end');
      }
      this.stopCountdown();
      localStorage.setItem(this.storageKey, '');
    }

    render() {
      const slot = this.$scopedSlots.default;
      return slot ? slot({ time: this.time }) : <span>{Math.ceil(this.time / 1000)}</span>;
    }
  }
</script>

<style lang="scss" scoped>
</style>
