<template>
  <div class="team">
    <div class="page-title">My Team</div>

    <common-prod-card title="Head Advisor">
      <div class="bs-flex bs-item-center">
        <img class="avatar" :src="$get(headAdvisor, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
        <div class="name bs-flex-1 bs-flex bs-item-center">
          <span class="text-overflow">{{ headAdvisor.name }}</span>
          <span class="email hidden-xs-only">({{ headAdvisor.email }})</span>
        </div>
        <a v-if="headAdvisor.email" class="btn" :href="`mailto:${headAdvisor.email}`">Email</a>
        <div v-if="headAdvisor.whats_app_url" @click="handleOpenLink(headAdvisor.whats_app_url)" class="btn">WhatsApp</div>
      </div>
    </common-prod-card>

    <div class="subject-wrap">
      <common-prod-card title="Specialist" v-if="advisors.length">
        <div v-for="advisor in advisors" :key="advisor.subject_id" class="subject-group">
          <div class="subject-name">{{ $get(advisor, 'subject.name') | firstUpperCase }}</div>
          <div class="divider"></div>

          <div class="subject-staff">
            <div v-for="people in advisor.advisors" :key="people.id" class="staff-line bs-flex bs-item-center">
              <img class="avatar" :src="$get(people, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
              <div class="name bs-flex-1 bs-flex bs-item-center">
                <span class="text-overflow">{{ people.name }}</span>
                <span class="email hidden-xs-only">({{ people.email }})</span>
              </div>
              <a v-if="people.email" class="btn" :href="`mailto:${people.email}`">Email</a>
              <div v-if="people.whats_app_url" @click="handleOpenLink(people.whats_app_url)" class="btn">WhatsApp</div>
            </div>
          </div>
        </div>
      </common-prod-card>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class MyTeam extends Vue {
    advisors = []

    get headAdvisor() {
      return this.$get(this.$authStore.user, 'head_advisor') || {};
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      const { data } = await this.$request.get('mine/advisors');
      this.advisors = data.advisors;
    }

    handleOpenLink(link) {
      window.open(link, '_blank');
    }
  }
</script>

<style lang="scss" scoped>
  div ::v-deep .card {
    box-sizing: border-box;
    max-width: 800px;
    padding: 16px;
    border-radius: 8px;

    @include media-lg-and-down {
      width: 100%;
    }

    .body {
      padding: 31px 0 14px;
    }
  }

  .subject-wrap {
    margin-top: 48px;
  }

  .subject-group {
    font-size: 24px;

    @include media-lg-and-down {
      width: 100%;
    }

    &:not(:first-of-type) {
      margin-top: 56px;
    }

    .divider {
      height: 2px;
      margin-top: 8px;
      background: var(--bg-secondary-gray);
    }

    .subject-name {
      color: var(--font-sixth-gray);
    }

    .staff-line {
      margin-top: 16px;
    }
  }

  .avatar {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  .name {
    margin-right: 32px;
    margin-left: 32px;
    font-size: 24px;
    color: var(--font-secondary-black);

    @include media-xs-only {
      margin: 0 10px 0 20px;
      font-size: 20px;
    }
  }

  .email {
    margin-left: 12px;
    font-size: 18px;
    color: var(--font-third-gray);
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 34px;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 12px;
    line-height: normal;
    color: var(--font-primary-white);
    background: var(--bg-primary-purple);

    &:not(:last-child) {
      margin-right: 24px;

      @include media-xs-only {
        margin-right: 12px;
      }
    }
  }
</style>
