<template>
  <div class="loading-wrapper">
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { MessageBox } from 'element-ui';

  @Component
  export default class SimpleSso extends Vue {
    async created() {
      const token = this.$route.query.token;
      const platform = this.$route.query.platform;
      if (!token) {
        this.$router.replace({ name: 'login' });
        return ;
      }
      try {
        this.$authStore.logout(); // 防止旧 token 影响当前登录
        await this.$authStore.login({ token, platform }, 'sessions/simple_sso');
        this.$authStore.loginAfterNav();
      } catch (err) {
        const msg = err.message || err.errMsg || err.data.error_message || 'Login failed, please login again!';
        MessageBox({
          title: 'Tips',
          message: msg,
          confirmButtonText: 'Login again',
          type: 'error'
        }).finally(() => {
          this.$router.replace({ name: 'login' });
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-size: 40px;
    color: var(--font-primary-purple);
  }
</style>
