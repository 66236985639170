<template>
  <div class="notes">
    <div class="bs-flex bs-item-center bs-wrap" style="margin-bottom: 2.4659rem;">
      <div class="page-title">{{ keyword ? `Search "${keyword}" results` : 'Notes' }}</div>
      <common-switch
        v-if="$authStore.isAdvisor"
        :value="userRole === 'advisor'"
        activeText="My notes"
        inactiveText="Student notes"
        class="switch-wrap"
        @click-change="handleSwitch"
      />
    </div>

    <common-filter-view v-if="$authStore.isAdvisor" />
    <common-prod-filter-groups v-else :groups="filterGroups"/>

    <div class="note-list">
      <div v-if="notesStore.isEmpty" class="empty-wrap">You have no notes</div>

      <template v-else>
        <div v-for="note in notesStore.data" :key="note.id" class="item-line">
          <!-- PC -->
          <div class="bs-flex bs-item-center hidden-md-and-down">
            <router-link :to="{ name: 'note.detail', params: { id: note.id } }" class="note-title">{{ note.title }}</router-link>
            <div class="bs-flex-1 bs-flex bs-item-center">
              <div class="time">
                {{ note.updated_at | formatTime('MMM DD,&nbsp;&nbsp;YYYY') }}
              </div>
              <div class="tag-wrap">
                <common-prod-tag v-for="tag in $get(note, 'subjects', [])" :key="tag.id"
                  :tag="tag"
                  :showClose="false"
                />
                <template v-if="$authStore.isAdvisor && note.students.length">
                  <common-prod-tag
                    v-for="studentTag in $get(note, 'students', [])" :key="studentTag.id"
                    :tag="studentTag"
                    :showClose="false"
                    :routeTo="{ name: 'students', query: { keyword: $get(studentTag, 'name'), id_eq: studentTag.id } }"
                  />
                </template>
              </div>
              <div v-if="note.user_id === $authStore.user.id" class="btn-group bs-flex bs-item-center bs-content-between">
                <div @click="handleOpenEditPopup(note)" class="list-btn">Edit</div>
                <div @click="handleDelete(note)" class="list-btn">Delete</div>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="hidden-lg-and-up" style="width: 100%">
            <router-link :to="{ name: 'note.detail', params: { id: note.id } }" class="note-title">{{ note.title }}</router-link>
            <div class="tag-wrap">
              <common-prod-tag v-for="tag in $get(note, 'subjects', [])" :key="tag.id"
                :tag="tag"
                :showClose="false"
              />
              <template v-if="$authStore.isAdvisor && note.students.length">
                <common-prod-tag
                  v-for="studentTag in $get(note, 'students', [])" :key="studentTag.id"
                  :tag="studentTag"
                  :showClose="false"
                  :routeTo="{ name: 'students', query: { keyword: $get(studentTag, 'name'), id_eq: studentTag.id } }"
                />
              </template>
            </div>
            <div class="bs-flex-1 bs-flex bs-content-between" style="margin-top: 1.04932rem">
              <div class="time">
                {{ note.updated_at | formatTime('MMM DD,&nbsp;&nbsp;YYYY') }}
              </div>
              <div v-if="note.user_id === $authStore.user.id" class="btn-group bs-flex bs-item-center bs-content-between">
                <div @click="handleOpenEditPopup(note)" class="list-btn">Edit</div>
                <div @click="handleDelete(note)" class="list-btn">Delete</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <common-prod-pagination :store="notesStore"/>
    </div>

    <div class="create-note-form">
      <div class="title">New Note</div>

      <el-row :gutter="24">
        <el-col :xs="24">
          <common-prod-input
            v-model.trim="noteForm.title"
            inputTitle="Note name"
            @input="showError = false"
          />
          <div v-show="showError" class="input-error-msg">The note cannot be blank</div>
        </el-col>
        <el-col :sm="$authStore.isAdvisor ? 12 : 24" :xs="24">
          <common-prod-select
            v-model="noteForm.subject_ids"
            :options="subjectsStore.data"
            inputTitle="Subject"
            multiple
          />
        </el-col>
        <el-col v-if="$authStore.isAdvisor" :sm="12" :xs="24">
          <common-prod-select
            v-model="noteForm.student_ids"
            :options="studentsStore.data"
            inputTitle="Student"
            multiple
          />
        </el-col>
        <el-col :xs="24">
          <common-prod-input
            v-model="noteForm.content"
            type="richtext"
            inputTitle="Note description"
            resize
          />
        </el-col>
        <el-col v-if="!$authStore.isAdvisor" :xs="24">
          <el-checkbox v-model="isStudentNotePrivate">
            <span class="label">Make note private</span>
          </el-checkbox>
        </el-col>
      </el-row>

      <div class="bs-flex bs-content-end">
        <common-prod-button @click.native="handleSaveNote">Save</common-prod-button>
      </div>
    </div>

    <common-popup v-model="showEditNote" title="Edit a note">
      <note-form :noteForm="currentOpenNote" :submit="handleEditNote" @close="showEditNote = false" />
    </common-popup>

  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { subjectsStore, studentsStore } from '@/stores';
  import NoteForm from './components/note-from.vue';
  import _ from 'lodash';

  const defaultNoteForm = {
    title: '',
    content: '',
    is_public: false,
    subject_ids: [],
    student_ids: []
  };

  @Component({
    components: {
      'note-form': NoteForm
    }
  })
  export default class Index extends Vue {
    isStudentNotePrivate = false
    noteForm = _.cloneDeep(defaultNoteForm)
    showEditNote = false
    currentOpenNote = null
    showError = false

    subjectsStore = subjectsStore
    studentsStore = studentsStore
    notesStore = this.$Collection.create({
      fetch: params => {
        return this.$request.get('notes', { params });
      }
    })

    get keyword() {
      return this.$route.query.keyword;
    }

    // advisor切换my notes/student note
    get userRole() {
      return this.$route.query.user_role_eq || 'advisor';
    }

    get filterGroups() {
      const subjects = _.map(this.subjectsStore.data, item => {
        return {
          name: item.name,
          value: item.id
        };
      });
      return [
        {
          key: 'subject_taggings_subject_id',
          title: 'By subject',
          allBtn: true,
          data: subjects,
        }
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      const isAdvisor = this.$authStore.isAdvisor;
      this.notesStore.setQueryToParams([
        'keyword',
        'user_role_eq',
        'subject_taggings_subject_id_in',
        'student_id_in'
      ]);

      await Promise.all[
        this.notesStore.fetchData({ user_role_eq: isAdvisor ? this.userRole : undefined }),
        this.subjectsStore.fetchData({ page: 0 }),
        isAdvisor && studentsStore.fetchData({ page: 0 }) // 学生不调取studentsStore
      ];
    }

    @Vue.autoLoading
    async handleDelete(note) {
      await this.$request.delete(`notes/${note.id}`);
      if (this.notesStore.meta.total < this.notesStore.meta.per_page) {
        this.notesStore.removeItemById(note.id);
        return;
      }
      await this.notesStore.fetchData();
    }

    @Vue.autoLoading
    async handleSaveNote() {
      if (!this.noteForm.title) {
        this.showError = true;
        return;
      }
      const { data } = await this.$request.post('notes', {
        ...this.noteForm,
        is_public: !this.$authStore.isAdvisor ? !this.isStudentNotePrivate : this.noteForm.is_public,
      });

      this.$message.success('Note added successfully');

      // 前端模拟添加数据advisor需要区分当前的user_role_eq
      if (this.$authStore.isAdvisor) {
        if (this.userRole === 'advisor') {
          this.notesStore.unshift(data);
        } else {
          this.$router.replace({ query: { ...this.$route.query, user_role_eq: 'advisor' } });
          return;
        }
      } else {
        this.notesStore.unshift(data);
      }

      // 清空表单
      this.noteForm = _.cloneDeep(defaultNoteForm);
      this.isStudentNotePrivate = false;
    }

    handleOpenEditPopup(note) {
      this.showEditNote = true;
      this.currentOpenNote = note;
    }

    @Vue.autoLoading
    async handleEditNote(body) {
      const { data } = await this.$request.put(`notes/${this.currentOpenNote.id}`, body);
      this.notesStore.replaceItem(data);
    }

    handleSwitch() {
      this.$router.replace({ query: {
        ...this.$route.query,
        user_role_eq: this.userRole === 'advisor' ? 'student' : 'advisor' }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .page-title {
    margin: 0 47px 0 0;
  }

  .switch-wrap {
    height: 40px;
    border: 2px solid var(--border-primary-black);
    font-size: 13px;
    color: var(--font-secondary-black);
    background: var(--bg-primary-white);

    ::v-deep &.inactive {
      div .cancel {
        color: var(--font-primary-white);
      }
    }

    ::v-deep div {
      .confirm {
        padding: 10px 20px;
      }

      .cancel {
        padding: 10px 5px;
        white-space: nowrap;
      }
    }

    ::v-deep .switch-inner {
      background: var(--bg-primary-purple);
    }
  }

  .note-list {
    margin-top: 55px;

    .item-line {
      width: 100%;
      font-size: 24px;

      @include media-lg-and-down {
        flex-direction: column;
        align-items: flex-start;
      }

      &:not(:last-child) {
        margin-bottom: 22px;
      }

      .note-title {
        width: 35%;
        margin-right: 50px;
        text-decoration: underline;
        color: var(--font-forth-purple);

        @include text-overflow();

        @include media-lg-and-down {
          width: 100%;
          margin-left: 0;
        }
      }

      .time {
        width: 15%;
        margin-left: 10px;
        font-size: 16px;
        color: var(--font-primary-blue);

        @include media-lg-and-down {
          width: auto;
          margin-left: 0;
        }
      }
    }

    .btn-group {
      width: 10%;

      @include media-lg-and-down {
        width: auto;
      }

      .list-btn {
        font-size: 16px;
        text-align: center;
        text-decoration: underline;
        color: #4e4e4e;
        cursor: pointer;

        @include media-lg-and-down {
          &:not(:last-child) {
            margin-right: 26px;
          }
        }
      }
    }

    .tag-wrap {
      width: 60%;

      @include media-lg-and-down {
        width: auto;
        margin-top: 16px;
      }

      ::v-deep .tag {
        margin: 0 8px 8px 0;
      }
    }
  }

  .create-note-form {
    width: 60%;
    margin-top: 58px;

    @include media-lg-and-down {
      width: 100%;
    }

    ::v-deep .el-col {
      margin-bottom: 24px;
    }

    .title {
      margin-bottom: 24px;
      font-size: 24px;
      color: var(--font-secondary-black);
    }

    ::v-deep .el-checkbox {
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-end;
      align-items: center;

      .el-checkbox__inner {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin-top: 3px;
        border: 2PX solid var(--border-secondary-black);
      }

      .is-checked .el-checkbox__inner {
        border: 2PX solid transparent;
      }

      .is-checked + .el-checkbox__label {
        color: var(--font-secondary-black);
      }

      .label {
        margin-right: 11px;
        color: var(--font-secondary-black);
      }
    }

    ::v-deep .ato-button {
      @include media-xs-only {
        min-width: 100% !important;
      }
    }

    > div.input-error-msg {
      margin-top: 0;
    }
  }

  .empty-wrap {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
  }
</style>
