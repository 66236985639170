<template>
  <div>
    <div class="page-title">Billing</div>
    <div class="description">
      <div>Currently, all billing is handled directly with our team.</div>
      <div>
        If you have questions or want to make a payment, please contact us at
        <a class="link" href="mailto:billing@atomicmind.com">billing@atomicmind.com.</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class Billing extends Vue {}
</script>

<style lang="scss" scoped>
  .description {
    max-width: 786px;
    font-weight: 600;
    font-size: 24px;

    div + div {
      margin-top: 20px;
    }

    .link {
      text-decoration: underline;
      color: var(--font-primary-purple);
    }
  }
</style>
