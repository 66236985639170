<template>
  <div class="picker-wrapper">
    <span><slot/></span>
    <div class="picker-slot"><slot name="picker"/></div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class PickerWrapper extends Vue {
  }
</script>

<style lang="scss" scoped>
  .picker-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    .picker-slot {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 1.6;
      opacity: 0;

      ::v-deep {
        .el-input__inner {
          height: auto;
          padding: 0;
          line-height: 1.6;
          cursor: pointer;
        }
      }

      > div {
        width: 100%;
      }
    }
  }
</style>
