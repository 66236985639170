<template>
  <div class="goole">
    <common-started-layout>
      <div class="google-wrap bs-flex bs-column bs-item-center">

        <template v-if="success">
          <div class="title" style="margin: 4.87933rem 0;">Your account has successfully been connected!</div>
          <common-prod-button size="large" @click.native="handleNative">
            start using atomicmind
          </common-prod-button>
        </template>
        <template v-else>
          <div class="title">Connect your Google Account</div>

          <span class="goole-input bs-flex bs-item-center" @click="handleBindGoogle">
            <img class="google-icon" src="@/assets/google-icon.png" alt="">
            Sign in with Google
          </span>
        </template>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { handleBindGoogle } from '@/utils';

  @Component
  export default class bindGoogleAccount extends Vue {
    @Prop({ type: Boolean, default: false }) success

    @Vue.autoLoading
    async handleBindGoogle() {
      return handleBindGoogle();
    }

    handleNative() {
      this.$authStore.loginAfterNav();
    }
  }
</script>

<style lang="scss" scoped>
  .google-wrap {
    padding: 92px 0;
    text-align: center;

    @include media-xs-only {
      padding: 48px 32px;
    }

    .title {
      font-size: 36px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        font-size: 32px;
      }
    }

    .subtitke {
      margin-top: 24px;
      font-size: 24px;
      color: var(--font-secondary-black);
    }

    .goole-input {
      padding: 20px;
      margin: 80px 0 68px;
      border: 2PX solid var(--border-primary-black);
      font-size: 31px;
      cursor: pointer;

      .google-icon {
        width: 34px;
        height: 34px;
        margin-right: 32px;
      }
    }
  }
</style>
