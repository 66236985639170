<template>
  <div class="msg-list">
    <div class="page-title">Message List</div>
    <div class="container">
      <common-msg-list :store="store"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class MessageList extends Vue {
    store = this.$Collection.create({
      fetch: params => this.$request.get('message/items', { params })
    })

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      if (this.$authStore.isAdvisor) {
        Object.assign(this.store.params, { actor_id_eq: this.$authStore.currentStudentId });
      }
      await this.store.fetchData();
    }

  }
</script>

<style lang="scss" scoped>
  .msg-list {
    padding-bottom: 20px;
  }
</style>
