<template>
  <el-form class="event-form">
    <el-form-item v-if="!isEdit">
      <div class="bs-flex bs-item-baseline">
        <i class="el-icon-time input-icon hidden"/>
        <el-radio-group v-model="form.kind">
          <el-radio label="event">Event</el-radio>
          <el-radio label="task">Assignment</el-radio>
        </el-radio-group>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-baseline">
        <i class="el-icon-time input-icon hidden"/>
        <div class="bs-flex-1">
          <el-input
            placeholder="Event Title"
            v-model="form.title"
            clearable
          />
          <div v-show="form.kind === 'task'" class="form-item-hint">
            Title format: "Assignment: Name of event - Subject"
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-baseline">
        <i class="el-icon-time input-icon"/>
        <div class="bs-flex-1">
          <DatePicker v-model="dateForm"/>
          <RepeatSelect :start-date="dateForm.start_date" v-model="recurrence"/>
        </div>
      </div>
    </el-form-item>
    <el-form-item v-if="form.kind === 'event' || $authStore.isAdvisor">
      <div class="bs-flex bs-item-baseline">
        <i class="el-icon-user input-icon"/>
        <div>
          <div v-if="formData.creator">
            <el-avatar class="avatar" :size="20" :src="$get(formData, 'creator.avatar') || require('@/assets/default-avatar.png')"/>
            <span>(Organizer) {{ formData.creator.name }}</span>
          </div>
          <SelectUsers
            v-model="userIds"
            :default-options="formData.attendance_users"
            :multiple-limit="form.kind === 'event' ? 0 : 1"
            :event-kind="form.kind"
          />
        </div>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-start">
        <i class="el-icon-tickets input-icon" style="margin-top: 0.52466rem;"/>
        <div class="bs-flex-1" style="overflow-x: hidden;">
          <common-richtext-editor v-model="form.desc"/>
        </div>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-center">
        <i class="el-icon-video-camera input-icon"/>
        <el-checkbox v-model="form.need_google_meet">Add Google Meet</el-checkbox>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-center">
        <i class="el-icon-goods input-icon"/>
        <el-select v-model="form.transparency" placeholder="Free">
          <el-option
            v-for="item in transparencyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </el-form-item>
    <el-form-item>
      <div class="bs-flex bs-item-baseline">
        <i class="el-icon-bell input-icon"/>
        <Notification class="bs-flex-1" :is-all-day="dateForm.is_all_day" v-model="overrides"/>
      </div>
    </el-form-item>
    <div class="btns-wrapper">
      <el-button plain type="info" @click="$emit('cancel')">Cancel</el-button>
      <el-button plain type="primary" @click="handleSubmit">Save</el-button>
    </div>
  </el-form>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import DatePicker from './date-picker/index.vue';
  import RepeatSelect from './repeat-select/index.vue';
  import SelectUsers from './select-users.vue';
  import Notification from './notification/index.vue';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component({
    components: {
      DatePicker,
      RepeatSelect,
      SelectUsers,
      Notification
    }
  })
  export default class EventForm extends Vue {
    @Prop({ type: Object, default: () => ({}) }) formData

    form = {
      title: '', // Title of the event.
      kind: 'event', // event/task
      desc: '',
      need_google_meet: false,
      transparency: 'transparent',
    }

    overrides = []

    dateForm = {
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().add(1, 'hour').format('YYYY-MM-DD'),
      start_time: dayjs().format('HH:mm'),
      end_time: dayjs().add(1, 'hour').format('HH:mm'),
      start_time_zone: dayjs.tz.guess(),
      end_time_zone: dayjs.tz.guess(),
      is_all_day: false,
    }

    userIds = []

    recurrence = ''

    transparencyOptions = [
      { label: 'Free', value: 'transparent' },
      { label: 'Busy', value: 'opaque' },
    ]

    get isEdit() {
      return !!this.formData.id;
    }

    @Watch('formData', { immediate: true, deep: true })
    handleFormDataChange(val) {
      if (val) {
        Object.assign(this.form, _.pick(val, [ ...Object.keys(this.form) ]));
        Object.assign(this.dateForm, _.pick(val, [ ...Object.keys(this.dateForm) ]));
        const userIds = this.translateUserIds();
        if (!_.isEqual(this.userIds, userIds)) {
          this.userIds = userIds;
        }
        const overrides = _.get(val.reminders, 'overrides') || [];
        if (!_.isEqual(this.overrides, overrides)) {
          this.overrides = overrides;
        }
        const recurrence = _.get(val.recurrence, '[0]') || '';
        if (this.recurrence !== recurrence) {
          this.recurrence = recurrence;
        }
      }
    }

    @Watch('form', { deep: true })
    @Watch('dateForm', { deep: true })
    @Watch('recurrence')
    @Watch('overrides', { deep: true })
    @Watch('userIds', { deep: true })
    handleChangeForm() {
      this.$emit('change', this.getNewFormData());
    }

    @Watch('form.kind')
    handleChangeKind() {
      // 切换kind时，清空userIds，防止切换后，userIds选了advisor
      this.userIds = [];
    }

    /**
     * 将attendancesAttributes 转换成form需要的users格式
     *
     * @return {Array} [user_id]
     */
    translateUserIds() {
      return _.map(_.get(this.formData, 'attendances_attributes'), item => item.user_id);
    }

    getNewFormData() {
      const reminders = {};
      if (this.overrides.length) {
        reminders.use_default = false;
        reminders.overrides = this.overrides.map(item => _.pick(item, ['method', 'minutes']));
      }
      return {
        ..._.pick(this.formData, ['id']),
        ...this.dateForm,
        title: _.trim(this.form.title),
        desc: _.trim(this.form.desc),
        ..._.omit(this.form, ['title', 'desc']),
        recurrence: this.recurrence ? [ this.recurrence ] : [],
        reminders,
        attendances_attributes: _.map(this.userIds, userId => ({ user_id: userId })),
      };
    }

    handleSubmit(e) {
      try {
        // 防止 button 一直 focus，通过键盘再次提交 submit 事件
        e.target.blur();
      // eslint-disable-next-line no-empty
      } catch (e) {}
      this.$emit('submit', this.getNewFormData());
    }
  }
</script>

<style lang="scss" scoped>
  .input-icon {
    margin-right: 15px;

    &.hidden {
      visibility: hidden;
    }
  }

  .btns-wrapper {
    text-align: right;
  }

  .event-form {
    ::v-deep {
      .editor {
        min-height: auto;
        max-height: 300px;
        border: 1px solid var(--border-color);
      }

      .editor-wrapper .tox-tinymce {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }

      .vditor-reset {
        padding: 10px 20px !important;
      }
    }

    .avatar {
      margin-right: 10px;
      vertical-align: middle;
    }

    .form-item-hint {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      color: #b1b1b1;
    }
  }
</style>
