import Observable from './helper/observable';
import { IS_DARK_THEME } from '@/constants';

class Theme extends Observable {
  constructor() {
    super();
    this.isDarkTheme = localStorage.getItem(IS_DARK_THEME);
    this.changeHtmlClass();
  }

  isDarkTheme = false

  toggleDarkTheme() {
    const isDarkTheme = !this.isDarkTheme;
    this.isDarkTheme = isDarkTheme;
    localStorage.setItem(IS_DARK_THEME, isDarkTheme || '');
    this.changeHtmlClass();
  }

  async changeHtmlClass() {
    document.body.classList[this.isDarkTheme ? 'add' : 'remove']('dark-theme');
  }

}

export const themeStore = Theme.create();
