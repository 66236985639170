import { WEEKLY_TRANSFORM_MAP } from '@/constants';
import { Collection } from './helper/collection';
import { request } from '@/utils';
import { authStore } from './auth-store';
import _ from 'lodash';

export class TasksStore extends Collection {
  fetch(params) {
    return request.get('tasks', { params });
  }

  getRepeatLabel(item) {
    const { repeat_type, repeat_dates } = item;
    const monthDates = Array.from({ length: 31 }, (_, i) => i + 1);
    switch (repeat_type) {
      case 'daily':
        return 'Daily';
      case 'weekly': {
        let datesArr = [];
        if (_.isEqual(repeat_dates, [1, 2, 3, 4, 5])) { return 'Every weekday'; }
        if (_.isEqual(repeat_dates, [6, 7])) { return 'Every weekend'; }
        if (_.isEqual(repeat_dates, [1, 2, 3, 4, 5, 6, 7])) { return 'Daily'; }
        repeat_dates.forEach(date => {
          const weeklyArr = _.get(WEEKLY_TRANSFORM_MAP, `${date - 1}`);
          if (!_.isEmpty(weeklyArr)) {
            datesArr.push(_.get(weeklyArr, 'full'));
          }
        });
        return `Every ${datesArr.join(' and ')}`;
      }
      case 'monthly': {
        let datesArr = [];
        if (_.isEqual(repeat_dates, monthDates)) { return 'Daily'; }
        repeat_dates.forEach(date => {
          switch (String(date)) {
            case '1':
            case '21':
            case '31':
              return datesArr.push(`${date}st`);
            case '2':
            case '22':
              return datesArr.push(`${date}nd`);
            case '3':
            case '23':
              return datesArr.push(`${date}rd`);
            default:
              return datesArr.push(`${date}th`);
          }
        });
        return `Every ${datesArr.join(' and ')}`;
      }
    }
  }

  async finishTask(item) {
    try {
      if (authStore.isAdvisor) {
        item.status = 'completed';
        await request.put(`tasks/${item.id}/advisor_complete`);
        this.removeItemById(item.id);
      } else {
        item.status = 'wait_advisor_review';
        await request.put(`tasks/${item.id}/student_complete`);
      }
    } catch (e) {
      this.$message.error(e.message);
    }
  }
}

export const tasksStore = TasksStore.create();
export const reminderStore = TasksStore.create();
