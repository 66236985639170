import dayjs from 'dayjs';

export function translateNotificationLabel(minutes, isAllDay) {
  if (isAllDay) {
    // 如果是全天，通知时间需要减去一天，显示时间需要比通知时间多一天
    // 如一天前09:00, minutes 为 15 * 60，显示为 15:00。 需要转换为 1 + 9 * 60，才能正确显示
    const oneDay = 24 * 60;
    const time = oneDay - (minutes % oneDay) + (Math.ceil(minutes / oneDay) * 60 * 24);
    const h = dayjs.duration(time, 'minutes').format('HH');
    const m = dayjs.duration(time, 'minutes').format('mm');
    return `${dayjs.duration(minutes, 'minutes').humanize().replace('a day', 'The day')} before at ${h}:${m}`;
  }
  return `${dayjs.duration(minutes, 'minutes').humanize().replace('a day', 'The day')} before`;
}

export default translateNotificationLabel;
