<template>
  <div class="msg-list">
    <div class="content">
      <div v-if="store.isEmpty" class="tip">No Result</div>
      <span v-else class="all-read" @click="handleAllRead">Mark all as read </span>
      <common-prod-activity-msg
        v-for="item in store.data"
        :key="item.id"
        :activity="item"
        :isDashboard="false"
    />
    </div>
    <div v-if="!store.isEmpty" class="more">
      <i v-if="store.isFetching" class="el-icon-loading"></i>
      <span class="more-btn" :class="{ 'disabled': store.isComplete }" v-else @click="store.fetchMoreData()">
        {{ !store.isComplete ? 'More' : 'No more' }}
      </span>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class MsgList extends Vue {
    @Prop(Object) store

    async handleAllRead() {
      await this.$autoLoading(this.$request.put('/message/items/all_read'));
      _.set(this.$authStore, 'user.unread_message_items_count', 0);
      await this.store.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .msg-list {
    @include media-xs-only {
      padding-bottom: 30px;
    }

    .content {
      .all-read {
        display: inline-block;
        margin-bottom: 16px;
        text-decoration: underline;
        color: var(--font-primary-black);
        cursor: pointer;
      }

      .tip {
        text-align: center;
        color: var(--font-forth-blue);
      }
    }

    .more {
      padding: 10px 0 4px;
      border-top: 1px solid var(--border-secondary-gray);
      text-align: center;
      color: var(--font-forth-blue);

      .more-btn {
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }
      }

      @include media-xs-only {
        font-size: 20px;
      }
    }
  }
</style>
