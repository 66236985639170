<template>
  <common-prod-picker-wrapper>
    <span>{{ dateText }}</span>
    <template #picker>
      <el-date-picker
        :value="date"
        @input="$emit('change', $event)"
        type="date"
        :editable="false"
        :clearable="false"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
      />
    </template>
  </common-prod-picker-wrapper>
</template>

<script>
  import { Vue, Component, Model, Watch, Prop } from 'vue-property-decorator';
  import dayjs from 'dayjs';

  @Component
  export default class DatePicker extends Vue {
    @Model('change', { type: String, default: '' }) value
    @Prop() startDate

    date = null

    get dateText() {
      return dayjs(this.date).format('MMM DD, YYYY');
    }

    get pickerOptions() {
      return {
        disabledDate: (current) => {
          return this.startDate ? dayjs(current).isBefore(dayjs(this.startDate)) : false;
        }
      };
    }

    @Watch('value', { immediate: true })
    handleValueChange(val, old) {
      if (old !== val) {
        this.date = dayjs(val).format('YYYY-MM-DD');
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
