<template>
  <div class="info-wrap">
    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          readonly
          inputTitle="Email address"
          v-model="email"
        />
      </el-col>
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="Slack"
          v-model.trim="contactForm.slack_name"
          @keyup.enter.native="handleSaveChange"
        />
      </el-col>
    </el-row>

    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          required
          inputTitle="Phone number"
          v-model.trim="contactForm.phone"
          @input="errorList.phone = ''"
          @keyup.enter.native="handleSaveChange"
        >
          <template v-slot:selectInput>
            <el-select
              v-model="contactForm.country_id"
              :popper-append-to-body="false"
              placeholder=""
              filterable
              :filter-method="filterPhoneCodeOptions"
              @focus="resetPhoneCodeOptions"
              @change="resetPhoneCodeOptions"
              popper-class="special-select-dropdown"
            >
              <el-option
                v-for="item in phoneCodeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
        <div v-show="errorList.phone" class="input-error-msg">{{ errorList.phone }}</div>
      </el-col>
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input type="onlySelect" inputTitle="Time zone">
          <template v-slot:selectInput>
            <el-select
              v-model="contactForm.time_zone"
              :popper-append-to-body="false"
              placeholder="No time zone set"
              filterable
            >
              <el-option
                v-for="(item, index) in timeZonesStore.data"
                :key="index"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
      </el-col>
    </el-row>

    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="WhatsApp number"
          v-model.trim="contactForm.whats_app_number"
          @keyup.enter.native="handleSaveChange"
        >
          <template v-slot:selectInput>
            <el-select
              v-model="contactForm.whats_app_country_id"
              :popper-append-to-body="false"
              placeholder=""
              filterable
              :filter-method="filterPhoneCodeOptions"
              @focus="resetPhoneCodeOptions"
              @change="resetPhoneCodeOptions"
              popper-class="special-select-dropdown"
            >
              <el-option
                v-for="item in phoneCodeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </template>
        </common-prod-input>
      </el-col>
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="WeChat ID"
          v-model.trim="contactForm.wechat_id"
          @keyup.enter.native="handleSaveChange"
        />
      </el-col>
    </el-row>

    <div class="footer">
      <common-prod-button
        size="large"
        @click.native="handleSaveChange()"
      >SAVE CHANGES</common-prod-button>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import { authStore, countriesStore, timeZonesStore } from '@/stores';
  import Schema from 'async-validator';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component
  export default class ContactInfo extends Vue {
    contactForm = {
      slack_name: '',
      country_id: '',
      phone: '',
      time_zone: '',
      whats_app_number: '',
      wechat_id: '',
      id: '',
      whats_app_country_id: ''
    }

    email = ''
    countriesStore = countriesStore
    timeZonesStore = timeZonesStore
    authStore = authStore
    phoneCodeList = []
    phoneCodeOptions = []

    errorList = {
      slack_name: '',
      phone: ''
    }

    @Vue.autoLoadingProgress
    async created() {
      await Promise.all([
        countriesStore.tryFetchData(),
        timeZonesStore.tryFetchData(),
        this.authStore.tryFetchData()
      ]);

      const contactAttributes = _.pick(_.get(this.authStore, 'user.contact'), Object.keys(this.contactForm));
      if (!contactAttributes.country_id) {
        contactAttributes.country_id = countriesStore.data[0].id;
      }
      if (!contactAttributes.time_zone) {
        contactAttributes.time_zone = dayjs.tz.guess();
      }
      if (!contactAttributes.whats_app_country_id) {
        contactAttributes.whats_app_country_id = countriesStore.data[0].id;
      }
      Object.assign(this.contactForm, contactAttributes);
      this.email = this.authStore.user.email;

      this.phoneCodeList = countriesStore.data.map((country) => {
        let phoneCode = {};
        phoneCode.value = country.id;
        phoneCode.name = country.flag + ' ' + country.name + ' ' + country.phone_code;
        phoneCode.label = country.flag + ' ' + country.phone_code;
        return phoneCode;
      });
      this.phoneCodeOptions = this.phoneCodeList;
    }

    resetPhoneCodeOptions() {
      this.phoneCodeOptions = this.phoneCodeList;
    }

    filterPhoneCodeOptions(keyword) {
      if (keyword) {
        this.phoneCodeOptions = this.phoneCodeList.filter((item) => {
          return item.name.toUpperCase().includes(keyword.toUpperCase());
        });
      } else {
        this.phoneCodeOptions = this.phoneCodeList;
      }
    }

    async handleSaveChange() {
      const validator = new Schema({
        phone: { required: true, message: 'Phone can\'t be blank' }
      });

      try {
        await validator.validate(this.contactForm);
      } catch ({ errors }) {
        this.$message({ message: errors.map(err => err.message).join('\n'), type: 'error' });
        errors.map(err => this.errorList[err.field] = err.message);
        return;
      }

      // 新用户更新后，接口会返回新创建的contact id, 这里需要更新一次form， 避免新用户在此点击更新后报 500
      this.contactForm.id = _.get(this.authStore, 'user.contact.id');

      await this.$autoLoading(this.authStore.changeContactInfo(this.contactForm));
      this.$message({ message: 'The contact info is modified successfully', type: 'success' });
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    margin-bottom: 280px;
  }

  ::v-deep .el-select-dropdown {
    &.special-select-dropdown {
      width: fit-content;

      @include media-xs-only {
        width: 370px;
      }
    }
  }
</style>
