import { Collection } from './helper/collection';
import { request } from '@/utils';

export class SchoolsStore extends Collection {

  fetch(params) {
    return request.get('schools', { params });
  }
}

export const schoolsStore = new SchoolsStore();
