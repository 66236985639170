import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';

class SettingsStore extends SimpleStore {
  async fetchData() {
    return this.mergeFetched(request.get('settings'));
  }
}

export const settingsStore = new SettingsStore();
