<template>
  <div class="tasks list-page">
    <div class="page-title">{{ keyword ? `Search "${keyword}" results` : `${titleName} Assignments` }}</div>
    <div class="content">
      <common-prod-card v-if="!$authStore.isAdvisor && reminderStore.data.length && !keyword" title="Reminders" class="reminder-wrap">
        <div v-for="item in reminderStore.data.slice(0, 5)" :key="item.id" class="list-line student-line">
          <common-task-status
            class="task-status"
            :task="item"
            @finish="reminderStore.finishTask(item)"
            :to="{ name: 'task.detail', params: { id: item.id } }"
          />
          <div class="assign">{{`Assigned by ${$get(item, 'creator.name')}`}}</div>
          <div class="time-and-repeat-wrap">
            <div class="time">{{ `Due ${$options.filters.formatTime(item.due_at, 'MMM DD,&nbsp;&nbsp;YYYY')}` }}</div>
            <div class="repeat text-overflow">{{ reminderStore.getRepeatLabel(item) }}</div>
          </div>
        </div>
        <router-link v-if="reminderStore.meta.total > 5" :to="{ name: 'reminders' }">
          <common-prod-button border>
            All Reminders
          </common-prod-button>
        </router-link>
      </common-prod-card>

      <!-- tutor -->
      <template v-if="$authStore.isAdvisor">
        <common-filter-view />
        <router-link :to="{ name: 'reminders' }">
          <common-prod-button border style="margin-right: 0.52466rem">
            All Reminders
          </common-prod-button>
        </router-link>
        <common-prod-button border @click.native="showTaskPopup = true; currentOpenTaskId = null">
          New Assignment
        </common-prod-button>
        <div class="list-wrap">
          <div v-for="task in tasksStore.data" :key="task.id" class="list-line bs-flex">
            <common-prod-list-line
              :time="`Due ${$options.filters.formatTime(task.due_at, 'MMM DD,\u0020\u0020YYYY')}`"
              :to="{name: 'task.detail', params: { id: task.id }}"
              :lineItem="task"
              @finish="tasksStore.finishTask(task)"
            >
              <template v-slot:tagSlot>
                <div class="tag-wrap">
                  <common-prod-tag v-for="tag in $get(task, 'subjects', [])" :key="tag.id"
                    :tag="tag"
                    :showClose="false"
                  />
                  <common-prod-tag v-if="$authStore.isAdvisor && task.student"
                    :tag="task.student"
                    :showClose="false"
                    :routeTo="{ name: 'students', query: { keyword: $get(task, 'student.name'), id_eq: $get(task, 'student.id') } }"
                  />
                </div>
              </template>
              <template v-slot:options>
                <div class="btn-group bs-flex bs-item-center bs-content-between">
                  <div @click="handleOpenEditPopup(task)" class="list-btn">Edit</div>
                  <div @click="handleDelete(task)" class="list-btn">Delete</div>
                </div>
              </template>
            </common-prod-list-line>
          </div>
        </div>
        <common-prod-pagination :store="tasksStore"/>
        <div v-if="tasksStore.isEmpty" class="empty-wrap">You have no tasks</div>
      </template>

      <!-- student -->
      <template v-else>
        <common-prod-filter-groups :groups="filterGroups"/>

        <router-link v-if="keyword" :to="{ name: 'reminders' }">
          <common-prod-button border style="margin-right: 0.52466rem">
            All Reminders
          </common-prod-button>
        </router-link>
        <common-prod-button border @click.native="showTaskPopup = true; currentOpenTaskId = null">
          New Assignment
        </common-prod-button>

        <div class="hidden-lg-and-up link" @click="handleToggleCompleted">
          Show {{isCompletedList ? 'open' : 'completed'}} tasks
        </div>

        <div class="list-and-link-wrap bs-flex bs-content-between">
          <div class="list-wrap student-wrap bs-flex-1">
            <div v-for="(store, title) in studentStore" :key="title">
              <template v-if="store.data.length">
                <div class="sub-title" v-if="title">{{ title }}</div>
                <div v-for="item in store.data" :key="item.id">
                  <div class="hidden-md-and-down list-line student-line bs-flex">
                    <div class="task-status">
                      <common-task-status :task="item" @finish="store.finishTask(item)" :to="{ name: 'task.detail', params: { id: item.id } }" />
                    </div>
                    <div class="assign">{{`Assigned by ${$get(item, 'creator.name')}`}}</div>
                    <div class="tag-wrap">
                      <common-prod-tag v-for="tag in $get(item, 'subjects', [])" :key="tag.id"
                        :tag="tag"
                        :showClose="false"
                      />
                    </div>
                    <div class="due-time">{{ `Due ${$options.filters.formatTime(item.due_at, 'MMM DD,&nbsp;&nbsp;YYYY')}` }}</div>
                    <div class="msg-wrap">
                      <common-prod-msg v-if="item.comments_count" :msgText="item.comments_count"/>
                    </div>
                  </div>
                  <div class="hidden-lg-and-up list-line student-line">
                    <div class="bs-flex bs-item-center bs-content-between">
                      <common-task-status class="task-status" :task="item" @finish="store.finishTask(item)" :to="{ name: 'task.detail', params: { id: item.id } }" />
                      <div class="msg-wrap">
                        <common-prod-msg v-if="item.comments_count" :msgText="item.comments_count"/>
                      </div>
                    </div>
                    <div class="bs-flex bs-item-center" style="margin-top: 1.04932rem;">
                      <div class="due-time">{{ `Due ${$options.filters.formatTime(item.due_at, 'MMM DD,  YYYY')}` }}</div>
                      <div class="assign">{{`Assigned by ${$get(item, 'creator.name')}`}}</div>
                    </div>
                    <div class="tag-wrap bs-flex bs-item-center bs-wrap" style="margin-top: 1.04932rem;">
                      <common-prod-tag v-for="tag in $get(item, 'subjects', [])" :key="tag.id"
                        :tag="tag"
                        :showClose="false"
                      />
                  </div>
                  </div>
                </div>
                <common-prod-pagination class="student-item-pagination" :store="store"/>
              </template>
            </div>
          </div>
          <div class="link hidden-md-and-down" @click="handleToggleCompleted">Show {{isCompletedList ? 'open' : 'completed'}} tasks</div>
        </div>

        <div v-if="isStudentStoresEmpty" class="empty-wrap">You have no tasks</div>
      </template>

    </div>

    <common-popup v-model="showTaskPopup" :title="`${currentOpenTaskId ? 'Edit' : 'Add'} an assignment`">
      <task-form
        :id="currentOpenTaskId"
        :submit="currentOpenTaskId ? handleEditTask : handleAddTask"
        :isEdit="currentOpenTaskId ? true : false"
        @close="showTaskPopup = false"
      />
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import TaskForm from './components/task-form.vue';
  import { subjectsStore, studentsStore, TasksStore, tasksStore, reminderStore } from '@/stores';
  import _ from 'lodash';
  import dayjs from 'dayjs';

  const advancedFormat = require('dayjs/plugin/advancedFormat');
  dayjs.extend(advancedFormat);

  @Component({
    components: {
      'task-form': TaskForm
    }
  })
  export default class Tasks extends Vue {
    showTaskPopup = false
    currentOpenTaskId = null

    tasksStore = tasksStore
    reminderStore = reminderStore
    subjectsStore = subjectsStore
    studentsStore = studentsStore

    get isCompletedList() {
      return this.$route.query.type === 'completed';
    }

    get keyword() {
      return this.$route.query.keyword;
    }

    get studentStore() {
      return this.isCompletedList ? {
        'Completed tasks': this.tasksStore
      } : {
        'Overdue': TasksStore.create({
          params: { overdue: true, no_repeat: true,  per_page: 5 }
        }),
        'Due this week': TasksStore.create({
          params: { due_this_week: true, no_repeat: true, per_page: 5 }
        }),
        'Due later': TasksStore.create({
          params: { due_later: true, no_repeat: true, per_page: 5 }
        }),
      };
    }

    get isStudentStoresEmpty() {
      const arr = _.map(this.studentStore, (store) => store.data);
      return !this.isAdvisor && _.every(arr, item => item.isFulfilled && item.length === 0);
    }

    get isAdvisor() {
      return this.$authStore.isAdvisor;
    }

    get titleName() {
      if (this.isAdvisor) {
        return 'Your student\'s';
      }

      return 'Your';
    }

    get filterGroups() {
      const subjects = _.map(this.subjectsStore.data, item => {
        return {
          name: item.name,
          value: item.id
        };
      });
      return [
        {
          key: 'subject_taggings_subject_id',
          title: 'By subject',
          allBtn: true,
          data: subjects,
        }
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();

      const isStudent = !this.isAdvisor;
      const queryToParams = [
        'keyword',
        'subject_taggings_subject_id_in',
        'student_id_in',
      ];
      if (isStudent) {
        _.map(this.studentStore, (store) => {
          store.setQueryToParams([
            store.setQueryToParams(queryToParams)
          ]);
        });
      } else {
        this.tasksStore.setQueryToParams([...queryToParams, 'overdue']);
      }
      // 获取列表过滤项数据
      await Promise.all([
        this.subjectsStore.fetchData({ page: 0 }),
        this.isAdvisor && studentsStore.fetchData({ page: 0 }), // 学生不调取studentsStore
      ]);

      /**
       * 获取列表数据
       * student：请求3次参数分别为Overdue、Due this week、Due later的task和reminder的task
       * advisor：请求普通的task list
       */
      if (isStudent) {
        const overdue = this.$get(this.$route, 'query.overdue');

        // 学生从my backpack点击overdue task/task进来
        if (overdue) {
          this.studentStore['Overdue'].fetchData();
          return;
        }

        await Promise.all([
          ..._.map(this.studentStore, (store, key) => {
            if (key === 'Completed tasks') {
              return store.fetchData({ completed_at_not_null: true });
            }
            // query上有overdue并且为false,那么不请求overdue的数据
            if (overdue === false && key === 'Overdue') {
              return false;
            } else {
              return store.fetchData();
            }
          }),
          this.reminderStore.fetchData({ page: 1, per_page: 5, reminder: true })
        ]);
      } else {
        this.tasksStore.fetchData();
      }
    }

    async handleDelete(task) {
      await this.$confirm('Are you sure you want to delete this assignment?');
      this.$autoLoading(async () => {
        await this.$request.delete(`tasks/${task.id}`);
        if (this.tasksStore.meta.total < this.tasksStore.meta.per_page) {
          this.tasksStore.removeItemById(task.id);
          return;
        }
        await this.tasksStore.fetchData();
      });
    }

    handleOpenEditPopup(task) {
      this.showTaskPopup = true;
      this.currentOpenTaskId = task.id;
    }

    @Vue.autoLoading
    async handleAddTask(body) {
      const { data } = await this.$request.post('tasks', body);
      const { query } = this.$route;

      await this.$message.success('The assignment was added successfully');

      if (query.type === 'completed') {
        this.$router.replace({ query: { ...query, type: undefined } });
        return;
      }

      // 根据是否是reminder来add
      if (data.recurrence.length > 0) {
        this.reminderStore.unshift(data);
        return;
      }

      if (this.isAdvisor) {
        this.tasksStore.unshift(data);
        return;
      }

      // 学生需要区分增加的task是overdue、due this week、due later的哪个分类
      const { due_at } = data;
      if (!dayjs(due_at).isBefore(dayjs().add(1, 'week'))) {
        this.studentStore['Due later'].unshift(data);
      } else {
        this.studentStore['Due this week'].unshift(data);
      }

    }

    @Vue.autoLoading
    async handleEditTask(body) {
      const { data } = await this.$request.put(`tasks/${this.currentOpenTaskId}`, body);
      this.$message.success('the assignment modified successfully');
      this.tasksStore.replaceItem(data);
    }

    handleToggleCompleted() {
      const { query } = this.$route;
      const type = this.isCompletedList ? undefined : 'completed';
      this.$router.replace({ query: { ...query, type, page: 1, overdue: undefined } });
    }

  }
</script>

<style lang="scss" scoped>
  .tag-wrap {
    ::v-deep .tag {
      margin: 0 8px 8px 0;
    }
  }

  .list-and-link-wrap {
    margin-top: 48px;

    @include media-lg-and-down {
      margin-top: 0;
    }
  }

  .list-wrap {
    margin-top: 48px;

    &.student-wrap {
      margin-top: 0;
    }

    .sub-title {
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 2px solid var(--border-secondary-gray);
      font-size: 24px;
      color: var(--font-third-gray);
    }
  }

  .list-line {
    width: 100%;
    margin-bottom: 25px;

    &.student-line {
      .task-status {
        width: 40%;

        @include media-lg-and-down {
          width: auto;
        }
      }

      .assign {
        width: 15%;
        font-style: italic;
        color: var(--font-primary-blue);

        @include media-lg-and-down {
          width: auto;
          margin-left: 25px;
        }
      }

      .tag-wrap {
        width: 30%;

        @include media-lg-and-down {
          width: auto;
        }
      }

      .due-time {
        width: 10%;
        font-size: 14px;
        color: var(--font-primary-blue);

        @include media-lg-and-down {
          width: auto;
        }
      }

      .msg-wrap {
        width: 5%;
        text-align: right;

        @include media-lg-and-down {
          width: auto;
        }
      }
    }
  }

  .link {
    margin-left: 150px;
    font-size: 18px;
    text-decoration: underline;
    color: var(--font-seventh-purple);
    cursor: pointer;

    @include media-lg-and-down {
      margin: 20px 0 0;
      text-align: right;
    }
  }

  .reminder-wrap {
    margin-bottom: 50px;

    .list-line {
      display: flex;
      align-items: center;

      @include media-lg-and-down {
        flex-direction: column;
        align-items: start;
      }

      .time-and-repeat-wrap {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;

        @include media-lg-and-down {
          width: 100%;
          margin-left: 0;
        }
      }

      .assign {
        @include media-lg-and-down {
          margin: 8px 0;
          margin-left: 0;
        }
      }

      .time {
        white-space: nowrap;
        color: var(--font-primary-blue);
      }
    }

    .repeat {
      margin-left: 20px;
      font-size: 16px;
      color: var(--font-secondary-black);
    }
  }

  .btn-group {
    .list-btn {
      font-size: 16px;
      text-align: center;
      text-decoration: underline;
      color: #4e4e4e;
      cursor: pointer;

      @include media-xs-only {
        &:not(:last-child) {
          margin-right: 26px;
        }
      }
    }
  }

  ::v-deep .filter-view {
    margin: 22px 0;
  }

  .empty-wrap {
    margin-top: 34px;
    font-size: 20px;
    text-align: center;
  }

  .student-item-pagination {
    margin: 20px 0;
  }
</style>
