import SparkMD5 from 'spark-md5';
import { request } from './request';
import { randomFileName } from './random';
import _ from 'lodash';

const fileSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

// https://github.com/rails/rails/blob/master/activestorage/app/javascript/activestorage/file_checksum.js
class FileChecksum {
  static create(file, callback) {
    const instance = new FileChecksum(file);
    instance.create(callback);
  }

  constructor(file) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback) {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer;
    this.fileReader = new FileReader;
    this.fileReader.addEventListener('load', event => this.fileReaderDidLoad(event));
    this.fileReader.addEventListener('error', event => this.fileReaderDidError(event));
    this.readNextChunk();
  }

  fileReaderDidLoad(event) {
    this.md5Buffer.append(event.target.result);

    if (!this.readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);
      this.callback(null, base64digest);
    }
  }

  fileReaderDidError() {
    this.callback(`Error reading ${this.file.name}`);
  }

  readNextChunk() {
    if (this.chunkIndex < this.chunkCount || (this.chunkIndex === 0 && this.chunkCount === 0)) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = fileSlice.call(this.file, start, end);
      this.fileReader.readAsArrayBuffer(bytes);
      this.chunkIndex++;
      return true;
    } else {
      return false;
    }
  }
}

function createChecksum(file) {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        return reject(error);
      }
      resolve(checksum);
    });
  });
}

export async function uploadFile(file, { tags, onProgress = _.noop } = {}) {
  const body = {
    filename: file.name || randomFileName(''),
    content_type: file.type || 'application/octet-stream',
    byte_size: file.size,
    checksum: await createChecksum(file),
    tags
  };
  onProgress(0, { ...body });
  const { data: result } = await request.post('/active_storage/direct_upload', body);
  onProgress(1, { ...body, ...result });
  await request.put(result.direct_upload.url, file.slice(), {
    headers: result.direct_upload.headers,
    timeout: 1 * 60 * 60 * 1000, // 1小时超时
    noAuthorization: true,
    onUploadProgress(progressEvent) {
      const { total, loaded } = progressEvent;
      onProgress(Math.floor(loaded / total * 100), { ...body, ...result });
    },
  });
  return {
    ...result,
    url: result.direct_upload.blob_url,
    blob_url: result.direct_upload.blob_url
  };
}
