<template>
  <div class="welcome">
    <common-started-layout>
      <div class="welcome-wrap bs-flex bs-column bs-item-center">
        <div class="title">Welcome to AtomicMind!</div>
        <div class="subtitle-1">We're here to support and guide you in your college applications journey.</div>
        <div class="subtitle-2">Let's get you set up!</div>
        <common-prod-button @click.native="handleClick" size="large">
          GET STARTED
        </common-prod-button>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class WelcomePage extends Vue {
    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      const { role, extended_info } = this.$authStore.user;
      // 如果是老师，下次就不要显示欢迎页面了；如果是学生，需要先填写完个人信息，才能更新
      if (role === 'advisor' && _.get(extended_info, 'show_start_page')) {
        await this.$authStore.updateIsShow('show_start_page', false);
      }
    }

    handleClick() {
      const { role } = this.$authStore.user;
      this.$router.replace({ name: role === 'advisor' ? 'home' : 'startPage' });
    }
  }
</script>

<style lang="scss" scoped>
  .welcome-wrap {
    padding: 176px 0 315px;
    text-align: center;

    @include media-xs-only {
      padding: 48px 32px;
    }

    div + div {
      margin-top: 46px;
    }

    .title {
      font-size: 64px;
      color: var(--font-secondary-gray);

      @include media-xs-only {
        font-size: 44px;
      }
    }

    .subtitle-1 {
      font-size: 24px;
      color: var(--font-secondary-black);
    }

    .subtitle-2 {
      font-size: 36px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        font-size: 32px;
      }
    }
  }
</style>
