<template>
  <div class="preferences">
    <div class="page-title">Preferences</div>
    <div class="settings-wrap">
      <div class="setting">Alert me about changes to my tasks, or schools</div>
      <!-- 隐藏schedule入口 -->
      <!-- <div class="setting">Alert me about changes to my tasks, schedules, or schools</div> -->
      <common-switch v-model="preferenceForm.alert_changes" @click-change="handleChange" class="switch-wrap"/>
      <div class="setting">Alert me about new comments</div>
      <common-switch v-model="preferenceForm.alert_new_comments" @click-change="handleChange" class="switch-wrap"/>
      <div class="setting">Send me "Daily Status Update" emails</div>
      <common-switch v-model="preferenceForm.send_daily_status_update_email" @click-change="handleChange" class="switch-wrap"/>

      <div class="setting select">Send me updates via:</div>
      <div class="bs-flex item-wrap" v-for="item in sendCategory" :key="item.content" @click="handleChange(item.content)">
        <div :class="['selector', { selected: preferenceForm[item.content] === true }]"></div>
        <div class="select-item">{{ item.name }}</div>
      </div>
    </div>
    <div class="footer">
      <common-prod-button
        @click.native="handleSave"
        :disabled="noChange"
        size="large"
      >SAVE CHANGES</common-prod-button>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/stores';
  import _ from 'lodash';

  @Component
  export default class Preferences extends Vue {
    preferenceForm = {
      alert_changes: false,
      alert_new_comments: false,
      send_daily_status_update_email: false,
      by_email: false,
      by_sms: false,
      by_whats_app: false,
      id: ''
    }

    authStore = authStore;
    noChange = true

    sendCategory = [
      { name: 'Email', content: 'by_email' },
      { name: 'WhatsApp', content: 'by_whats_app' },
      { name: 'Text message', content: 'by_sms' }
    ]

    @Vue.autoLoadingProgress
    async created() {
      await this.authStore.tryFetchData();
      const preferenceAttributes = _.pick(_.get(this.authStore, 'user.preference'), Object.keys(this.preferenceForm));
      Object.assign(this.preferenceForm, preferenceAttributes);
    }

    // Send me updates via 如果用户没有填写手机号码和whatsApp，点击时候需要提示
    async handleChange(type) {
      switch (type) {
        case 'by_whats_app':
          if (!_.get(this.authStore, 'user.contact.whats_app_number')) {
            await this.$confirm('You haven\'t filled in whatsApp number yet. Go ahead and fill it out？');
            this.$router.push({ name: 'profile', query: { type: 'contact' } });
          }
          break;
        case 'by_sms':
          if (!_.get(this.authStore, 'user.contact.phone')) {
            await this.$confirm('You haven\'t filled in phone number yet. Go ahead and fill it out？');
            this.$router.push({ name: 'profile', query: { type: 'contact' } });
          }
          break;
      }
      if (type) {
        this.preferenceForm[type] = !this.preferenceForm[type];
      }
      this.noChange = false;
    }

    async handleSave() {
      await this.authStore.changePreference(this.preferenceForm);
      this.$message({ message: 'Your preferences have been modified successfully', type: 'success' });
      this.noChange = true;
    }
  }
</script>

<style lang="scss" scoped>
  .setting {
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: var(--font-secondary-black);

    &.select {
      padding-top: 14px;
    }
  }

  .selector {
    position: relative;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    margin: 0 16px 0 24px;
    border: 3px solid var(--border-secondary-black);

    &.selected::after {
      position: absolute;
      top: 2px;
      left: 2px;
      display: block;
      width: 14px;
      height: 14px;
      background: var(--bg-primary-purple);
      content: '';
    }
  }

  .item-wrap {
    width: fit-content;
    cursor: pointer;

    .select-item {
      padding-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      color: var(--font-secondary-black);
    }
  }

  .footer {
    margin-top: 60px;

    ::v-deep .ato-button--large {
      @include media-xs-only {
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        margin-bottom: 60px;
      }
    }
  }

  .switch-wrap {
    width: 250px;
    margin-bottom: 40px;
  }

</style>
