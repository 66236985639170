<template>
  <div>
    <div class="bs-flex group bs-item-baseline">
      <i class="el-icon-time input-icon"/>
      <div>
        <div class="date-wrapper">
          <div>
            <span>{{ startDateText }}</span>
            <span v-if="dateShow.showTime" style="margin-left: 0.52466rem;">{{ startTime }}</span>
            <span style="margin: 0 0.78699rem;">-</span>
            <span v-if="dateShow.showTime" style="margin-right: 0.52466rem;">{{ endTime }}</span>
            <span v-if="dateShow.showEndDate">{{ endDateText }}</span>
          </div>
          <div v-if="isAllday">All day</div>
        </div>
        <div v-if="recurrence">{{ recurrence }}</div>
      </div>
    </div>
    <div class="bs-flex group bs-item-start" v-if="showUsers">
      <i class="el-icon-user input-icon" style="margin-top: 0.52466rem;"/>
      <div>
        <div class="item-user">
          <el-avatar class="avatar" :size="20" :src="$get(event, 'creator.avatar.url') || defaultAvatar"/>
          <span>(Organizer) {{ $get(event, 'creator.name') }}</span>
        </div>
        <div class="item-user" v-for="item in event.attendance_users" :key="item.id">
          <el-avatar class="avatar" :size="20" :src="$get(item, 'avatar.url') || defaultAvatar"/>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="bs-flex group bs-item-baseline" v-if="event.desc">
      <i class="el-icon-tickets input-icon" style="margin-top: 0.52466rem;"/>
      <div class="bs-flex-1">
        <common-richtext-editor :value="event.desc" only-read/>
      </div>
    </div>
    <div class="bs-flex group bs-item-center">
      <i class="el-icon-goods input-icon"/>
      <div>{{ transparency }}</div>
    </div>
    <div class="bs-flex group bs-item-center" v-if="event.need_google_meet && event.hangout_link">
      <i class="el-icon-video-camera input-icon"/>
      <a :href="event.hangout_link" target="_blank">Google meet</a>
    </div>
    <div class="bs-flex group bs-item-baseline" v-if="!isEmpty(overrides)">
      <i class="el-icon-bell input-icon"/>
      <div>
        <div v-for="(item, index) in overrides" :key="index">{{ item }}</div>
      </div>
    </div>
    <div class="btns-wrapper">
      <template
        v-if="event.kind !== 'task' || $get(event, 'creator_id') === $get($authStore, 'user.id')"
      >
        <el-button plain size="mini" type="info" @click="innerVisible = true">Delete</el-button>
        <el-button plain size="mini" type="primary" @click="$emit('edit')">Edit</el-button>
      </template>
      <template v-if="$get(event, 'task.id')">
        <el-button
          v-if="['init', 'wait_advisor_review'].includes(taskStatus)"
          plain
          size="mini"
          type="primary"
          :disabled="taskDisabled"
          @click="$emit('toggle-task-status', event.task)"
        >
          {{ $authStore.isAdvisor ? 'Mark Complete' : (taskStatus === 'wait_advisor_review' ? 'Awaiting Review' : 'I\'m Done') }}
        </el-button>
        <el-button
          v-if="taskStatus !== 'init'"
          plain
          size="mini"
          type="primary"
          @click="$emit('toggle-task-status', event.task, true)"
        >
        {{ $authStore.isAdvisor ? 'Request Revision' : 'Withdraw submission' }}
        </el-button>
      </template>
    </div>
    <common-prod-dialog
      title="Delete event?"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" plain @click="innerVisible = false">Cancel</el-button>
        <el-button size="mini" type="primary" plain @click="handleEmitDelete">Confirm</el-button>
      </div>
    </common-prod-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import DatePicker from './date-picker/index.vue';
  import RepeatSelect from './repeat-select/index.vue';
  import { RRule } from 'rrule';
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import { translateNotificationLabel } from '../utils/translate-notification-label';
  import { displayDate } from '@/utils/display-date';
  import defaultAvatar from '@/assets/default-avatar.png';

  @Component({
    components: {
      DatePicker,
      RepeatSelect,
    }
  })
  export default class EventDetail extends Vue {
    @Prop({ type: Object, default: () => ({}) }) event

    innerVisible = false
    defaultAvatar = defaultAvatar

    isEmpty = _.isEmpty

    get recurrence() {
      const val = _.get(this.event, 'recurrence[0]');
      return val ? _.upperFirst(RRule.fromString(val).toText()) : '';
    }

    get showUsers() {
      return !_.isEmpty(_.get(this.event, 'attendance_users'));
    }

    get isAllday() {
      return _.get(this.event, 'is_all_day');
    }

    get transparency() {
      return {
        transparent: 'Free',
        opaque: 'Busy',
      }[this.event.transparency];
    }

    get startTime() {
      return dayjs(this.event.start_time, 'HH:mm').format('hh:mma');
    }

    get endTime() {
      return dayjs(this.event.end_time, 'HH:mm').format('hh:mma');
    }

    get overrides() {
      const overrides = _.get(this.event, 'reminders.overrides') || [];
      return overrides.map(item => translateNotificationLabel(item.minutes, this.isAllday));
    }

    get dateShow() {
      const { start_date, end_date } = this.event;
      return displayDate(start_date, end_date, this.isAllday);
    }

    get startDateText() {
      return dayjs(this.event.start_date).format('MMM DD, YYYY');
    }

    get endDateText() {
      return dayjs(this.event.end_date).format('MMM DD, YYYY');
    }

    get taskStatus() {
      return this.$get(this.event, 'task.status');
    }

    get taskDisabled() {
      const isAdvisor = this.$get(this.$authStore, 'isAdvisor');
      // 如果是老师，只有在init和wait_advisor_review状态下才可以点击
      // 如果是学生，只有在init状态下才可以点击
      return isAdvisor
        ? !['init', 'wait_advisor_review'].includes(this.taskStatus)
        : this.taskStatus !== 'init';
    }

    handleEmitDelete() {
      this.innerVisible = false;
      this.$emit('delete');
    }
  }
</script>

<style lang="scss" scoped>
  .input-icon {
    margin-right: 15px;

    &.hidden {
      visibility: hidden;
    }
  }

  .group {
    margin-bottom: 20px;

    .date-wrapper {
      position: relative;

      ::v-deep .el-checkbox {
        display: none;
      }
    }

    .item-user {
      display: flex;
      align-items: center;
      margin: 5px 0;

      .avatar {
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .btns-wrapper {
    text-align: right;

    &:not(:last-child) {
      .el-button {
        margin-left: 20px;
      }
    }
  }
</style>
