<template>
  <div class="date-wrap bs-flex bs-item-center bs-content-between" :class="{ disabled }">
    <img v-if="$isDarkTheme" class="icon" src="@/assets/dark-date.png" mode="widthFix" alt="">
    <img v-else class="icon" src="@/assets/date.png" mode="widthFix" alt="">
    <el-date-picker
      v-model="inputLabel"
      :type="type"
      :placeholder="placeholder"
      :clearable="false"
      :editable="false"
      :picker-options="getDisabledDate"
      format="MMM d, yyyy HH:mm A"
      value-format="yyyy-MM-dd HH:mm"
      :disabled="disabled"
      ref="datePicker"
      @change="handleChange"
    ></el-date-picker>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class DatePicker extends Vue {
    @Prop({ type: String, default: 'datetime' }) type
    @Prop({ type: String, default: 'No date' }) placeholder
    @Prop({ type: String }) value
    @Prop({ type: Object, default: () => ({}) }) getDisabledDate
    @Prop(Boolean) disabled

    inputLabel = ''

    @Watch('value', { immediate: true })
    valueChange(val) {
      if (!val) { return this.inputLabel = ''; }
      this.inputLabel = new Date(val);
    }

    mounted() {
      this.$nextTick(() => {
        if (!this.$refs.datePicker.picker) {
          this.$refs.datePicker.mountPicker();
        }
        // format 为 “MMM d, yyyy HH:mm A”，time Format 会显示成 “, HH:mm A”
        this.$refs.datePicker.picker.format = 'MMM d,yyyy HH:mm A';
      });
    }

    handleChange(e) {
      this.$emit('input', String(e));
      this.$emit('change');
    }
  }
</script>

<style lang="scss" scoped>

  .date-wrap {
    box-sizing: border-box;
    height: 100%;

    .icon {
      width: 24px;
      height: 24px;
      padding-left: 20px;
    }

    &.disabled {
      background: #f5f7fa;
    }
  }

  ::v-deep .el-date-editor {
    width: 100%;
    padding: 0 20px;

    input {
      padding: 0;
      border: none;
      outline: none;
      font-size: 24px;
      color: var(--font-secondary-black);
      background-color: transparent;

      &::placeholder {
        color: var(--font-secondary-gray);
      }
    }

    .el-input__prefix {
      display: none;
    }
  }
</style>
