<template>
  <div class="common-sidebar">
    <div class="side-wrap bs-flex bs-column">
      <router-link :to="{ name: 'home' }" class="logo hidden-xs-only">
        <img class="hidden-md-and-down logo-img" src="@/assets/atomicmind-grow-logo.svg" alt="">
        <img class="hidden-lg-and-up mobile-logo" src="@/assets/atomicmind-grow-logo.svg" alt="">
      </router-link>
      <div class="router-wrap bs-flex-1 bs-flex bs-column" @click="handleShowTip">
        <router-link
          class="side-nav bs-flex bs-item-center"
          :class="{ 'show-guide': showGuide && currentGuideStep === $get(item, 'guideStep')}"
          v-for="(item, index) in routerList"
          :key="index"
          :to="{name: item.name}"
          :exact="item.name === 'home'"
        >
          <img class="nav-icon" :src="$route.path.includes(item.name) ? item.selectedIcon :item.icon" alt="">
          <span class="nav-text hidden-xs-only hidden-md-and-down">{{ item.text }}</span>
        </router-link>
      </div>
      <div class="side-footer bs-content-end bs-flex bs-column hidden-xs-only">
        <!-- <div class="foot-icon bs-flex">
          <img src="@/assets/sidebar/theme.png" alt="" @click="$toggleDarkTheme">
          <img src="@/assets/sidebar/present.png" alt="">
        </div> -->
        <div class="foot-text">{{`Copyright © ${new Date().getFullYear()} AtomicMind. All rights reserved.`}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class CommonSideBar extends Vue {
    @Prop([Number, String]) currentGuideStep
    @Prop(Boolean) showGuide

    get routerList() {
      const isAdvisor = this.$authStore.isAdvisor;
      return [
        {
          name: 'home',
          icon: require('@/assets/sidebar/icon-dashboard.png'),
          selectedIcon: require('@/assets/sidebar/icon-dashboard.png'),
          text: 'Dashboard',
          guideStep: 1,
        },
        {
          name: 'calendar',
          icon: require('@/assets/sidebar/icon-calendar.png'),
          selectedIcon: require('@/assets/sidebar/icon-calendar-selected.png'),
          text: 'Calendar',
        },
        isAdvisor && {
          name: 'students',
          icon: require('@/assets/sidebar/icon-person.png'),
          selectedIcon: require('@/assets/sidebar/icon-person-selected.png'),
          text: 'Students',
        },
        !isAdvisor && {
          name: 'backpack',
          icon: require('@/assets/sidebar/icon-backpack.png'),
          selectedIcon: require('@/assets/sidebar/icon-backpack-selected.png'),
          text: 'My Backpack',
          guideStep: 2,
        },
        !isAdvisor && {
          name: 'team',
          icon: require('@/assets/sidebar/icon-person.png'),
          selectedIcon: require('@/assets/sidebar/icon-person-selected.png'),
          text: 'My Team',
        },
        {
          name: 'tasks',
          icon: require('@/assets/sidebar/icon-tasks.png'),
          selectedIcon: require('@/assets/sidebar/icon-tasks-selected.png'),
          text: 'Assignments',
          guideStep: 3,
        },
        {
          name: 'notes',
          icon: require('@/assets/sidebar/icon-notes.png'),
          selectedIcon: require('@/assets/sidebar/icon-notes-selected.png'),
          text: 'Notes',
        },
        {
          name: 'files',
          icon: require('@/assets/sidebar/icon-files.png'),
          selectedIcon: require('@/assets/sidebar/icon-files-selected.png'),
          text: 'Files',
        },
      ].filter(Boolean);
    }

    // 如果学生没有 advisor 提示去联系 team
    handleShowTip() {
      if (this.$authStore.isUnpaidStudent) {
        this.$alertMsg('You have not been assigned a Head Advisor yet, please contact our staff.');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .common-sidebar {
    box-sizing: border-box;
    width: 300px;
    height: 100vh;
    padding: 26px 42px;
    color: var(--font-primary-white);
    background: var(--linear-gradient-purple);

    @include media-lg-and-down {
      width: 120px;
      padding: 26px 20px;
    }

    @include media-xs-only {
      box-sizing: content-box;
      width: 100%;
      height: 48px;
      padding: 5px 0;
      background: var(--bg-seventh-purple);

      @include paddingBottomSafeArea(5px);
    }

    .side-wrap {
      height: 100%;

      .logo {
        margin-bottom: 70px;
        text-align: center;
        cursor: pointer;

        .logo-img {
          width: 200px;
          height: auto;
        }

        .mobile-logo {
          width: 100%;
          height: auto;
        }
      }

      .router-wrap {
        height: 100%;
        text-align: center;

        @include media-xs-only {
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
        }

        .side-nav {
          margin-bottom: 47px;
          font-size: 18px;
          opacity: 0.68;

          &.show-guide {
            position: relative;
            z-index: 1001;
          }

          @include media-xs-only {
            margin-bottom: 0;
          }

          @include media-lg-and-down {
            justify-content: center;
          }

          &.router-link-active {
            font-family: 'Neue Montreal Bold';
            opacity: 1;
          }

          .nav-icon {
            width: 20px;
            height: 20px;

            @include media-xs-only {
              width: 26px;
              height: 26px;
            }
          }

          .nav-text {
            margin-left: 18px;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .side-footer {
      text-align: left;

      .foot-icon {
        margin-bottom: 15px;

        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        img + img {
          margin-left: 20px;
        }
      }

      .foot-text {
        font-size: 10px;
        font-family: 'Neue Montreal Regular';
        line-height: 17px;
        opacity: 0.5;
      }
    }
  }
</style>
