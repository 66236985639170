<template>
  <div>
    <router-link :to="{ name: 'tasks' }" class="back-btn">Back to all tasks</router-link>
    <div class="page-title">{{ keyword ? `Search "${keyword}" results` : `${titleName} Reminders` }}</div>

    <common-filter-view v-if="$authStore.isAdvisor" />
    <common-prod-filter-groups v-else :groups="filterGroups"/>

    <div class="list-wrap">
      <div v-for="item in reminderStore.data" :key="item.id" class="list-line student-line bs-flex">
        <common-task-status class="task-status" :task="item" @finish="reminderStore.finishTask(item)" :to="{ name: 'task.detail', params: { id: item.id } }" />
        <div class="assign">{{`Assigned by ${$get(item, 'creator.name')}`}}</div>
        <div class="tag-wrap">
          <common-prod-tag v-for="tag in $get(item, 'subjects', [])" :key="tag.id"
            :tag="tag"
            :showClose="false"
          />
          <common-prod-tag v-if="$authStore.isAdvisor && item.student"
            :tag="item.student"
            :showClose="false"
          />
        </div>
        <div class="time-and-repeat-wrap">
          <div class="time">{{ `Due ${$options.filters.formatTime(item.due_at, 'MMM DD, YYYY')}` }}</div>
          <div class="repeat">{{ reminderStore.getRepeatLabel(item) }}</div>
        </div>
      </div>
    </div>

    <common-prod-pagination :store="reminderStore"/>

    <div v-if="reminderStore.isEmpty" class="empty-wrap">You have no reminders</div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { TasksStore, subjectsStore, studentsStore } from '@/stores';
  import _ from 'lodash';

  @Component
  export default class Reminders extends Vue {
    reminderStore = TasksStore.create()
    subjectsStore = subjectsStore

    get keyword() {
      return this.$route.query.keyword;
    }

    get titleName() {
      if (this.$authStore.isAdvisor) {
        return 'Your student\'s';
      }

      return 'Your';
    }

    get filterGroups() {
      const subjects = _.map(this.subjectsStore.data, item => {
        return {
          name: item.name,
          value: item.id
        };
      });
      return [
        {
          key: 'subject_taggings_subject_id',
          title: 'By subject',
          allBtn: true,
          data: subjects,
        }
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      this.reminderStore.setQueryToParams([
        'keyword',
        'subject_taggings_subject_id_in',
        'student_id_in'
      ]);
      await Promise.all([
        this.reminderStore.fetchData({ reminder: true }),
        this.subjectsStore.fetchData({ page: 0 }),
        this.$authStore.isAdvisor && studentsStore.fetchData({ page: 0 }) // 学生不调取studentsStore
      ]);

    }
  }
</script>

<style lang="scss" scoped>
  .back-btn {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: underline;
    color: var(--font-seventh-purple);
  }

  .list-wrap {
    margin-top: 48px;
  }

  .list-line {
    display: flex;
    width: 100%;
    margin-bottom: 25px;

    @include media-lg-and-down {
      flex-direction: column;
    }

    .task-status {
      width: 30%;

      @include media-lg-and-down {
        width: 100%;
      }
    }

    .assign {
      width: 15%;
      font-style: italic;
      color: var(--font-primary-blue);

      @include media-lg-and-down {
        width: 100%;
        margin: 8px 0;
        margin-left: 0;
      }
    }

    .tag-wrap {
      width: 20%;

      @include media-lg-and-down {
        width: auto;
      }

      ::v-deep .tag {
        margin: 0 8px 8px 0;
      }
    }

    .time-and-repeat-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 35%;

      @include media-lg-and-down {
        width: 100%;
        margin: 5px 0;
      }
    }

    .time {
      white-space: nowrap;
      color: var(--font-primary-blue);
    }

    .repeat {
      margin-left: 10px;

      @include text-overflow();
    }
  }

  .empty-wrap {
    margin-top: 34px;
    font-size: 20px;
    text-align: center;
  }
</style>
