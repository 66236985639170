import { SimpleStore } from './helper/simple-store';
import Observable from './helper/observable';
import { request, loginSuccessRedirect } from '@/utils';
import { ACCESS_TOKEN_KEY, CURRENT_STUDENT_ID, GOOGLE_REDIRECT_URL } from '@/constants';
import _ from 'lodash';
import router from '@/router/index.js';
import dayjs from 'dayjs';

class User extends Observable {
}

class AuthStore extends SimpleStore {
  user = User.create()
  $currentStudentId = localStorage.getItem(CURRENT_STUDENT_ID)

  get currentStudentId() {
    return this.$currentStudentId;
  }

  set currentStudentId(v) {
    this.$currentStudentId = v;
    localStorage.setItem(CURRENT_STUDENT_ID, v);
  }

  $accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)

  set accessToken(v) {
    this.$accessToken = v;
    localStorage.setItem(ACCESS_TOKEN_KEY, v);
  }

  get accessToken() {
    return this.$accessToken;
  }

  get isAdvisor() {
    return _.get(this.user, 'role') === 'advisor';
  }

  get isUnpaidStudent() {
    return !this.isAdvisor && !_.get(this.user, 'head_advisor');
  }

  get timeZone() {
    return _.get(this.user, 'contact.time_zone') || dayjs.tz.guess();
  }

  get studentTimeZone() {
    return _.get(this.user, 'current_role.contact.time_zone') || _.get(this.user, 'contact.time_zone') || dayjs.tz.guess();
  }

  async fetchData() {
    return this.fetching(async () => {
      this.fetchDataPromise = request.get('mine').finally(() => this.fetchDataPromise = null);
      const { data } = await this.fetchDataPromise;
      this.user = data;
      this.setCurrentStudentId();
    });
  }

  setCurrentStudentId() {
    if (_.get(this.user, 'role') === 'student') {
      this.currentStudentId = _.get(this.user, 'id') || '';
    }
  }

  // type值 show_start_page: 是否显示开始页;  show_quick_tutorial: 是否显示 quick tutorial 视频;  show_tool_guide: 是否显示导航指引
  async updateIsShow(type, value) {
    const params = {};
    params[type] = value;
    await request.put('user/extended_infos', params);
    _.set(this.user, `extended_info.${type}`, value);
  }

  login(body, apiUrl = 'sessions') {
    return this.fetching(async () => {
      const { data } = await request.post(apiUrl, body);

      const reg = new RegExp(`https?://${location.host}/?`);
      const { VUE_APP_ENV, VUE_APP_LOGIN_HOST } = process.env;
      // CAP 和 FJP 登录为统一域名，如果是登录域名登录，就不要保存 token 等信息
      if (VUE_APP_ENV === 'development' || !reg.test(VUE_APP_LOGIN_HOST)) {
        this.accessToken = data.token;
        this.user = data.user;
        this.setCurrentStudentId();
      }
      return data;
    });
  }

  loginAfterNav(redirect) {
    if (_.get(this, 'user.force_update_password')) {
      return router.replace({ name: 'resetPassword' });
    } else if (_.get(this, 'user.extended_info.show_start_page')) {
      return router.replace({ name: 'welcomePage' });
    } else if (_.isFunction(redirect)) {
      redirect();
    } else {
      loginSuccessRedirect();
    }
  }

  async loginWithGoogle(code) {
    const { data } = await request.post('auth/google/login', {
      code,
      redirect_url: GOOGLE_REDIRECT_URL,
    });
    this.accessToken = data.token || this.accessToken;
    this.user = data.user || this.user;
  }

  async registration(body) {
    const { data } = await request.post('registrations', body);
    this.accessToken = data.token;
    this.user = data.user;
    this.setCurrentStudentId();
    return data;
  }

  logout() {
    this.accessToken = '';
    this.currentStudentId = '';
    this.user = {};
    this.isFulfilled = false;

    return this.iframeLogout();
  }

  // 通知 CAP 退出登录
  iframeLogout() {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement('iframe');
      iframe.onload = () => {
        iframe.contentWindow.postMessage({ type: 'logout' }, '*');
        setTimeout(() => {
          document.body.removeChild(iframe);
          resolve();
        }, 100);
      };
      iframe.onerror = reject;
      iframe.src = process.env.VUE_APP_CAP_HOST;
      document.body.appendChild(iframe);
    });
  }

  async changePassword(body, logout) {
    await request.put('mine/change_password', body);
    if (logout) {
      this.logout();
      router.replace({ name: 'login' });
    }
  }

  async changePreference(body) {
    const { data } = await request.put('mine/preference', { preference_attributes: body });
    this.user = data;
  }

  async updatePersonalInfo(body) {
    const { data } = await request.put('mine', body);
    this.user = data;
  }

  async changeContactInfo(body) {
    const { data } = await request.put('mine/contact', { contact_attributes: body });
    this.user = data;
  }
}

export const authStore = AuthStore.create();
