<template>
  <div class="file-list-wrapper">
    <div class="bs-flex bs-item-center item" v-for="item in files" :key="item.id">
      <div class="item-line bs-flex bs-item-center">
        <FileItem :file="item"/>

        <div class="bs-flex-1 bs-flex bs-item-center hidden-md-and-down">
          <div class="time">
            {{ item.updated_at | formatTime('MMM DD, YYYY') }}
          </div>
          <div v-if="showTag" class="tag-wrap">
            <common-prod-tag v-for="tag in $get(item, 'document_taggings', [])" :key="tag.id"
              :tag="tag"
              :showClose="false"
              :routeTo="tag.taggable_type === 'Task' ? { name: 'task.detail', params: { id: tag.taggable_id } } : {}"
            />
            <common-prod-tag v-if="$authStore.isAdvisor && item.student"
              :tag="item.student"
              :showClose="false"
              :routeTo="{ name: 'students', query: { keyword: $get(item, 'student.name'), id_eq: $get(item, 'student.id') } }"
            />
          </div>
          <el-popconfirm
            v-if="(isNormal && $authStore.isAdvisor) || item.uploader_id === $get($authStore, 'user.id')"
            confirm-button-text='Delete'
            cancel-button-text='Close'
            icon="el-icon-info"
            icon-color="red"
            title="Confirm delete?"
            @confirm="$emit('delete-item', item)"
          >
            <div slot="reference" class="delete-btn">Delete</div>
          </el-popconfirm>
        </div>

        <div class="hidden-lg-and-up" style="width: 100%">
          <div v-if="showTag" class="tag-wrap">
            <common-prod-tag v-for="tag in $get(item, 'document_taggings', [])" :key="tag.id"
              :tag="tag"
              :showClose="false"
            />
            <common-prod-tag v-if="$authStore.isAdvisor && item.student"
              :tag="item.student"
              :showClose="false"
              :routeTo="{ name: 'students', query: { keyword: $get(item, 'student.name'), id_eq: $get(item, 'student.id') } }"
            />
          </div>
          <div class="time-delete-wrap bs-flex bs-content-between">
            <div class="time">
              {{ item.updated_at | formatTime('MMM DD, YYYY') }}
            </div>
            <el-popconfirm
              v-if="(isNormal && $authStore.isAdvisor) || item.uploader_id === $get($authStore, 'user.id')"
              confirm-button-text='Delete'
              cancel-button-text='Close'
              icon="el-icon-info"
              icon-color="red"
              title="Confirm delete?"
              @confirm="$emit('delete-item', item)"
            >
              <div slot="reference" class="delete-btn">Delete</div>
            </el-popconfirm>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import FileItem from './file-item.vue';

  @Component({
    components: {
      FileItem
    }
  })
  export default class FileLists extends Vue {
    @Prop({ type: Array, default: () => [] }) files
    @Prop({ type: Boolean, default: true }) isNormal
    @Prop({ type: Boolean, default: false }) showTag
  }
</script>

<style lang="scss" scoped>
  .file-list-wrapper {
    .item {
      position: relative;
      margin: 25px 0;

      .item-line {
        width: 100%;
        font-size: 24px;

        @include media-lg-and-down {
          flex-direction: column;
          align-items: flex-start;
        }

        ::v-deep .file-item {
          width: 35%;

          @include text-overflow();

          @include media-lg-and-down {
            width: 100%;
          }
        }

        .time {
          width: 15%;
          margin-left: 10px;
          font-size: 16px;
          color: var(--font-primary-blue);

          @include media-lg-and-down {
            width: auto;
            margin-left: 0;
          }
        }
      }

      .delete-btn {
        font-size: 16px;
        text-align: center;
        text-decoration: underline;
        color: #4e4e4e;
        cursor: pointer;

        @include media-lg-and-down {
          width: auto;
        }
      }
    }

    .time-delete-wrap {
      width: 100%;
      margin-top: 16px;
    }

    .tag-wrap {
      width: 60%;

      @include media-lg-and-down {
        width: auto;
        margin-top: 16px;
      }

      ::v-deep .tag {
        margin: 0 8px 8px 0;
      }
    }
  }
</style>
