import _ from 'lodash';
import qs from 'qs';
import axios from 'axios';
import router from '../router';
import dayjs from 'dayjs';
import decoder from './decoder';
import { REDIRECT_URL_SESSION_KEY } from '@/constants';
import { MessageBox } from 'element-ui';

const request = axios.create({
  baseURL: '/app_api/v1',
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
  transformRequest: [(data, headers) => {
    if (!headers['Current-Role-Id']) {
      const { authStore } = require('@/stores/auth-store');
      headers['Current-Role-Id'] = _.get(authStore, 'currentStudentId');
    }
    if (headers['Content-Type'] === 'application/json' && _.isObject(data)) {
      return JSON.stringify(data);
    }
    headers['Local-Time-Zone'] = dayjs.tz.guess();
    return data;
  }],
});

request.interceptors.request.use(config => {
  const { headers, noAuthorization } = config;
  if (!headers['Authorization'] && !noAuthorization) {
    const { authStore } = require('@/stores/auth-store');
    headers['Authorization'] = authStore.accessToken;
  }

  return config;
});

const goToLogin = _.debounce(function(message) {
  if (_.get(router, 'currentRoute.name') === 'login') {
    return;
  }
  MessageBox({
    title: 'Tips',
    message,
    confirmButtonText: 'Login again',
    type: 'error'
  }).finally(() => {
    const currentRouteFullPath = _.get(router, 'currentRoute.fullPath') || '';
    const { authStore } = require('@/stores');
    authStore.logout();
    currentRouteFullPath && sessionStorage.setItem(REDIRECT_URL_SESSION_KEY, currentRouteFullPath);
    router.replace({ name: 'login' });
  });
}, 200);

request.interceptors.response.use(
  res => {
    res.isResponse = true;
    res.meta = {};
    _.forEach(res.headers, (v, k) => {
      if (/^x-/i.test(k)) {
        const key = _.snakeCase(k.replace(/^x-/i, ''));
        res.meta[key] = decoder(v);
      }
    });
    return res;
  },
  err => {
    const response = _.get(err, 'response', {});
    if (response.data) {
      const { error_message, messages, error, code } = response.data;
      err.message = error_message || messages || error || err.message;
      err.code = code;
    }
    err.status = response.status;
    const currentRouteFullPath = _.get(router, 'currentRoute.fullPath') || '';
    const currentRouteName = _.get(router, 'currentRoute.name');
    if (response.status === 401 && !['login', 'login.simpleSso'].includes(currentRouteName)) {
      goToLogin('The session has expired, please Sign in again');
      err.message = `ignore! ${err.message}`;
      return Promise.reject(err);
    }
    if (response.status === 403) {
      // 40304 禁用用户
      if (err.code === 40304) {
        goToLogin(err.message);
      } else if (err.code === 40301 && currentRouteName !== 'startPage') {
        router.replace({ name: 'startPage', query: { redirect_uri: encodeURIComponent(currentRouteFullPath), step: 'bindGoogle' } });
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export { request };
