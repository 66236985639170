<template>
  <div class="students">
    <div class="page-title">{{ keyword ? `Search "${keyword}" results` : 'Students' }}</div>

    <div class="students-wrap">
      <el-collapse v-if="$get(studentsStore, 'data.length') > 0">
        <el-collapse-item v-for="student in studentsStore.data" :key="student.id">
          <template slot="title">
            <div class="title-wrap bs-flex bs-item-center bs-content-between">
              <div class="info-and-overdue-wrap bs-flex bs-item-center bs-content-between">
                <div class="student-info-wrap bs-flex bs-item-center">
                  <img class="avatar" :src="$get(student, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
                  <h2 class="name text-overflow">{{ student.name }}</h2>
                  <span class="email hidden-xs-only" v-if="student.email">({{ student.email }})</span>
                </div>
                <router-link
                  :to="{ name: 'tasks', query: { overdue: true, student_id_in: [student.id] } }"
                  class="info overdue-task-font-color bs-no-shirk"
                  :class="{ 'no-font-color': student.overdue_tasks_count === 0 }"
                >
                  {{ student.overdue_tasks_count | pluralize('overdue assignment', true) }}
                </router-link>
              </div>
              <div class="operate-buttons bs-flex bs-item-center">
                <div v-if="student.session_room_link"
                  @click.stop="studentsStore.handleOpenLink(student.session_room_link)"
                  class="btn session-room-btn"
                >
                  <img class="video-icon" src="@/assets/video-icon-white.png" alt="">
                  <span class="hidden-sm-and-down">Session Room</span>
                </div>
                <a v-if="student.email" class="btn" :href="`mailto:${student.email}`">Email</a>
                <div v-if="$get(student, 'contact.whats_app_number')" @click="studentsStore.handleWhatsApp(student)" class="btn">WhatsApp</div>
              </div>
            </div>

          </template>

          <el-row :gutter="24">
            <!-- profile -->
            <el-col :md="6" :xs="24">
              <common-prod-card title="profile">
                <div class="card-content bs-flex bs-column bs-content-between">
                  <div v-if="student.profile.grade || student.profile.high_school">
                    <div class="info profile-info" v-if="student.profile.grade">
                      <strong class="label">Grade</strong>
                      <span class="value">{{ student.profile.grade }}</span>
                    </div>
                    <div class="info profile-info" v-if="student.profile.high_school">
                      <strong class="label">High School</strong>
                      <span class="value">{{ student.profile.high_school }}</span>
                    </div>
                  </div>
                  <router-link
                    :to="{ name: 'student.detail', params: { id: student.id } }"
                    class="info"
                  >
                    Full student profile
                  </router-link>
                </div>
              </common-prod-card>
            </el-col>
            <!-- subjects -->
            <el-col :md="6" :xs="24" v-for="subject in student.related_subjects" :key="subject.id">
              <common-prod-card :title="subject.name">
                <div class="card-content">
                  <router-link
                    :to="{ name: 'tasks', query: { subject_taggings_subject_id_in: [subject.id], overdue: true, student_id_in: [student.id] } }"
                    class="info overdue-task-font-color"
                    :class="{ 'no-font-color': !subject.overdue_tasks_count}"
                  >
                    {{ subject.overdue_tasks_count | pluralize('overdue assignment', true) }}
                  </router-link>
                  <router-link
                    :to="{ name: 'tasks', query: { overdue: false, subject_taggings_subject_id_in: [subject.id], student_id_in: [student.id] } }"
                    class="info"
                    :class="{ 'no-font-color': !subject.tasks_count}"
                  >
                    {{ subject.tasks_count | pluralize('assignment', true) }}
                  </router-link>
                  <router-link
                    :to="{ name: 'files', query: { document_taggings_taggable_of_Subject_type_id_in: [subject.id], student_id_in: [student.id] } }"
                    class="info"
                    :class="{ 'no-font-color': !subject.documents_count }"
                  >
                    {{ subject.documents_count | pluralize('file', true) }}
                  </router-link>
                  <router-link
                    :to="{ name: 'notes', query: { subject_taggings_subject_id_in: [subject.id], student_id_in: [student.id] } }"
                    class="info"
                    :class="{ 'no-font-color': !subject.notes_count }"
                  >
                    {{ subject.notes_count | pluralize('note', true) }}
                  </router-link>
                </div>
              </common-prod-card>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <div v-else class="empty">
        {{ keyword ? 'Sorry, there are currently no related students found' : 'Sorry, there are currently no bound students'}}
      </div>
      <common-prod-pagination :store="studentsStore"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { StudentsStore } from '@/stores';

  @Component
  export default class Students extends Vue {
    studentsStore = StudentsStore.create()

    get keyword() {
      return this.$route.query.keyword;
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.fetchData();
    }

    async fetchData() {
      this.studentsStore.setQueryToParams([
        'id_eq',
      ]);
      Object.assign(this.studentsStore.params, { on_dashboard: false });
      await this.studentsStore.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-collapse {
    border: none;

    .el-collapse-item__header,
    .el-collapse-item__content {
      border: none;
      color: var(--font-secondary-black);
      background: var(--bg-primary-gray);
    }

    .el-collapse-item__header {
      padding: 24px;
    }

    .el-collapse-item__content {
      padding: 0 16px 24px;
    }

    .el-collapse-item__wrap {
      border: none;
    }

    .el-collapse-item__arrow {
      &::before {
        font-weight: bold;
        font-size: 32px;
        color: var(--font-secondary-black);
        content: '+';
      }

      &.is-active {
        transform: none;

        &::before {
          content: '-';
        }
      }
    }

    .el-collapse-item + .el-collapse-item {
      margin-top: 16px;
    }
  }

  div ::v-deep .card {
    display: flex;
    flex-direction: column;
    height: 264px;
    border-radius: 8px;
    background: var(--bg-primary-white);

    @include media-xs-only {
      height: auto;
    }

    &.nothing {
      .card-content {
        font-style: italic;
        font-size: 24px;
        color: var(--font-secondary-gray);
      }
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0;
      padding-top: 24px;
    }

    .card-content {
      flex: 1;
    }
  }

  .el-row {
    padding: 0 86px;

    @include media-xs-only {
      padding: 0 50px;
    }
  }

  .el-col {
    margin-bottom: 24px;

    @include media-xs-only {
      margin-bottom: 0;
    }
  }

  .students-wrap {
    position: relative;
    margin-right: 152px;

    @include media-lg-and-down {
      margin-right: 0;
    }

    .title-wrap {
      width: 100%;
      padding-right: 43px;

      @include media-xs-only {
        display: block;
        padding-right: 20px;
      }
    }

    .info-and-overdue-wrap {
      width: 60%;

      @include media-xs-only {
        width: 100%;
      }
    }

    .student-info-wrap {
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        @include media-xs-only {
          width: 40px;
          height: 40px;
        }
      }

      .name {
        margin-left: 24px;
        color: var(--font-secondary-black);

        @include media-xs-only {
          margin-left: 12px;
          font-size: 20px;
        }
      }

      .email {
        margin-left: 12px;
        color: var(--font-third-gray);
      }
    }

    .operate-buttons {
      .session-room-btn {
        .video-icon {
          width: 25px;
          height: 14px;
          margin-right: 8px;

          @include media-md-and-down {
            margin-right: 0;
          }
        }
      }
    }

    .info {
      display: block;
      font-style: italic;
      text-decoration: underline;
      color: var(--font-primary-purple);

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &.overdue-task-font-color {
        color: var(--font-primary-red);
      }

      &.no-font-color {
        color: var(--font-secondary-gray);
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      font-style: normal;
      text-decoration: none;
      color: $primary-black;

      .label {
        font-size: 18px;
      }

      .value {
        line-height: 1.2;
      }
    }

    .btn {
      &:not(:last-child) {
        margin-right: 24px;

        @include media-xs-only {
          margin-right: 12px;
        }
      }
    }
  }

  .empty {
    margin: 80px 0;
    font-size: 36px;
    color: var(--font-secondary-black);
  }
</style>
