import { render, staticRenderFns } from "./activity-msg.vue?vue&type=template&id=bb5b0290&scoped=true&"
import script from "./activity-msg.vue?vue&type=script&lang=js&"
export * from "./activity-msg.vue?vue&type=script&lang=js&"
import style0 from "./activity-msg.vue?vue&type=style&index=0&id=bb5b0290&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_eeqz4xtr3chsfpwu4zsrvzqhvy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb5b0290",
  null
  
)

export default component.exports