<template>
  <div class="editor-wrapper">
    <div v-if="onlyRead" v-html="tinymceHtml" class="mce-content-body"/>
    <Editor v-else v-model="tinymceHtml" :init="editorInit"/>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
  import { uploadFile, previewImage } from '@/utils';
  import _ from 'lodash';
  import tinymce from 'tinymce/tinymce';
  import Editor from '@tinymce/tinymce-vue';
  import 'tinymce/themes/silver/theme';
  import 'tinymce/models/dom';
  import 'tinymce/icons/default';

  // plugins
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/wordcount';
  import 'tinymce/plugins/media';

  // 用到的 plugin 需要 import 进来
  const plugins = [
    // 'advlist',
    // 'autoresize',
    // 'code',
    // 'emoticons',
    'image',
    'link',
    // 'nonbreaking',
    // 'quickbars',
    // 'table',
    // 'visualchars',
    // 'anchor',
    // 'autosave',
    // 'codesample',
    // 'fullscreen',
    // 'importcss',
    'lists',
    // 'pagebreak',
    // 'save',
    // 'template',
    'wordcount',
    // 'autolink',
    // 'charmap',
    // 'directionality',
    // 'help',
    // 'insertdatetime',
    // 'media',
    // 'preview',
    // 'searchreplace',
    // 'visualblocks',
  ];

  const toolbar = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'fontsize',
    '|',
    'forecolor',
    'backcolor',
    // '|',
    // 'alignleft',
    // 'aligncenter',
    // 'alignright',
    // 'alignjustify',
    // '|',
    // 'bullist',
    // 'numlist',
    // '|',
    // 'outdent',
    // 'indent',
    '|',
    'blockquote',
    // '|',
    // 'undo',
    // 'redo',
    'link',
    // 'unlink',
    'image',
    // 'media',
    // 'code',
    // '|',
    // 'removeformat',
  ];

  @Component({
    components: {
      Editor,
    }
  })
  export default class MarkdownEditor extends Vue {
    @Model('input', { type: String, default: '' }) value
    @Prop(Boolean) onlyRead
    @Prop(Boolean) resize

    show = true
    tinymceHtml = ''

    get editorInit() {
      return {
        menubar: '',
        skin_url: '/skins/ui/oxide',
        content_css: '/skins/content/default/content.css',
        height: '100%',
        resize: this.resize,
        plugins: plugins.join(' '),
        toolbar: toolbar.join(' '),
        branding: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        images_upload_handler: this.uploadImage,
      };
    }

    @Watch('value', { immediate: true })
    handleValueChange(val, old) {
      if (val !== old) {
        this.tinymceHtml = val;
      }
    }

    @Watch('tinymceHtml')
    handleTinymceHtmlChange(val, old) {
      if (val !== old) {
        this.$emit('input', val);
      }
    }

    async mounted() {
      // 如果在这里传入上面的 init 对象，并不能生效，但什么参数都不传也会报错，所以这里传入一个空对象。
      tinymce.init({});
    }

    async uploadImage(blobInfo, progress) {
      const res = await uploadFile(
        new File(
          [blobInfo.blob()],
          blobInfo.filename()
        ),
        { onProgress: progress }
      );
      return res.url;
    }

    handleClick(e) {
      const src = _.get(e.target, 'src');
      if (_.get(e.target, 'nodeName') === 'IMG' && src) {
        previewImage([src], 0);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .editor-wrapper {
    line-height: 1.4;

    ::v-deep {
      // 需要样式隔离，以免影响全局样式
      @import '/public/skins/content/default/content';

      .mce-content-body {
        overflow: auto;
        font-size: 18PX;

        &:not([dir=rtl]) blockquote {
          border-left-width: 2PX;
        }

        ul {
          margin-block: 1em;
          padding-inline-start: 40px;
        }

        p {
          margin-block: 1em;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .tox-tinymce {
        min-height: 300px;
        border-color: var(--border-primary-blue);
        border-radius: 0;
      }

      a {
        text-decoration: underline;
        color: var(--font-primary-purple);
      }
    }
  }
</style>
