<template>
  <div class="msg">
    <div class="ract">
      <span>{{ msgText }}</span>
    </div>
    <div class="left-bubble"></div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class MsgIcon extends Vue {
    @Prop({ type: [String, Number], required: true }) msgText

  }
</script>

<style lang="scss" scoped>
  .msg {
    --msg-color: var(--font-seventh-purple);
    position: relative;
    display: inline-block;

    .ract {
      position: relative;
      padding: 5px 13px;
      border-radius: 5px;
      overflow: hidden;
      font-weight: bold;
      font-size: 14px;
      color: var(--msg-color);

      &::after,
      &::before {
        position: absolute;
        display: block;
        content: '';
      }

      &::before {
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 3PX solid var(--msg-color);
        border-bottom: none;
      }

      &::after {
        bottom: 0;
        left: 9px;
        width: 100%;
        height: 3PX;
        background: var(--msg-color);
      }
    }

    .left-bubble {
      position: relative;
      width: 3PX;
      height: 10px;
      margin-top: -3PX;
      background: var(--msg-color);

      &::after {
        position: absolute;
        bottom: 0;
        display: block;
        width: 3PX;
        height: 11px;
        background: var(--msg-color);
        transform: rotate(45deg);
        transform-origin: 100% 100%;
        content: '';
      }
    }
  }
</style>
