<template>
  <div class="started-footer">
    <span>Copyright © {{ year }} AtomicMind. All rights reserved.</span>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class StartFooter extends Vue {
    year = new Date().getFullYear()
  }
</script>

<style lang="scss" scoped>
  .started-footer {
    width: 100%;
    padding: 12px 0;
    font-family: 'Neue Montreal Regular';
    text-align: center;
    color: var(--font-primary-white);
    background-color: #270d46;

    @include paddingBottomSafeArea(12px);

    span {
      opacity: 0.5;
    }
  }
</style>
