import { render, staticRenderFns } from "./bind-google-account.vue?vue&type=template&id=247149b6&scoped=true&"
import script from "./bind-google-account.vue?vue&type=script&lang=js&"
export * from "./bind-google-account.vue?vue&type=script&lang=js&"
import style0 from "./bind-google-account.vue?vue&type=style&index=0&id=247149b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.7_eeqz4xtr3chsfpwu4zsrvzqhvy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247149b6",
  null
  
)

export default component.exports