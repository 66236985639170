export class Mask {
  dom = null;
  isAppend = false;

  show(options = {}) {
    if (!this.dom) {
      this.dom = document.createElement('div');
    }
    if (!this.isAppend) {
      document.body.append(this.dom);
    }
    this.isAppend = true;
    Object.assign(this.dom.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 9999,
      background: 'transparent',
      cursor: 'wait',
      display: 'block',
    }, options);
  }

  hidden() {
    Object.assign(this.dom.style, {
      display: 'none',
    });
  }

  remove() {
    this.isAppend = false;
    this.dom.remove();
  }
}

export const mask = new Mask();
window.mask = mask;
