import _ from 'lodash';
import { Vue } from 'vue-property-decorator';
import {
  Loading,
  Message,
  MessageBox,
  Notification,
  Progress,
  DatePicker,
  TimePicker,
  TimeSelect,
  Dialog,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Upload,
  Button,
  Popconfirm,
  Pagination,
  Autocomplete,
  Row, Col,
  Avatar,
  Radio,
  RadioGroup,
  Collapse,
  CollapseItem,
  Tooltip,
  Popover,
  CheckboxGroup,
  CheckboxButton,
  Checkbox,
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

_.merge(lang.el.datepicker.months, { sep: 'Sept' });

// 设置语言
locale.use(lang);

Vue.use(Progress)
  .use(DatePicker)
  .use(TimePicker)
  .use(TimeSelect)
  .use(Dialog)
  .use(Tabs)
  .use(TabPane)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Upload)
  .use(Button)
  .use(Popconfirm)
  .use(Pagination)
  .use(Autocomplete)
  .use(Row)
  .use(Col)
  .use(Avatar)
  .use(Radio)
  .use(RadioGroup)
  .use(Collapse)
  .use(CollapseItem)
  .use(Tooltip)
  .use(Popover)
  .use(CheckboxGroup)
  .use(CheckboxButton)
  .use(Checkbox)
  .use(Loading);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = function(msg) {
  const msgObj = { message: msg.message ? msg.message : msg, duration: 5000 };
  const msgType = msg.type || '';

  switch (msgType) {
    case 'success':
      return Message.success(msgObj);
    case 'warning':
      return Message.warning(msgObj);
    case 'error':
      return Message.error(msgObj);
    default:
      return Message(msgObj);
  }
};
Vue.prototype.$message.success = function(message) {
  return Message.success({ message, duration: 5000 });
};
Vue.prototype.$message.warning = function(message) {
  return Message.warning({ message, duration: 5000 });
};
Vue.prototype.$message.error = function(message) {
  return Message.error({ message, duration: 5000 });
};
