<template>
  <div>
    <bind-google-account :success="success"/>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import BindGoogleAccount from './components/bind-google-account.vue';

  @Component({
    components: {
      'bind-google-account': BindGoogleAccount
    }
  })
  export default class Redirect extends Vue {
    success = false

    @Vue.autoLoadingProgress
    async created() {
      const { code, success } = this.$route.query;
      if (success) {
        await this.$authStore.fetchData();
        const { show_callback_google_page } = this.$authStore.user.extended_info;
        if (show_callback_google_page) {
          this.success = true;
          await this.$authStore.updateIsShow('show_callback_google_page', false);
        } else {
          this.$router.replace({ name: 'home' });
        }
      } else if (code) {
        try {
          await this.$authStore.loginWithGoogle(code);
          await this.$authStore.fetchData();
          // 防止手动刷新重新请求
          this.$authStore.loginAfterNav(() => {
            this.$router.replace({ query: { success: 1 } });
          });
        } catch (e) {
          this.$router.replace({ query: { ...this.$route.query, code: '' } });
          throw new Error(e);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
