import { REDIRECT_URL_SESSION_KEY } from '@/constants';

import router from '../router';

export function loginSuccessRedirect() {
  const redirectUri = decodeURIComponent(router.currentRoute.query.redirect_uri || sessionStorage.getItem(REDIRECT_URL_SESSION_KEY));
  try {
    const { route } = router.resolve(redirectUri);
    router.replace(route.name === '404' ? { name: 'home' } : route.fullPath);
  } catch (e) {
    router.replace({ name: 'home' });
  }
  sessionStorage.removeItem(REDIRECT_URL_SESSION_KEY);
}
