<template>
  <div class="files">
    <div class="page-title">{{ keyword ? `Search "${keyword}" results` : 'Files' }}</div>

    <common-filter-view type="File" :isAdvisor="$authStore.isAdvisor" />

    <common-prod-button border @click.native="showFileForm = true">
      UPLOAD A FILE
    </common-prod-button>

    <div v-if="filesStore.isEmpty" class="empty-wrap">You have no files</div>
    <common-prod-file-lists class="list-page" :files="filesStore.data" @delete-item="handleDeleteFile" showTag/>
    <common-prod-pagination :store="filesStore"/>

    <common-popup v-model="showFileForm" title="Upload a file">
      <div class="file-form">
        <common-prod-select
          v-if="$authStore.isAdvisor"
          v-model="studentId"
          :options="studentsStore.data"
          inputTitle="Add a student"
          placeholder="Select student"
        />
        <common-prod-select
          v-model="subjectArray"
          :options="subjectsStore.data"
          inputTitle="Add a subject"
          placeholder="None selected"
          multiple
        />
        <div class="file-input-wrapper">
          <common-prod-upload-input v-model="files"/>
        </div>
        <common-prod-button
          class="submit-comment-btn"
          :disabled="disabled"
          @click.native="handleUpload"
        ><span class="uppercase">UPLOAD</span></common-prod-button>
      </div>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { subjectsStore, studentsStore, tasksStore } from '@/stores';
  import _ from 'lodash';

  @Component
  export default class Files extends Vue {
    showFileForm = false
    files = []
    studentId = null
    subjectArray = []

    subjectsStore = subjectsStore
    studentsStore = studentsStore
    tasksStore = tasksStore
    filesStore = this.$Collection.create({
      fetch: params => {
        return this.$request.get('documents', { params });
      }
    })

    get keyword() {
      return this.$route.query.keyword;
    }

    get disabled() {
      return !((this.studentId || this.$authStore.currentStudentId) && this.files.length !== 0);
    }

    get filterGroups() {
      const subjects = _.map(this.subjectsStore.data, item => {
        return {
          name: item.name,
          value: item.id
        };
      });
      const tasks = _.map(this.tasksStore.data, item => {
        return {
          name: item.name,
          value: item.id
        };
      });
      return [
        {
          key: 'document_taggings_taggable_of_Subject_type_id',
          title: 'By subject',
          allBtn: true,
          data: subjects,
        },
        {
          key: 'document_taggings_taggable_of_Task_type_id_in',
          title: 'By assignment',
          allBtn: true,
          data: tasks,
        }
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      this.filesStore.setQueryToParams([
        'keyword',
        'document_taggings_taggable_of_Subject_type_id_in',
        'student_id_in',
        'document_taggings_taggable_of_Task_type_id_in'
      ]);

      await Promise.all([
        this.filesStore.fetchData(),
        this.subjectsStore.fetchData({ page: 0 }),
        this.$authStore.isAdvisor && this.studentsStore.fetchData({ page: 0 }), // 学生不调取studentsStore
        this.tasksStore.fetchData({ page: 0 })
      ]);

    }

    @Vue.autoLoading
    async handleUpload() {
      const attachments = [];

      this.files.map(item => {
        if (!item._destroy) {
          attachments.push(item.attachment);
        }
      });

      const documentTagging = [
        ...this.subjectArray.map(id => ({ taggable_type: 'Subject', taggable_id: id })),
      ];

      const { data } = await this.$request.post('documents', {
        student_id: this.studentId || this.$authStore.currentStudentId,
        attachments,
        document_taggings_attributes: documentTagging
      });

      data.forEach(item => this.filesStore.unshift(item));

      this.showFileForm = false;
      this.files = [];
    }

    // 删除文件
    @Vue.autoLoading
    async handleDeleteFile(file) {
      await this.$request.delete(`documents/${file.id}`);
      if (this.filesStore.meta.total < this.filesStore.meta.per_page) {
        this.filesStore.removeItemById(file.id);
        return;
      }
      await this.filesStore.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .file-form {
    > div + div {
      margin-top: 30px;
    }
  }

  div .filter-group-wrapper {
    margin-bottom: 0;
  }

  ::v-deep .el-scrollbar {
    max-height: 180px;
  }

  .empty-wrap {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
  }

  ::v-deep .ato-button {
    display: inline-block;
    margin-top: 48px;
  }

  ::v-deep .el-icon-close {
    font-size: 20px;
    cursor: pointer;
  }

  .file-form ::v-deep .popper-wrap {
    top: 60PX;
  }
</style>
