<template>
  <span class="tag">
    <el-tooltip class="item" effect="dark" :content="tagText" placement="top-start" :open-delay="1000">
      <component
        :is="isRoute ? 'router-link' : 'div'"
        v-bind="isRoute ? { to: routeTo } : {}"
        class="tag-wrap font-regular"
        :class="[active ? 'default' : 'is-disabled', {'is-toggle': toggle}]"
        @click="$emit('change', !active)"
      >
        <span class="text-overflow">{{ tagText }}</span>
        <i v-if="showClose" @click="$emit('delete', tag)" class="el-icon-close close-icon"></i>
      </component>
    </el-tooltip>
  </span>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class Tag extends Vue {
    @Prop({ type: Object, default: () => ({}) }) tag
    @Prop({ type: Boolean, default: true }) active
    @Prop({ type: Boolean, default: true }) showClose
    @Prop({ type: Boolean, default: false }) toggle  // 是否为可切换tag
    @Prop(String) label
    @Prop({ type: Object, default: () => ({}) }) routeTo

    get tagText() {
      if (this.label) { return this.label; }

      return {
        SubjectTagging: this.$get(this.tag, 'subject.name'),
        DocumentTagging: this.$get(this.tag, 'taggable_name'),
      }[this.$get(this.tag, 'cn')] || this.$get(this.tag, 'name');
    }

    get isRoute() {
      return !_.isEmpty(this.routeTo);
    }
  }
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-block;
  }

  .tag-wrap {
    display: flex;
    align-items: center;
    max-width: 150px;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 16px;
    color: var(--font-primary-white);

    @include media-xs-only {
      padding: 8px 15px;
      font-size: 14px;
    }

    &.default {
      background: var(--radial-gradient-blue);
    }

    &.is-disabled {
      border-radius: 20px;
      background: var(--bg-secondary-gray);
    }

    &.is-toggle {
      cursor: pointer;
    }
  }

  .close-icon {
    padding: 3px;
    margin-left: 5px;
    font-size: 8px;
    cursor: pointer;
  }
</style>
