<template>
  <div>
    <div class="repeat-every-wrapper">
      <span class="label">Repeat every</span>
      <el-input class="number-input" size="mini" v-model="form.interval" type="number" :min="1"/>
      <el-select class="every-select" size="mini" v-model="form.freq">
        <el-option
          v-for="item in repeatEveryOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="week-repeat-wrapper" v-show="showWeekSelect">
      <div style="margin-bottom: 0.52466rem;">Repeat on</div>
      <el-checkbox-group v-model="selectWeeks" size="mini">
        <el-checkbox-button v-for="(item, index) in selectWeekOptions" :label="item.value" :key="index">{{ item.label }}</el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div style="margin-bottom: 1.04932rem;" v-show="showMonthSelect">
      <el-select v-model="monthSelect" size="mini" style="width: 15.73977rem;">
        <el-option
          v-for="item in monthSelectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div style="line-height: 1.6;">
      <div>Ends</div>
      <el-radio-group v-model="endsForm.type" class="radio-group">
        <div class="item"><el-radio :label="false" class="radio-item">Never</el-radio></div>
        <div class="item">
          <el-radio label="until" class="radio-item">On</el-radio>
          <common-prod-picker-wrapper>
            <span>{{ dateText }}</span>
            <template #picker>
              <el-date-picker
                size="mini"
                v-model="endsForm.until"
                type="date"
                :editable="false"
                :clearable="false"
                value-format="yyyy-MM-dd"
              />
            </template>
          </common-prod-picker-wrapper>
        </div>
        <div class="item">
          <el-radio label="count" class="radio-item">After</el-radio>
          <el-input v-model="endsForm.count" size="mini" class="number-input" type="number" :min="1"/>
          <span>occurrences</span>
        </div>
      </el-radio-group>
    </div>
    <div class="btns-wrapper">
      <el-button plain size="mini" type="info" @click="$emit('cancel')">Cancel</el-button>
      <el-button plain size="mini" type="primary" @click="handleSubmit">Done</el-button>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { RRule } from 'rrule';
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import { getMonthSelectOptions } from '@/utils';

  @Component
  export default class RepeatCustomForm extends Vue {
    repeatEveryOptions = [
      { label: 'days', value: RRule.DAILY },
      { label: 'weeks', value: RRule.WEEKLY },
      { label: 'months', value: RRule.MONTHLY },
      { label: 'years', value: RRule.YEARLY },
    ]

    form = {
      interval: 1,
      freq: RRule.DAILY,
    }

    selectWeekOptions = [
      { label: 'S', value: RRule.SU },
      { label: 'M', value: RRule.MO },
      { label: 'T', value: RRule.TU },
      { label: 'W', value: RRule.WE },
      { label: 'T', value: RRule.TH },
      { label: 'F', value: RRule.FR },
      { label: 'S', value: RRule.SA },
    ]

    selectWeeks = [ RRule.MO ]

    endsForm = {
      type: false,
      count: 1, // count, until
      until: dayjs().format('YYYY-MM-DD')
    }

    monthSelect = '';

    get showWeekSelect() {
      return this.form.freq === RRule.WEEKLY;
    }

    get showMonthSelect() {
      return this.form.freq === RRule.MONTHLY;
    }

    get dateText() {
      return dayjs(this.endsForm.until).format('MMM DD,  YYYY');
    }

    get monthSelectOptions() {
      return getMonthSelectOptions();
    }

    @Watch('form.freq', { immediate: true })
    formFreqChange(val) {
      if (!this.monthSelect && val === RRule.MONTHLY) {
        this.monthSelect = _.get(this.monthSelectOptions, '[0].value');
      }
    }
    @Watch('selectWeeks', { immediate: true })
    selectWeeksChange(val) {
      if (!_.get(val, 'length')) {
        this.selectWeeks = [ RRule.MO ];
      }
    }

    handleSubmit() {
      const newForm = { ...this.form };
      switch (this.form.freq) {
        case RRule.WEEKLY:
          newForm.byweekday = [...this.selectWeeks];
          break;
        case RRule.MONTHLY:
          newForm.byweekday = [..._.get(RRule.fromString(this.monthSelect), 'origOptions.byweekday', [])];
          break;
      }

      switch (this.endsForm.type) {
        case 'until':
          newForm.until = new Date(this.endsForm.until);
          break;
        case 'count':
          newForm.count = this.endsForm.count;
          break;
      }
      const rule = new RRule(newForm);
      this.$emit('confirm', {
        label: _.upperFirst(rule.toText()),
        value: rule.toString(),
      });
    }
  }
</script>

<style lang="scss" scoped>
  .number-input {
    width: 80px;
    margin-right: 10px;
  }

  .repeat-every-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .label {
      width: 150px;
    }

    .every-select {
      width: 120px;
    }
  }

  .week-repeat-wrapper {
    margin-bottom: 20px;
  }

  .radio-group {
    font-size: inherit;

    .item {
      display: flex;
      align-items: center;
      height: 50px;

      .radio-item {
        width: 150px;
        margin-right: 0;
      }
    }
  }

  .btns-wrapper {
    text-align: right;
  }
</style>
