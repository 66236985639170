<template>
  <div class="home-page" :style="{'--screen-height': $data.screenHeight}">
    <component-sidebar :currentGuideStep="currentStep" :showGuide="showGuide" />
    <div class="contain">
      <component-header/>
      <div class="page-main content">
        <div class="bs-flex title-wrapper">
          <div class="">
            <img src="@/assets/icon-good.png" class="icon hidden-xs-only">
          </div>
          <div class="bs-flex-1">
            <div class="title-and-session-wrap">
              <div class="page-title">{{ pageTitle }}</div>
              <div
                v-if="showGuide || $authStore.user.session_room_link"
                class="session-btn bs-flex bs-item-center"
                :class="{ 'show-guide': showGuide && currentStep === 0 }"
                @click="handleOpenLink($authStore.user.session_room_link)"
              >
                <img src="@/assets/video-icon-white.png" alt="">
                <span>Session room</span>
              </div>
            </div>
            <div class="sub-title" v-if="$get(authStore, 'user.role') === 'student'">
              <template v-if="$get(authStore, 'user.head_advisor.name')">
                <span>Your head advisor is</span>
                <span class="primary-red"> {{ $get(authStore, 'user.head_advisor.name') }}</span>
                <span>. </span>
              </template>
              <template v-if="$get(this.$authStore.user, 'extended_info.show_quick_tutorial')">
                <span>Since you're new, </span>
                <span @click="handleShowVideo" class="link">watch a quick tutorial!</span>
              </template>
              <!-- TODO: 已填写相关资料判断 -->
              <!-- <template>
                <span>Need some </span>
                <span @click="handleShowVideo" class="link">motivation</span>
                <span> today?</span>
              </template> -->
            </div>
          </div>
        </div>

        <div class="categories-wrapper">
          <div class="title">
            <span>{{ tasksTip }}</span>
            <template v-if="!authStore.isAdvisor">
              <router-link :to="{ name: 'tasks' }" class="link no-underline"> {{ $get(authStore, 'user.recent_week_active_tasks_count', 0) | pluralize('assignment', true, 'no') }} due</router-link>
              <span> this week</span>
            </template>
          </div>

          <div v-if="!authStore.isAdvisor" class="cards-wrapper student">
            <common-prod-card :isNothing="$get(scheduleStore, 'data.length', 0) === 0">
              <template v-slot:header>
                <div class="schedule-header bs-flex bs-item-center bs-content-between">
                  <div class="card-title">Schedule</div>
                  <div class="time-toggle-box bs-flex bs-item-center">
                    <i @click="handleToggleTime('prev')" class="icon el-icon-arrow-left"></i>
                    <el-date-picker
                      v-model="currentShowDate"
                      class="current-time"
                      :editable="false"
                      :clearable="false"
                      @change="handleToggleTime"
                    />
                    <i @click="handleToggleTime('next')"  class="icon el-icon-arrow-right"></i>
                  </div>
                </div>
              </template>
              <div class="card-content">
                <div v-for="event in $get(scheduleStore, 'data', []).slice(0, 3)" :key="event.id" class="event-line">
                  <div class="time-shaft text-overflow">
                    <span class="bs-no-shirk">{{ getEventTime(event) }}</span>
                  </div>
                  <div class="event-title text-overflow">{{ event.title }}</div>
                </div>
              </div>
              <router-link
                :to="{ name: 'calendar' }"
                class="all-link bs-self-end "
              >
                See calendar
              </router-link>
            </common-prod-card>

            <common-prod-card title="Assignments" :isNothing="tasksStore.isEmpty" :class="{ 'show-guide': showGuide && currentStep === 5 }">
                <div class="card-content">
                  <div v-for="item in tasksStore.data" :key="item.id" class="card-line">
                    <common-task-status
                      class="task-status"
                      :task="item"
                      @finish="tasksStore.finishTask(item)"
                      :to="{ name: 'task.detail', params: { id: item.id } }"
                      showOverdueStyle
                    />
                    <div class="due-time">
                      <span class="text-overflow">{{ `Due ${$options.filters.formatTime(item.due_at, 'ddd, MMM Do')}` }}</span>
                    </div>
                  </div>
                </div>
              <router-link
                v-if="tasksStore.meta.total > 3"
                :to="{ name: 'tasks' }"
                class="all-link"
              >
                All tasks
              </router-link>
            </common-prod-card>

            <common-prod-card title="Reminders" :isNothing="reminderStore.isEmpty">
              <div class="card-content">
                <div v-for="item in reminderStore.data" :key="item.id" class="card-line">
                  <common-task-status
                    class="task-status"
                    :task="item"
                    @finish="reminderStore.finishTask(item)"
                    :to="{ name: 'task.detail', params: { id: item.id } }"
                  />
                  <div class="due-time">
                    <span class="text-overflow">{{ reminderStore.getRepeatLabel(item) }}</span>
                  </div>
                </div>
              </div>
              <router-link
                v-if="reminderStore.meta.total > 3"
                :to="{ name: 'reminders' }"
                class="all-link"
              >
                All Reminders
              </router-link>
            </common-prod-card>

            <common-prod-card title="Files" :isNothing="filesStore.isEmpty">
              <div class="card-content">
                <div v-for="item in filesStore.data" :key="item.id" class="card-line">
                  <FileItem :file="item"/>
                </div>
              </div>
              <router-link
                v-if="filesStore.meta.total > 3"
                :to="{ name: 'files' }"
                class="all-link"
              >
                All files
              </router-link>
            </common-prod-card>
          </div>

          <div v-else class="cards-wrapper advisor">
            <common-prod-card :isNothing="$get(scheduleStore, 'data.length', 0) === 0">
              <template v-slot:header>
                <div class="schedule-header bs-flex bs-item-center bs-content-between">
                  <div class="card-title">Schedule</div>
                  <div class="time-toggle-box bs-flex bs-item-center">
                    <i @click="handleToggleTime('prev')" class="icon el-icon-arrow-left"></i>
                    <el-date-picker
                      v-model="currentShowDate"
                      :editable="false"
                      :clearable="false"
                      class="current-time"
                      @change="handleToggleTime"
                    />
                    <i @click="handleToggleTime('next')"  class="icon el-icon-arrow-right"></i>
                  </div>
                </div>
              </template>
              <div class="card-content">
                <div v-for="event in $get(scheduleStore, 'data', []).slice(0, 3)" :key="event.id" class="event-line">
                  <div class="time-shaft bs-no-shirk text-overflow">
                    <span>{{ getEventTime(event) }}</span>
                  </div>
                  <div class="event-title text-overflow">{{ event.title }}</div>
                </div>
              </div>
              <router-link
                :to="{ name: 'calendar' }"
                class="all-link bs-self-end"
              >
                See calendar
              </router-link>
            </common-prod-card>

            <common-prod-card title="Students" class="students-card" :isNothing="studentsStore.isEmpty">
              <div class="card-content">
                <div v-for="student in studentsStore.data.slice(0, 6)" :key="student.id" class="card-line">
                  <div class="student-info-wrap bs-flex bs-item-center">
                    <img class="avatar" :src="$get(student, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
                    <router-link
                      :to="{ name: 'students', query: { keyword: student.name, id_eq: student.id } }"
                      class="name"
                    >
                      {{ student.name }}
                    </router-link>
                  </div>
                  <div class="operate-buttons bs-flex bs-item-center">
                    <div v-if="student.session_room_link"
                      @click.stop="studentsStore.handleOpenLink(student.session_room_link)"
                      class="btn session-room-btn"
                    >
                      <img class="video-icon" src="@/assets/video-icon-white.png" alt="">
                      <span class="hidden-sm-and-down">Session Room</span>
                    </div>
                    <a v-if="student.email" class="btn" :href="`mailto:${student.email}`">Email</a>
                    <div v-if="$get(student, 'contact.whats_app_number')" @click="studentsStore.handleWhatsApp(student)" class="btn">WhatsApp</div>
                  </div>
                </div>
              </div>
              <router-link v-if="studentsStore.meta.total > 6"
                :to="{ name: 'students' }"
                class="all-link bs-self-end "
              >
                All students
              </router-link>
            </common-prod-card>

            <common-prod-card title="Files" :isNothing="filesStore.isEmpty">
              <div class="card-content">
                <div v-for="item in filesStore.data" :key="item.id" class="card-line">
                  <FileItem :file="item"/>
                </div>
              </div>
              <router-link v-if="filesStore.meta.total > 3"
                :to="{ name: 'files' }"
                class="all-link"
              >
                All files
              </router-link>
            </common-prod-card>
          </div>
        </div>

        <common-prod-card title="RECENT ACTIVITY">
          <common-prod-activity-msg welcome v-if="showWelcome"/>
          <div v-if="activitiesStore.isEmpty" class="empty-tip">You have no recent activities at present.</div>
          <common-prod-activity-msg
            v-for="item in activitiesStore.data"
            :key="item.id"
            :activity="item"
          />
          <span>
            <common-prod-button v-if="!activitiesStore.isComplete" @click.native="handleLoadMore(activitiesStore)">LOAD MORE</common-prod-button>
          </span>
        </common-prod-card>

      </div>
    </div>
    <guide-tips v-model="showGuide" @step-change="currentStep = $event" @guide-btn="handleClickGuideBtn" />
    <guide-vedio v-model="showVideo" />
    <component-help v-if="!$authStore.isAdvisor" :guideHighlight="showGuide && currentStep === 4" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import GuideTips from './components/guide';
  import GuideVedio from './components/guide-vedio';
  import { authStore, TasksStore, studentsStore } from '@/stores';
  import FileItem from '@/components/prod/file-item.vue';
  import Header from '@/components/common-layout/header';
  import Sidebar from '@/components/common-layout/sidebar';
  import Help from '@/components/common-layout/help.vue';
  import dayjs from 'dayjs';
  import { displayDate } from '@/utils/display-date';

  const advancedFormat = require('dayjs/plugin/advancedFormat');
  dayjs.extend(advancedFormat);


  @Component({
    components: {
      'guide-tips': GuideTips,
      'guide-vedio': GuideVedio,
      'component-sidebar': Sidebar,
      'component-help': Help,
      'component-header': Header,
      FileItem,
    }
  })
  export default class Dashboard extends Vue {
    currentStep = null
    showGuide = false
    showVideo = false
    currentShowDate = dayjs()

    authStore = authStore
    studentsStore = studentsStore
    tasksStore = TasksStore.create({
      params: { per_page: 3, no_repeat: true }
    })
    reminderStore = TasksStore.create({
      params: { per_page: 3, reminder: true, completed_at_not_null: false }
    })
    filesStore = this.$Collection.create({
      params: { per_page: 3 },
      fetch: params => {
        return this.$request.get('documents', { params });
      }
    })
    activitiesStore = this.$Collection.create({
      params: { per_page: 5 },
      fetch: params => this.$request.get('activities', { params })
    })

    scheduleStore = this.$Collection.create({
      params: {
        per_page: 3,
        event_date: dayjs().format('YYYY-MM-DD')
      },
      fetch: params => {
        return this.$request.get('schedule/events', { params });
      }
    });

    get pageTitle() {
      let dateText = '';
      const h = dayjs().tz(this.$authStore.timeZone).get('h');
      const filter = this.$options.filters;

      if (h >= 0 && h < 12) {
        dateText = 'morning';
      } else if (h >= 12 && h < 18) {
        dateText = 'afternoon';
      } else if (h >= 18 && h < 24) {
        dateText = 'evening';
      }
      return `Good ${dateText}, ${filter.firstUpperCase(this.$get(authStore, 'user.screen_name') || this.$get(authStore, 'user.name'))}.`;
    }

    get tasksTip() {
      if (authStore.isAdvisor) {
        return 'This is your dashboard.';
      } else {
        return 'This is your dashboard. You have';
      }
    }

    get showWelcome() {
      if (this.activitiesStore.isEmpty) {
        return authStore.isAdvisor ? this.$get(authStore, 'user.students.length') : true;
      } else {
        return false;
      }
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();

      await Promise.all([
        this.scheduleStore.fetchData(),
        !authStore.isAdvisor && this.tasksStore.fetchData(),
        !authStore.isAdvisor && this.reminderStore.fetchData(),
        this.filesStore.fetchData(),
        authStore.isAdvisor && this.studentsStore.fetchData({ per_page: 6 }),
        this.activitiesStore.fetchData(),
      ]);

      // 学生注册后，如果超过两星期，则不再显示视频指引
      if (this.$get(this.$authStore.user, 'extended_info.show_quick_tutorial')) {
        if (dayjs().isAfter(dayjs(authStore.user.created_at).add(2, 'week'))) {
          this.$authStore.updateIsShow('show_quick_tutorial', false);
        }
      }

      // 只有学生，且没有点击不再提示按钮才会显示导航指引
      this.showGuide = !this.$authStore.isAdvisor && this.$get(this.$authStore.user, 'extended_info.show_tool_guide');
    }

    mounted() {
      this.$initScreenHeight();
    }

    beforeDestroy() {
      this.$destroyScreenHeight();
    }

    @Vue.autoLoading
    handleLoadMore(store) {
      return store.fetchMoreData();
    }

    async handleShowVideo() {
      this.showVideo = true;
    }

    handleClickGuideBtn(val) {
      switch (val) {
        case 'toWork':
          this.$authStore.updateIsShow('show_quick_tutorial', false);
          break;
      }
    }

    @Vue.autoLoading
    async handleToggleTime(type) {
      switch (type) {
        case 'prev':
          this.currentShowDate = dayjs(this.currentShowDate).subtract(1, 'day');
          break;
        case 'next':
          this.currentShowDate = dayjs(this.currentShowDate).add(1, 'day');
          break;
      }

      await this.scheduleStore.fetchData({
        event_date: dayjs(this.currentShowDate).format('YYYY-MM-DD')
      });
    }

    getEventTime(event) {
      const { start_time, end_time, end_date, start_date, is_all_day } = event;
      const dateShow = displayDate(start_date, end_date, is_all_day);
      const startDate = !dayjs(this.currentShowDate).isSame(dayjs(), 'day') ? dayjs(start_date).format('MMM DD,  YYYY') : '';

      if (is_all_day) {
        return 'All day';
      }

      if (dateShow.showTime) {
        const startTime = dayjs(start_time, 'HH:mm').format('hh:mma');
        const endTime = dayjs(end_time, 'HH:mm').format('hh:mma');
        if (dateShow.showEndDate) {
          return `${startDate} ${startTime} - ${endTime} ${dayjs(end_date).format('MMM DD,  YYYY')}`;
        }
        return `${startDate} ${startTime} - ${endTime}`;
      } else {
        return `${startDate} - ${dayjs(end_date).format('MMM DD,  YYYY')}`;
      }
    }

    handleOpenLink(link) {
      window.open(link, '_blank');
    }
  }
</script>

<style lang="scss" scoped>
  .home-page {
    display: flex;
    box-sizing: border-box;
    height: 100vh;
    background-color: var(--bg-secondary-white);

    @include media-xs-only {
      flex-direction: column-reverse;
      height: var(--screen-height);
    }

    .contain {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0;
      overflow: hidden;
    }

    .page-main {
      position: relative;
      flex: 1;
      box-sizing: border-box;
      padding: 50px 40px;
      overflow: auto;

      @include media-xs-only {
        padding: 40px 16px;
      }
    }
  }

  .title-wrapper {
    .icon {
      width: 54px;
      height: 54px;
      margin-top: 5px;
      margin-right: 30px;
    }

    .title-and-session-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-xs-only {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .page-title {
      margin-bottom: 0;
    }

    .session-btn {
      height: 55px;
      padding: 0 21px;
      border: 1px solid #4e4e4e6b;
      font-family: 'Radwave-Regular';
      color: #fff;
      background: var(--radial-gradient-blue);
      cursor: pointer;

      @include media-xs-only {
        margin-top: 16px;
      }

      &.show-guide {
        position: relative;
        z-index: 1001;
      }

      img {
        width: 38px;
        height: 23px;
        margin-right: 25px;
      }
    }

    .sub-title {
      padding-top: 16px;
      font-size: 36px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        font-size: 28px;
      }

      .primary-red {
        color: var(--font-primary-red);
      }

      .link {
        text-decoration: underline;
        color: var(--font-primary-purple);
        cursor: pointer;
      }
    }
  }

  .categories-wrapper {
    margin: 56px 0 16px;

    @include media-xs-only {
      margin-top: 24px;
    }

    .title {
      font-size: 24px;
      line-height: 34px;

      .link {
        text-decoration: underline;
        color: var(--font-primary-purple);

        &.no-underline {
          text-decoration: none;
        }
      }
    }

    .cards-wrapper {
      margin-top: 40px;

      &.advisor {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 12px));
        grid-gap: 24px;

        @include media-md-and-down {
          display: block;

          ::v-deep .card:not(:last-child) {
            margin-bottom: 24px;
          }
        }

        .students-card {
          grid-column-start: 2;
          grid-row-start: 1;
          grid-row-end: 3;
          height: auto;

          .student-info-wrap {
            .avatar {
              width: 50px;
              height: 50px;
              border-radius: 50%;

              @include media-xs-only {
                width: 40px;
                height: 40px;
              }
            }

            .name {
              margin-left: 24px;
              font-weight: 600;
              font-size: 24px;
              color: var(--font-secondary-black);

              @include media-xs-only {
                margin-left: 12px;
                font-size: 20px;
              }
            }
          }

          .operate-buttons {
            .session-room-btn {
              .video-icon {
                width: 25px;
                height: 14px;
                margin-right: 8px;

                @include media-md-and-down {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      &.student {
        display: grid;
        grid-template-rows: repeat(2, 250px);
        grid-template-columns: repeat(2, calc(50% - 12px));
        grid-gap: 24px;

        @include media-md-and-down {
          display: block;

          ::v-deep .card:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }

      ::v-deep .card {
        box-sizing: content-box;
        padding: 16px;

        &.show-guide {
          position: relative;
          z-index: 1001;
        }

        .body {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding: 25px 0 20px;
        }

        .card-content {
          flex: 1;
        }
      }

      .schedule-header {
        .card-title {
          font-size: 18px;
          font-family: 'Radwave-Regular';
          color: var(--font-primary-red);
        }

        .time-toggle-box {
          .current-time {
            text-align: center;
          }

          .icon {
            padding: 5px;
            font-size: 18px;
            cursor: pointer;
          }
        }

        ::v-deep .el-date-editor {
          width: 100px;

          .el-input__inner {
            height: 100%;
            padding: 0;
            border: none;
            background: transparent;
          }

          .el-input__prefix {
            display: none;
          }
        }
      }

      .event-line {
        display: flex;
        align-items: center;
        font-size: 24px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        @include media-md-and-down {
          flex-direction: column;
          align-items: flex-start;
        }

        .time-shaft {
          width: 50%;
          margin-right: 10px;
          font-family: Neue Montreal Medium;

          @include media-md-and-down {
            width: 100%;
            font-size: 20px;
          }
        }

        .event-title {
          font-family: Neue Montreal Regular;

          @include media-md-and-down {
            margin-top: 5px;
            font-size: 22px;
          }
        }
      }

      .card-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .task-status {
        width: 50%;
      }

      .due-time {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        color: var(--font-secondary-black);
      }

      .file-item {
        font-size: 24px;
      }

      ::v-deep .text {
        margin-right: 0;

        @include text-overflow();
      }

      .all-link {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        margin-top: 20px;
        text-decoration: underline;
        color: var(--font-forth-purple);
      }
    }
  }

  .empty-tip {
    font-size: 24px;
    text-align: center;
    color: var(--font-forth-blue);
  }

  .btn {
    &:not(:last-child) {
      margin-right: 24px;

      @include media-xs-only {
        margin-right: 12px;
      }
    }
  }

  .card ::v-deep .activity-msg-wrapper {
    margin-bottom: 40px;

    @include media-xs-only {
      margin-bottom: 21px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  div ::v-deep .list-container {
    .checkbox {
      margin-right: 17px;
      border: 2px solid var(--border-primary-white);
    }

    .content .due-time {
      margin-left: 6px;
      font-size: 16px;
      color: var(--font-primary-white);

      @include media-lg-and-down {
        margin-left: 0;
      }
    }
  }
</style>
