<template>
  <div style="line-height: 1.6;">
    <div
      class="bs-flex bs-item-center"
      :class="{
        'show-end-date': show.showEndDate,
        'show-time': show.showTime,
      }"
    >
      <DateView v-model="startDate"/>
      <TimeView v-model="startTime" v-if="show.showTime"/>
      <span :class="{ margin: !show.showTime }">-</span>
      <TimeView v-model="endTime" v-if="show.showTime" :min-time="minTime"/>
      <DateView v-model="endDate" v-if="show.showEndDate" :start-date="this.startDate"/>
    </div>
    <div class="bs-flex bs-item-center">
      <el-checkbox v-model="checkedAllDay">All day</el-checkbox>
      <div class="bs-flex-1">
        <el-select
          v-show="!checkedAllDay"
          class="time-zone-select"
          v-model="timeZone"
          filterable
          size="mini"
        >
        <el-option
            v-for="item in timeZonesStore.data"
            :key="item.name"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import DateView from './date.vue';
  import TimeView from './time.vue';
  import { displayDate } from '@/utils/display-date';
  import { timeZonesStore } from '@/stores';

  @Component({
    components: {
      DateView,
      TimeView,
    }
  })
  export default class DatePicker extends Vue {
    @Model('change', { type: Object, default: () => ({
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().add(1, 'hour').format('YYYY-MM-DD'),
      start_time: dayjs().format('HH:mm'),
      end_time: dayjs().add(1, 'hour').format('HH:mm'),
      start_time_zone: dayjs.tz.guess(),
      end_time_zone: dayjs.tz.guess(),
      is_all_day: false,
    }) }) value

    startDate = null
    startTime = null
    endDate = null
    endTime = null
    timeZone = dayjs.tz.guess()
    timeZonesStore = timeZonesStore

    checkedAllDay = false

    get show() {
      const { start_date, end_date } = this.value;
      return displayDate(start_date, end_date, this.checkedAllDay);
    }

    get minTime() {
      return this.startDate === this.endDate ? this.startTime : null;
    }

    @Watch('value', { immediate: true, deep: true })
    valueChange(val) {
      const startDate = val.start_date;
      let endDate = val.end_date;
      // 防止 All day 时，start_time 和 end_time 为 null
      const startTime = val.start_time || dayjs().format('HH:mm');
      let endTime = val.end_time || dayjs().add(1, 'hour').format('HH:mm');
      let start = dayjs(`${startDate} ${startTime}`);
      let end = dayjs(`${endDate} ${endTime}`);
      if (!end.isAfter(start)) {
        // 防止开始 time 23：59这种，全天事件选不到当天
        end = val.is_all_day ? start.clone() : start.clone().add(1, 'hour');
      }
      endDate = end.format('YYYY-MM-DD');
      endTime = end.format('HH:mm');
      this.startDate = startDate;
      this.startTime = startTime;
      this.endDate = endDate;
      this.endTime = endTime;
      this.checkedAllDay = _.get(val, 'is_all_day');
      this.timeZone = _.get(val, 'start_time_zone');
    }

    @Watch('startDate')
    @Watch('startTime')
    @Watch('endDate')
    @Watch('endTime')
    @Watch('checkedAllDay')
    @Watch('timeZone')
    update(val, old) {
      if (val !== old) {
        this.emitChange();
      }
    }

    created() {
      timeZonesStore.tryFetchData();
    }

    emitChange() {
      const date = {
        start_date: this.startDate,
        start_time: this.startTime,
        start_time_zone: this.timeZone,
        end_date: this.endDate,
        end_time: this.endTime,
        end_time_zone: this.timeZone,
        is_all_day: this.checkedAllDay,
      };
      this.$emit('change', date);
    }
  }
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input {
    .el-input__prefix {
      display: none;
    }

    .el-input__inner {
      height: auto;
      padding: 0 15px;
      border: none;
      line-height: 1.6;
    }
  }
}

// 小屏时间日期全部显示出来的时候，一行显示不下，调小一点字体和间距
.show-end-date.show-time {
  @include media-xs-only {
    ::v-deep {
      font-size: 14px;

      .time-picker {
        width: 70px;

        input {
          font-size: 14px;
        }
      }
    }
  }
}

.margin {
  margin: 0 10px;
}

.time-zone-select {
  width: 270px;
  margin-left: 10px;

  ::v-deep {
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
