<template>
  <div class="edit-note">
    <common-prod-input v-model="note.title" inputTitle="Note name" />
    <common-prod-select
      v-model="note.subject_ids"
      :options="subjectsStore.data"
      placeholder=""
      inputTitle="Subject"
      multiple
    />
    <common-prod-select
      v-if="$authStore.isAdvisor"
      v-model="note.student_ids"
      :options="studentsStore.data"
      placeholder=""
      inputTitle="Student"
      multiple
    />
    <common-prod-input v-model="note.content" type="richtext" inputTitle="Note description" />
    <div v-if="!$authStore.isAdvisor" class="bs-flex bs-item-center">
      <el-checkbox
        v-model="isStudentNotePrivate"
        class="bs-flex bs-flex-flow"
      >
        <span class="label">Make note private</span>
      </el-checkbox>
    </div>

    <common-prod-button @click.native="handleEdit">Save changes</common-prod-button>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { subjectsStore, studentsStore } from '@/stores';
  import _ from 'lodash';

  const defaultNoteForm = {
    title: '',
    subject_ids: [],
    student_ids: [],
    content: '',
    is_public: false
  };

  @Component
  export default class noteForm extends Vue {
    @Prop({ type: Object, default: () => ({}) }) noteForm
    @Prop(Function) submit

    note = {}
    isStudentNotePrivate = false

    subjectsStore = subjectsStore
    studentsStore = studentsStore

    @Watch('noteForm', { immediate: true, deep: true })
    noteFormChange(val) {
      if (val) {
        this.note = _.pick(this.noteForm, Object.keys(defaultNoteForm));
        this.isStudentNotePrivate = !this.note.is_public;
      }
    }

    async handleEdit() {
      await this.submit({ ...this.note, is_public: !this.isStudentNotePrivate });
      this.$message.success('Note edited');
      this.$emit('close');
    }
  }
</script>

<style lang="scss" scoped>
  .edit-note {
    div + div {
      margin-top: 24px;
    }
  }

  ::v-deep .el-checkbox {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;

    .el-checkbox__inner {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      margin-top: 3px;
      border: 2PX solid var(--border-secondary-black);
    }

    .is-checked .el-checkbox__inner {
      border: 2PX solid transparent;
    }

    .is-checked + .el-checkbox__label {
      color: var(--font-secondary-black);
    }

    .label {
      margin-right: 11px;
      color: var(--font-secondary-black);
    }
  }

  ::v-deep .ato-button {
    @include media-xs-only {
      width: 100%;
    }
  }
</style>
