import dayjs from 'dayjs';

/**
 * 获取是否显示时间 和 结束日期
 *
 * @param  {Date | String} start 开始时间
 * @param  {Date | String} end   结束时间
 * @param  {Boolean} isAllDay    是否为全天事件
 *
 * @return {Object}       {
 *   showTime: 是否显示时间
 *   showEndDate: 是否显示结束日期
 * }
 */
export function displayDate(start, end, isAllDay) {
  // 选择了 all day 就不显示 TimeView
  if (isAllDay) {
    return {
      showTime: false,
      showEndDate: true,
    };
  }
  // 如果没选择 all day 并且没有跨天，就显示 TimeView，不显示 endDateView
  if (dayjs(start).format('YYYY-MM-DD') === dayjs(end).format('YYYY-MM-DD')) {
    return {
      showTime: true,
      showEndDate: false,
    };
  }
  // 如果没选择 all day 并且跨天，就都显示
  return {
    showTime: true,
    showEndDate: true,
  };
}
