export function setTitle(title) {
  // 如果没传title，就不设置
  if (!title) { return; }

  document.title = title;
  //Hack IOS设备下标题不会更新的兼容问题
  if (/iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
    document.title = title;
    let iframe = document.createElement('iframe');
    iframe.src = '/favicon.ico';
    iframe.style.display = 'none';
    iframe.onload = function () {
      setTimeout(function () {
        document.body.removeChild(iframe);
      }, 500);
    };
    document.body.appendChild(iframe);
  }
}

export default setTitle;
