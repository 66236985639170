import { Collection } from './helper/collection';
import { request } from '@/utils';
import _ from 'lodash';

export class ScheduleStore extends Collection {
  fetch(params) {
    return request.get('schedule/events', { params });
  }

  async add(body) {
    const { data } = await request.post('schedule/events', { ...body });
    return data;
  }

  async update(body, id) {
    const { data } = await request.put(`schedule/events/${id}`, { ...body });
    _.map([...this.data], ({ events }) => {
      const i = _.findIndex(events, event => String(event.id) === String(id));
      i !== -1 && events.splice(i, 1, data);
    });
  }

  async delete(id) {
    await request.delete(`schedule/events/${id}`);
    _.map([...this.data], ({ events }, index) => {
      const i = _.findIndex(events, event => String(event.id) === String(id));
      i !== -1 && events.splice(i, 1);
      !events.length && this.data.splice(index, 1);
    });
  }
}

export const scheduleStore = ScheduleStore.create();
