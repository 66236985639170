<template>
  <div>
    <common-prod-select
      :value="value"
      :options="options"
      name="label"
      valueKey="value"
      placeholder="None selected"
      inputTitle="Repeat"
      tips="To make this a reminder, set it as a repeating assignment"
      @change="handleChange"
    />
    <common-prod-dialog :visible.sync="showCustom" append-to-body>
      <RepeatCustomForm @cancel="showCustom = false" @confirm="handleCustomConfirm"/>
    </common-prod-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';
  import _ from 'lodash';
  import dayjs from 'dayjs';
  import { RRule } from 'rrule';
  import { getMonthSelectOptions } from '@/utils';
  import RepeatCustomForm from '@/components/prod/repeat-custom-form.vue';

  @Component({
    components: {
      RepeatCustomForm
    }
  })
  export default class RepeatSelect extends Vue {
    @Model('change', { type: String, default: '' }) recurrence

    showCustom = false
    value = ''

    @Watch('recurrence', { immediate: true })
    startRecurrenceChange(val, old) {
      this.value = val;
      if (val && val !== old) {
        if (!this.options.find(({ value }) => val === value)) {
          this.customSelectOptions.push({
            label: _.upperFirst(RRule.fromString(val).toText()),
            value: val,
          });
        }
      }
    }

    get options() {
      const date = dayjs();
      return [
        { label: 'Does not repeat', value: '' },
        { label: 'Daily', value: 'RRULE:INTERVAL=1;FREQ=DAILY' },
        { label: `Weekly on ${date.format('dddd')}`, value: RRule.fromText(`Every Week on ${date.format('dddd')}`).toString() },
        ...getMonthSelectOptions(date),
        {
          label: `Annually on ${date.format('MMM')} ${date.format('D')}`,
          value: RRule.fromText(`Every ${date.format('MMM')} on the ${date.format('Do')}`).toString()
        },
        { label: 'Every weekday (Monday to Friday)', value: RRule.fromText('Every weekday').toString() },
        ...this.customSelectOptions,
        { label: 'Custom...', value: 'custom' },
      ];
    }

    customSelectOptions = []

    handleChange(e) {
      if (e === 'custom') {
        this.showCustom = true;
      } else {
        this.value = e;
        this.$emit('change', e);
      }
    }

    handleCustomConfirm(item) {
      this.value = item.value;
      if (!this.options.find(({ value }) => item.value === value)) {
        this.customSelectOptions.push(item);
      }
      this.$emit('change', this.value);
      this.showCustom = false;
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .input {
  .input-name {
    display: flex;
    flex-direction: column;
  }
}
</style>
