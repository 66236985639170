<template>
  <transition name="el-fade-in">
    <div v-show="show" class="popup-wrapper bs-flex bs-content-center bs-item-center">
      <!-- 点击组件外部隐藏弹框 -->
      <div :class="['mask', {'dark': $isDarkTheme}]" @click="handleClose" v-show="show"></div>
      <div class="popup-container">
        <div class="header">
          <span class="title" v-if="title">{{ title }}</span>
          <img @click="handleClose" class="icon" src="@/assets/close.png" alt="">
        </div>
        <div class="content">
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Component, Vue, Model, Watch, Prop } from 'vue-property-decorator';

  @Component
  export default class EditNamePopup extends Vue {
    @Model('input', Boolean) show;
    @Prop(String) title;

    @Watch('show', { immediate: true })
    valueChange(val) {
      document.body.classList[val ? 'add' : 'remove']('disabled-scrolling');
    }

    handleClose() {
      this.$emit('input', false);
      this.$emit('close');
    }
  }
</script>

<style lang="scss" scoped>
  .popup-wrapper {
    &, .mask {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .mask {
      background: var(--bg-primary-black);
      opacity: 0.8;

      &.dark {
        background: #222;
        opacity: 0.9;
      }
    }
  }

  .popup-container {
    position: relative;
    z-index: 1001;
    width: 900px;

    @include media-xs-only {
      width: 90%;
      overflow-x: hidden;
    }

    .header {
      font-weight: 500;
      font-size: 64px;
      color: var(--font-secondary-gray);

      @include media-xs-only {
        margin-top: 20px;
      }

      .title {
        margin-left: 40px;

        @include media-xs-only {
          font-size: 50px;
        }
      }

      .icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 13px;
        height: 13px;
        cursor: pointer;
      }
    }

    .content {
      box-sizing: border-box;
      max-height: 80vh;
      padding: 40px 40px 50px;
      overflow: auto;
      background: var(--bg-primary-white);

      @include media-xs-only {
        max-height: 65vh;
        padding: 20px;
      }
    }
  }
</style>
