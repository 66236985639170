<template>
  <div class="info-line">
    <img
      v-if="showAvatar"
      :src="$get(data, 'avatar.url') || require('@/assets/default-avatar.png')"
      class="avatar"
      alt=""
    >
    <div class="name bs-flex-1 bs-flex bs-item-center">
      <span class="text-overflow">
        {{ $get(data, 'name') }}
      </span>
      <span class="email hidden-xs-only">
        ({{ $get(data, 'email') }})
      </span>
    </div>
    <a
      v-if="$get(data, 'email') && !isCurrentAccount"
      :href="`mailto:${$get(data, 'email')}`"
      class="btn"
    >
      Email
    </a>
    <div
      v-if="$get(data, 'whats_app_url') && !isCurrentAccount"
      @click="handleOpenLink($get(data, 'whats_app_url'))"
      class="btn"
    >
      WhatsApp
    </div>
    <div
      v-if="$get(data, 'session_room_link')"
      @click="handleOpenLink($get(data, 'session_room_link'))"
      class="btn"
    >
      session_room_link
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class InfoLine extends Vue {
    @Prop({ type: Object, default: () => ({}) }) data
    @Prop({ type: Boolean, default: true }) showAvatar

    // 判断数据是否是当前登录账号
    get isCurrentAccount() {
      return this.$get(this.data, 'id') === this.$get(this.$authStore, 'user.id');
    }

    handleOpenLink(link) {
      window.open(link, '_blank');
    }
  }
</script>

<style lang="scss" scoped>
  .info-line {
    display: flex;
    align-items: center;
    width: 100%;

    .avatar {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
    }

    .email {
      margin-left: 12px;
      font-size: 18px;
      color: var(--font-third-gray);
    }

    .name {
      margin-right: 32px;
      margin-left: 32px;
      font-size: 24px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        margin: 0 10px 0 20px;
        font-size: 20px;
      }
    }

    .btn {
      &:not(:last-child) {
        margin-right: 24px;

        @include media-xs-only {
          margin-right: 12px;
        }
      }
    }
  }
</style>
