import dayjs from 'dayjs';

export const parseTime = function(time) {
  const values = (time || '').split(':');
  if (values.length >= 2) {
    const hours = parseInt(values[0], 10);
    const minutes = parseInt(values[1], 10);
    return {
      hours,
      minutes
    };
  }
  /* istanbul ignore next */
  return null;
};

export const compareTime = function(time1, time2) {
  const value1 = parseTime(time1);
  const value2 = parseTime(time2);
  const minutes1 = value1.minutes + value1.hours * 60;
  const minutes2 = value2.minutes + value2.hours * 60;
  if (minutes1 === minutes2) {
    return 0;
  }
  return minutes1 > minutes2 ? 1 : -1;
};

export const formatTime = function(time) {
  return (time.hours < 10 ? '0' + time.hours : time.hours) + ':' + (time.minutes < 10 ? '0' + time.minutes : time.minutes);
};

export const nextTime = function(time, step) {
  const timeValue = parseTime(time);
  const stepValue = parseTime(step);
  const next = {
    hours: timeValue.hours,
    minutes: timeValue.minutes
  };
  next.minutes += stepValue.minutes;
  next.hours += stepValue.hours;
  next.hours += Math.floor(next.minutes / 60);
  next.minutes = next.minutes % 60;
  return formatTime(next);
};

export function parseText(text) {
  text = text ? text.replace(/ */g, '') : '';
  const parameters = [
    'HH:mmA', 'H:mmA', 'H:mA', 'hh:mmA', 'hh:mA', 'h:mmA', 'h:mA',
    'HH:mma', 'H:mma', 'H:ma', 'hh:mma', 'hh:ma', 'h:mma', 'h:ma',
    'AHH:mm', 'AH:mm', 'AH:m', 'Ahh:mm', 'Ahh:m', 'Ah:mm', 'Ah:m',
    'aHH:mm', 'aH:mm', 'aH:m', 'ahh:mm', 'ahh:m', 'ah:mm', 'ah:m',
    'HH:mm', 'H:mm', 'H:m', 'hh:mm', 'hh:m', 'h:mm', 'h:m',
  ];
  // 为了提高匹配精确度，采用严格模式匹配
  let d = dayjs(text, parameters, true);
  // 如果以上都没有匹配到，采用严格模式匹配，去掉中间冒号匹配
  d = d.isValid() ? d : dayjs(text, parameters.map(item => item.replace(':', '')), true);
  // 如果以上都没有匹配到，采用非严格模式匹配
  d = d.isValid() ? d : dayjs(text, parameters);
  // 最终全部没匹配到，返回 null
  return d.isValid() ? d.format('HH:mm') : null;
}
