import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';

const timeZoneStorageKey = 'timeZone';

class TimeZonesStore extends SimpleStore {
  constructor (params) {
    super(params);
    const { Storage } = require('@/utils/storage.js');
    this.storage = new Storage(`${process.env.VUE_APP_ENV}_time_zone`, 24 * 3600 * 1000 * 30);
    this.data = this.storage.get(timeZoneStorageKey);
  }

  data = []
  storage = null

  async fetchData() {
    const timeZone = this.storage.get(timeZoneStorageKey);
    if (timeZone) {
      return this.data = timeZone;
    }
    this.fetchDataPromise = this.fetching(request.get('countries/time_zones'));
    const { data } = await this.fetchDataPromise.finally(() => this.fetchDataPromise = null);
    this.storage.set(timeZoneStorageKey, data);
    this.data = data;
  }
}

export const timeZonesStore = new TimeZonesStore();
