<template>
  <div class="backpack">
    <div class="page-title">My Backpack</div>

    <div class="backpack-wrap">
      <el-collapse v-if="$get(backpackStore, 'data.length') > 0">
        <el-collapse-item v-for="item in backpackStore.data" :key="item.id">
          <template slot="title">
            <div class="title-wrap bs-flex bs-item-center">
              <h2 class="title text-overflow">{{ item.name }}</h2>
              <div class="info-box bs-flex hidden-xs-only">
                <router-link
                  :to="{ name: 'tasks', query: { subject_taggings_subject_id_in: [item.id] } }"
                  class="info"
                  :class="{ 'no-font-color': item.active_tasks_count === 0 }"
                >
                    {{ item.active_tasks_count | pluralize('assignment', true) }}
                  </router-link>
                <router-link
                  v-if="item.overdue_tasks_count"
                  :to="{ name: 'tasks', query: { overdue: true, subject_taggings_subject_id_in: [item.id] } }"
                  class="info overdue-task-font-color"
                >
                  {{ item.overdue_tasks_count | pluralize('overdue assignment', true) }}
                </router-link>
                <router-link
                  v-if="item.active_reminders_count"
                  :to="{ name: 'reminders', query: { subject_taggings_subject_id_in: [item.id] } }"
                  class="info"
                  :class="{ 'no-font-color': item.active_reminders_count === 0 }"
                >
                  {{ item.active_reminders_count | pluralize('reminder assignment', true) }}
                </router-link>
                <router-link
                  :to="{ name: 'notes', query: { subject_taggings_subject_id_in: [item.id] } }"
                  class="info"
                  :class="{ 'no-font-color': item.notes_count === 0 }"
                >
                  {{ item.notes_count | pluralize('note', true) }}
                </router-link>
                <router-link
                  :to="{ name: 'files', query: { document_taggings_taggable_of_Subject_type_id_in: [item.id] } }"
                  class="info"
                  :class="{ 'no-font-color': item.documents_count === 0 }"
                >
                  {{ item.documents_count | pluralize('file', true) }}
                </router-link>
              </div>
            </div>
          </template>

          <el-row :gutter="24">
            <el-col :sm="8" :xs="24">
              <common-prod-card title="Assignments" :class="{'nothing': $get(item, 'active_tasks.length') === 0}">
                <div class="card-content">
                  <template v-if="$get(item, 'active_tasks.length') > 0">
                    <div v-for="task in item.active_tasks" :key="task.id" class="line">
                      <router-link :to="{ name: 'task.detail', params: { id: task.id } }"
                        class="text-overflow line-link"
                      >
                        {{ task.name }}
                      </router-link>
                      <div class="due-time">Due {{ task.due_at | formatTime('MMM DD, YYYY') }}</div>
                    </div>
                  </template>
                  <span v-else>Nothing to see here...</span>
                </div>
                <router-link v-if="item.active_tasks_count > 5"
                  :to="{ name: 'tasks' }"
                  class="see-all"
                >
                  See all
                </router-link>
              </common-prod-card>
            </el-col>
            <el-col v-if="$get(item, 'active_reminders.length') !== 0" :sm="8" :xs="24">
              <common-prod-card title="Reminder Assignments">
                <div class="card-content">
                  <template v-if="$get(item, 'active_reminders.length') > 0">
                    <div v-for="reminder in item.active_reminders" :key="reminder.id" class="line">
                      <router-link :to="{ name: 'task.detail', params: { id: reminder.id } }"
                        class="text-overflow line-link"
                      >
                        {{ reminder.name }}
                      </router-link>
                      <div class="due-time">Until {{ reminder.repeat_end_date | formatTime('MMM DD, YYYY') }}</div>
                    </div>
                  </template>
                  <span v-else>Nothing to see here...</span>
                </div>
                <router-link v-if="item.active_reminders > 5"
                  :to="{ name: 'reminders' }"
                  class="see-all"
                >
                  See all
                </router-link>
              </common-prod-card>
            </el-col>
            <el-col :sm="8" :xs="24">
              <common-prod-card title="Notes" :class="{'nothing': $get(item, 'notes.length') === 0}">
                <div class="card-content">
                  <template v-if="$get(item, 'notes.length') > 0">
                    <div v-for="note in item.notes" :key="note.id" class="line">
                      <router-link :to="{ name: 'note.detail', params: { id: note.id } }"
                        class="text-overflow line-link"
                      >
                        {{ note.title }}
                      </router-link>
                      <div class="due-time">{{ note.created_at | formatTime('MMM DD, YYYY') }}</div>
                    </div>
                  </template>
                  <span v-else>Nothing to see here...</span>
                </div>
                <router-link v-if="item.notes_count > 5"
                  :to="{ name: 'notes' }"
                  class="see-all"
                >
                  See all
                </router-link>
              </common-prod-card>
            </el-col>
            <el-col :sm="8" :xs="24">
              <common-prod-card title="Files" :class="{'nothing': $get(item, 'documents.length') === 0}">
                <div class="card-content">
                  <template v-if="$get(item, 'documents.length') > 0">
                    <div v-for="file in item.documents" :key="file.id" class="line">
                      <FileItem :file="file"/>
                      <div class="due-time">{{ file.created_at | formatTime('MMM DD, YYYY') }}</div>
                    </div>
                  </template>
                  <span v-else>Nothing to see here...</span>
                </div>
                <router-link v-if="item.documents_count > 5"
                  :to="{ name: 'files' }"
                  class="see-all"
                >
                  See all
                </router-link>
              </common-prod-card>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <div v-else class="empty">There is nothing in your backpack yet!</div>
      <common-prod-pagination :store="backpackStore"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import FileItem from '@/components/prod/file-item.vue';

  @Component({
    components: {
      FileItem
    }
  })
  export default class MyBackpack extends Vue {
    backpackStore = this.$Collection.create({
      fetch: params => this.$request.get('subjects', { params })
    })

    @Vue.autoLoadingProgress
    async created() {
      await this.fetchData();
    }

    async fetchData() {
      await this.backpackStore.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-collapse {
    border: none;

    .el-collapse-item__header,
    .el-collapse-item__content {
      border: none;
      color: var(--font-secondary-black);
      background: var(--bg-primary-gray);
    }

    .el-collapse-item__header {
      padding: 24px;

      &.is-active {
        .info-box {
          display: none;
        }
      }
    }

    .el-collapse-item__content {
      padding: 0 16px 24px;
    }

    .el-collapse-item__wrap {
      border: none;
    }

    .el-collapse-item__arrow {
      &::before {
        font-weight: bold;
        font-size: 32px;
        color: var(--font-secondary-black);
        content: '+';
      }

      &.is-active {
        transform: none;

        &::before {
          content: '-';
        }
      }
    }

    .el-collapse-item + .el-collapse-item {
      margin-top: 16px;
    }
  }

  div ::v-deep .card {
    display: flex;
    flex-direction: column;
    height: 510px;
    border-radius: 8px;
    background: var(--bg-primary-white);

    @include media-xs-only {
      height: auto;
    }

    &.nothing {
      .card-content {
        font-style: italic;
        font-size: 24px;
        color: var(--font-secondary-gray);
      }

      .see-all {
        color: var(--font-secondary-gray);
      }
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0;
      padding-top: 24px;
    }

    .card-content {
      flex: 1;
    }

    .line {
      font-size: 16px;
      color: var(--font-secondary-black);

      &:not(:first-child) {
        margin-top: 16px;
      }

      .line-link,
      .file-item span {
        font-size: 24px;
        text-decoration: underline;
        color: var(--font-primary-purple);

        @include text-overflow();
      }
    }

    .see-all {
      text-align: right;
      text-decoration: underline;
      color: var(--font-forth-purple);
    }
  }

  ::v-deep .el-col {
    margin-bottom: 24px;
  }

  .backpack-wrap {
    .title {
      width: 375px;
      font-weight: 500;
    }

    .info-box {
      .info {
        font-style: italic;
        text-decoration: underline;
        color: var(--font-primary-purple);

        &.overdue-task-font-color {
          color: var(--font-primary-red);
        }

        &.no-font-color {
          color: var(--font-secondary-gray);
        }
      }

      .info + .info {
        margin-left: 48px;
      }
    }
  }

  .empty {
    margin: 80px 0;
    font-size: 36px;
    color: var(--font-secondary-black);
  }
</style>
