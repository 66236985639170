<template>
  <div v-loading="loading" class="add-task">
    <div class="input-group">
      <el-row :gutter="32">
        <el-col :sm="$authStore.isAdvisor ? 12 : 24" :xs="24">
          <common-prod-select
            v-model="task.subject_ids"
            :options="subjectsStore.data"
            placeholder="None selected"
            inputTitle="Select a subject"
            multiple
          />
        </el-col>
        <el-col v-if="$authStore.isAdvisor" :sm="12" :xs="24">
          <common-prod-select
            v-model="task.student_id"
            :options="studentsStore.data"
            placeholder="None selected"
            inputTitle="Select a student"
            @input="errorList.student_id = ''"
          />
          <div v-show="errorList.student_id" class="input-error-msg bs-flex bs-content-between">
            <div>{{ errorList.student_id }}</div>
          </div>
        </el-col>
        <el-col :xs="24">
          <common-prod-input
            v-model.trim="task.name"
            inputTitle="Assignment name"
            @input="errorList.name = ''"
          />
          <div v-show="errorList.name" class="input-error-msg bs-flex bs-content-between">
            <div>{{ errorList.name }}</div>
          </div>
        </el-col>
        <el-col :sm="showDueDate ? 12 : 24" :xs="24">
          <RepeatSelect v-model="task.recurrence" />
        </el-col>
        <el-col v-if="showDueDate" :sm="12" :xs="24">
          <common-prod-input type="onlySelect" inputTitle="Due date">
            <template v-slot:selectInput>
              <DatePicker
                v-model="task.due_at"
                :getDisabledDate="getDisabledDate"
                :disabled="!!task.recurrence"
                label="Due Date"
                @change="errorList.due_at = ''"
              />
            </template>
          </common-prod-input>
          <div v-show="errorList.due_at" class="input-error-msg bs-flex bs-content-between">
            <div>{{ errorList.due_at }}</div>
          </div>
        </el-col>
        <el-col :xs="24">
          <common-prod-input
            v-model="task.desc"
            type="richtext"
            inputTitle="What needs to be done? (Optional)"
          />
        </el-col>
        <el-col v-if="!isEdit" :xs="24">
          <common-prod-upload-input v-model="task.document_taggings_attributes"/>
        </el-col>
      </el-row>
    </div>
    <common-prod-button @click.native="handleSubmit">{{ isEdit ? 'Save changes': 'Add assignment' }}</common-prod-button>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import _ from 'lodash';
  import dayjs from 'dayjs';
  import Schema from 'async-validator';
  import { subjectsStore, studentsStore } from '@/stores';
  import { toPlainDesc } from '@/utils';
  import DatePicker from './date-picker.vue';
  import RepeatSelect from './repeat-select.vue';

  const defaultTaskForm = {
    name: '',
    student_id: null,
    desc: '',
    due_at: undefined,
    recurrence: '',
    subject_ids: [],
    update_or_delete_method: undefined,
    document_taggings_attributes: []
  };

  @Component({
    components: {
      DatePicker,
      RepeatSelect
    }
  })
  export default class TaskForm extends Vue {
    @Prop({ type: Boolean, default: false }) isEdit
    @Prop({ type: Object, default: () => ({}) }) taskForm
    @Prop({ type: [Number, String], default: null }) id
    @Prop(Function) submit

    task = {}
    errorList = {
      student_id: '',
      name: '',
      due_at: '',
    }

    subjectsStore = subjectsStore
    studentsStore = studentsStore

    get getDisabledDate() {
      return {
        disabledDate: (time) => {
          return dayjs(time) < dayjs();
        }
      };
    }

    get showDueDate() {
      return !this.isEdit || !this.task.recurrence;
    }

    // 获取表单数据
    getFormData(task) {
      const recurrence = this.$get(task.recurrence, '[0]') || '';
      this.task = _.pick(task, Object.keys(defaultTaskForm));
      if (this.task.recurrence !== recurrence) {
        this.task.recurrence = recurrence;
      }
    }

    @Watch('taskForm', { immediate: true, deep: true })
    formChange(val) {
      if (!_.isEmpty(val)) {
        this.getFormData(val);
      }
    }

    loading = false
    @Watch('id', { immediate: true })
    async fetchDetail(val, oldVal) {
      if (!val || val === oldVal) { return; }

      try {
        this.loading = true;
        const { data } = await this.$request.get(`tasks/${val}`);
        this.getFormData(data);
      } finally {
        this.loading = false;
      }
    }

    @Watch('isEdit', { immediate: true })
    addTask() {
      if (!this.isEdit) {
        this.task = _.cloneDeep(defaultTaskForm);
      }
    }

    async formValidate(body) {
      const descriptor = {
        name: { required: true, message: 'The assignment cannot be blank' },
        student_id: { required: this.$authStore.isAdvisor, message: 'Please choose a student' },
        due_at: {
          required: !this.task.recurrence,
          message: 'Please choose a due date'
        }
      };
      const validator = new Schema(descriptor);

      await validator.validate(body)
        .catch(({ errors }) => {
          errors.map(err => this.errorList[err.field] = err.message);
          throw new Error();
        });
    }

    async handleSubmit() {
      const documentTagging = _.map(this.task.document_taggings_attributes, item =>
        ({ document: { attachment: item.signed_id } })
      );
      const body = {
        ...this.task,
        recurrence: this.task.recurrence ? [ this.task.recurrence ] : [],
        plain_desc: toPlainDesc(this.task.desc),
        document_taggings_attributes: documentTagging,
      };

      await this.formValidate(body);

      if (_.isFunction(this.submit)) {
        await this.submit(body);
      }
      if (!this.isEdit) {
        this.file = {};
        this.task = _.cloneDeep(defaultTaskForm);
      }
      this.$emit('close');
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-col {
    margin-bottom: 24px;
  }

  ::v-deep .ato-button {
    @include media-xs-only {
      width: 100%;
    }
  }
</style>
