import urlJoin from 'url-join';

const { VUE_APP_ENV, VUE_APP_FJP_HOST } = process.env;

export const ACCESS_TOKEN_KEY = `${VUE_APP_ENV}_access_token`;
export const CURRENT_STUDENT_ID = `${VUE_APP_ENV}_current_student_id`;
export const IS_DARK_THEME = `${VUE_APP_ENV}_is_dark_theme`;
export const VALIDATE_PASSWORD = /^(?=.*[A-Za-z])(?=.*[0-9])(.{8,})$/;
export const CHECK_PASSWORD_TIP = 'The password length must be greater than 8, and contain both numbers and letters.';
export const SAME_PASSWORD_TIP = 'Inconsistent input of new password';

export const LABEL_COLORS = {
  tomato: 'rgb(213, 0, 0)',
  flamingo: 'rgb(230, 124, 115)',
  tangerine: 'rgb(244, 81, 30)',
  banana: 'rgb(246, 191, 38)',
  sage: 'rgb(51, 182, 121)',
  basil: 'rgb(11, 128, 67)',
  peacock: 'rgb(3, 155, 229)',
  blueberry: 'rgb(63, 81, 181)',
  lavender: 'rgb(121, 134, 203)',
  graphite: 'rgb(97, 97, 97)',
};

export const REDIRECT_URL_SESSION_KEY = `${VUE_APP_ENV}_redirect_session_url`;

// google 登录只能返回到fjp
export const GOOGLE_REDIRECT_URL = urlJoin(VUE_APP_ENV === 'development' ? 'http://localhost:8000' : VUE_APP_FJP_HOST, '/callback_google');

export const WEEKLY_TRANSFORM_MAP = [
  { full: 'Monday', simple: 'M' },
  { full: 'Tuesday', simple: 'T' },
  { full: 'Wednesday', simple: 'W' },
  { full: 'Thursday', simple: 'Th' },
  { full: 'Friday', simple: 'F' },
  { full: 'Saturday', simple: 'Sa' },
  { full: 'Sunday', simple: 'Su' }
];

export const CALENDAR_EVENT_DEFAULT_COLORS = [
  '#d50000', '#E67C73', '#F4511E', '#F6BF26', '#33B679', '#0B8043',
  '#039BE5', '#3F51B5', '#7986CB', '#8E24AA', '#616161', '#7CB342',
];
