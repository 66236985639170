import { Collection } from './helper/collection';
import { request } from '@/utils';

export class SubjectsStore extends Collection {

  fetch(params) {
    return request.get('subjects/read_options', { params });
  }
}

export const subjectsStore = new SubjectsStore();
