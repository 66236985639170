import { Collection } from './helper/collection';
import { request } from '@/utils';

export class StudentsStore extends Collection {
  fetch(params) {
    return request.get('mine/students', { params });
  }

  handleOpenLink(link) {
    window.open(link, '_blank');
  }

  handleWhatsApp(student) {
    window.open(`https://wa.me/${student.contact.whats_app_number}`, '_blank');
  }
}

export const studentsStore = StudentsStore.create();
