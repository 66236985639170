<template>
  <div id="app" :class="[{'dark-theme': $isDarkTheme}]">
    <router-view v-if="$route.meta.hiddenLayout" :key="$route.fullPath"/>
    <common-layout v-else>
      <router-view :key="$route.fullPath"/>
    </common-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class App extends Vue {
    created() {
      this.$settingsStore.tryFetchData();
      this.initGoogleAnalytics();
    }

    initGoogleAnalytics() {
      const { VUE_APP_GOOGLE_ANALYTICS_ID } = process.env;
      if (!VUE_APP_GOOGLE_ANALYTICS_ID) {
        return;
      }
      const s = document.createElement('script');
      s.onload = function() {
        document.body.removeChild(s);
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', VUE_APP_GOOGLE_ANALYTICS_ID);
      };
      document.body.append(s);
      s.src = `https://www.googletagmanager.com/gtag/js?id=${VUE_APP_GOOGLE_ANALYTICS_ID}`;
    }
  }
</script>

<style lang="scss">
  html, body {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'Neue Montreal Medium';
  }

  #app {
    width: 100%;
    font-size: 14px;
    color: var(--font-primary-black);
  }
</style>
