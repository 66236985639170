<template>
  <div class="info-wrap">
    <el-row :gutter="30" type="flex" :justify="center ? 'center' : 'start'" v-if="profile">
      <el-col :xs="24" :sm="8" class="input-group">
        <common-prod-input
          inputTitle="Current password"
          v-model.trim="passwordForm.current_password"
          placeholder=""
          type="password"
          @input="incorrectPassword = ''"
          @keyup.enter.native="handleChangePassword"
        />
        <div v-show="incorrectPassword" class="input-error-msg">{{ incorrectPassword }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="30" type="flex" :justify="center ? 'center' : 'start'">
      <el-col :xs="24" :sm="8" class="input-group">
        <common-prod-input
          inputTitle="New password"
          v-model.trim="passwordForm.password"
          placeholder=""
          type="password"
          @keyup.enter.native="handleChangePassword"
          @input="invalidFormat = ''"
        />
        <div v-show="invalidFormat" class="input-error-msg">{{ invalidFormat }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="30" type="flex" :justify="center ? 'center' : 'start'">
      <el-col :xs="24" :sm="8" class="input-group">
         <common-prod-input
          inputTitle="Confirm password"
          v-model.trim="passwordForm.password_confirmation"
          placeholder=""
          type="password"
          @input="newPasswordError = ''"
          @keyup.enter.native="handleChangePassword"
        />
        <div v-show="newPasswordError" class="input-error-msg">{{ newPasswordError }}</div>
      </el-col>
    </el-row>

    <div class="footer">
      <common-prod-button
        @click.native="handleChangePassword"
        :disabled="!canClickBtn"
        size="large"
      >SAVE CHANGES</common-prod-button>
    </div>
  </div>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { authStore } from '@/stores';
  import { VALIDATE_PASSWORD, CHECK_PASSWORD_TIP, SAME_PASSWORD_TIP } from '@/constants';

  @Component
  export default class PasswordGroup extends Vue {
    @Prop({ type: Boolean, default: false }) center
    @Prop({ type: Boolean, default: true }) profile

    passwordForm = {
      current_password: '',
      password: '',
      password_confirmation: ''
    }

    incorrectPassword = ''
    newPasswordError = ''
    invalidFormat = ''

    get canClickBtn() {
      if (this.profile) {
        return _.every(this.passwordForm);
      } else {
        return _.every(_.omit(this.passwordForm, ['current_password']));
      }
    }

    async handleChangePassword() {
      if (!this.canClickBtn) {
        return;
      }
      if (!VALIDATE_PASSWORD.test(this.passwordForm.password)) {
        this.invalidFormat = CHECK_PASSWORD_TIP;
        await this.$message({ message: this.invalidFormat, type: 'error' });
        return;
      }
      if (this.passwordForm.password !== this.passwordForm.password_confirmation) {
        this.newPasswordError = SAME_PASSWORD_TIP;
        await this.$message({ message: this.newPasswordError, type: 'error' });
        return;
      }
      try {
        await this.$autoLoading(authStore.changePassword(this.passwordForm, this.profile ? true : false));
      } catch (e) {
        this.incorrectPassword = e;
        return;
      }
      if (this.profile) {
        this.$message({ message: 'Password changed successfully, please login again', type: 'success' });
        this.$router.replace({ name: 'login' });
      } else {
        this.$message({ message: 'Password changed successfully.', type: 'success' });
        this.$router.replace({ name: 'welcomePage' });
      }
    }
  }
</script>

<style lang="scss">
  .info-wrap {
    margin-top: 50px;

    @include media-xs-only {
      margin-top: 26px;
    }

    .input-group {
      padding-bottom: 30px;
    }
  }

  .footer {
    margin-bottom: 100px;

    @include media-xs-only {
      width: 100%;
    }

    .tip {
      font-weight: 500;
      font-size: 18px;
      color: var(--font-primary-purple);
      cursor: pointer;
    }

    .ato-button {
      &.ato-button--large {
        width: 316px;

        @include media-xs-only {
          box-sizing: border-box;
          width: 100%;
          height: 48px;
        }
      }
    }
  }
</style>
