<template>
  <div>
    <router-link :to="{ name: 'tasks' }" class="back-btn">Back to all tasks</router-link>
    <div class="task-wrapper">
      <div class="header-wrap bs-flex bs-item-center">
        <div class="title">{{ task.name }}</div>
        <div class="tag-wrap">
          <common-prod-tag v-for="tag in $get(task, 'subjects', [])" :key="tag.id"
            :tag="tag"
            :showClose="false"
          />
          <common-prod-tag v-if="isAdvisor && task.student"
            :tag="task.student"
            :showClose="false"
          />
        </div>
        <div class="time-wrap">
          <div v-if="task.due_at && !isRepeatTask" class="due-time">
            {{ `(Due ${$options.filters.formatTime(task.due_at, 'MMM DD, YYYY')})` }}
          </div>
          <div v-else-if="repeatTaskEndTime" class="due-time">
            {{ `(Until ${repeatTaskEndTime})` }}
          </div>
        </div>
      </div>
      <div class="desc-and-btns-wrap">
        <common-richtext-editor :value="task.desc" only-read />
        <div class="btns-group bs-flex bs-column bs-item-center">
          <template v-if="task.status">
            <span
              v-if="['init', 'wait_advisor_review'].includes(task.status)"
              :class="{ 'disabled': !isAdvisor && task.status === 'wait_advisor_review' }"
              class="btn"
              @click="handleTaskComplete"
            >
              {{ task.status === 'init'
                ? (isAdvisor ? 'Mark Complete' : "I'm Done")
                : (isAdvisor ? 'Mark Complete' : 'Awaiting Review')
              }}
            </span>
            <span v-if="task.status !== 'init'" class="btn" @click="handleTaskReopen">
              {{ isAdvisor ? 'Request Revision' : 'Withdraw submission' }}
            </span>
          </template>

          <span v-if="showDeleteBtn && isRepeatTask" class="btn" @click="showDeletePopup = true">
            Stop Reminder
          </span>
          <span v-if="showDeleteBtn" class="btn" @click="showEditTask = true">
            Edit
          </span>
          <span v-if="showDeleteBtn && !isRepeatTask" class="btn" @click="handleDelete">
            Delete
          </span>
        </div>
      </div>
    </div>

    <common-prod-card title="FILES" class="files-wrapper">
      <!-- 文件列表 -->
      <common-prod-file-lists :files="files" @delete-item="handleDeleteFile"/>
      <div class="bs-flex bs-content-end" v-if="$get(this.task, 'documents.length') > 2">
        <span class="link" @click="showAllFile = !showAllFile">{{showAllFile ? 'Hide files' : 'All files'}}</span>
      </div>

      <common-prod-upload-input
        label=""
        :show-files="false"
        @upload-success="handleAddFile"
        sizeTip="(A single file cannot exceed 100 MB)"
      >
        <common-prod-button class="relative">UPLOAD A FILE </common-prod-button>
      </common-prod-upload-input>
      <!-- 新上传的文件 -->
      <div class="bs-flex bs-item-center item" v-for="(item, key) in progress" :key="key">
        <el-progress :percentage="item.progress" style="width: 20.98636rem"/>
      </div>
    </common-prod-card>

    <common-prod-card title="COMMENTS" class="comments-wrapper">
      <div class="comment-list">
        <div class="bs-flex comment-item" v-for="item in commentsStore.data" :key="item.id">
          <img :src="$get(item, 'creator.avatar.url') || require('@/assets/default-avatar.png')" alt="" class="avatar">
          <div class="bs-flex-1" style="overflow: hidden;">
            <div class="item-header bs-flex bs-item-baseline font-regular">
              <div class="username">{{ $get(item, 'creator_id') === $get($authStore, 'user.id') ? 'You' : item.creator.name }}</div>
              <div class="comment-time">{{ item.updated_at | formatTime('MMM DD,&nbsp;&nbsp;YYYY') }}</div>
              <template v-if="$get(item, 'creator_id') === $get($authStore, 'user.id')" >
                <span class="action-btn" @click="editComment = item">Edit</span>
                <span class="action-btn" @click="handleDeleteComment(item.id)">Delete</span>
              </template>
            </div>
            <div class="message">
              <common-richtext-editor :value="item.content" only-read/>
              <common-prod-file-lists
                :files="item.document_taggings_attributes"
                @delete-item="handleDeleteCommentFile($event, item)"
                :isNormal="false"
              />
            </div>
          </div>
        </div>
        <common-prod-button v-if="!commentsStore.isComplete" @click.native="handleLoadMore(commentsStore)">LOAD MORE</common-prod-button>
      </div>
      <comment-form :submit="handleSubmitComment"/>
    </common-prod-card>

    <common-popup :show="!!editComment" @close="editComment = null" title="Edit a comment">
      <comment-form :submit="($event) => handleSubmitComment($event, editComment)" type="Edit" :value="editComment"/>
    </common-popup>

    <common-popup v-model="showEditTask" title="Edit an assignment">
      <task-form :taskForm="task" isEdit :submit="handleEdit" @close="showEditTask = false"/>
    </common-popup>

    <common-popup v-model="showDeletePopup" title="Stop Reminder" class="delete-reminder-popup">
      <common-prod-select
        v-model="repeatMethod"
        inputTitle="Repeat method"
        :options="reminderMethods"
        name="label"
        valueKey="value"
      />

      <div class="popup-footer bs-flex bs-content-end bs-item-center">
        <common-prod-button @click.native="handleDeleteReminder" class="uppercase">
          Confirm
        </common-prod-button>
      </div>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import CommentForm from './components/comment-form';
  import TaskForm from './components/task-form.vue';
  import { subjectsStore, studentsStore } from '@/stores';
  import _ from 'lodash';
  import dayjs from 'dayjs';

  @Component({
    components: {
      'comment-form': CommentForm,
      'task-form': TaskForm
    }
  })
  export default class TaskDetail extends Vue {
    task = {}
    progress = {}
    showEditTask = false
    showAllFile = false
    editComment = null
    showDeletePopup = false
    repeatMethod = 'this_and_following_tasks'
    reminderMethods = [
      { label: 'This and following tasks', value: 'this_and_following_tasks' },
      { label: 'All tasks', value: 'all_tasks' },
    ]

    commentsStore = this.$Collection.create({
      params: { per_page: 5 },
      fetch: (params) => {
        return this.$request.get('comments', { params: {
          ...params,
          commentable_type_eq: 'Task',
          commentable_id_eq: this.$route.params.id,
        } });
      }
    })

    get isAdvisor() {
      return this.$authStore.isAdvisor;
    }

    get files() {
      const files = _.get(this.task, 'documents') || [];
      return this.showAllFile ? files : files.slice(0, 2);
    }

    get showDeleteBtn() {
      // 学生可以删除自己新建的任务的，advisor能删除自己还有学生的任务的。
      if (this.isAdvisor) {
        return true;
      }
      return this.$get(this.task, 'creator.id') && this.$get(this.task, 'creator.id') === this.$get(this.$authStore, 'user.id');
    }

    // 判断是否是重复任务
    get isRepeatTask() {
      return this.$get(this.task, 'recurrence.length') > 0;
    }

    // 获取重复任务的结束时间
    get repeatTaskEndTime() {
      const recurrence = this.$get(this.task.recurrence, '[0]') || '';
      const match = recurrence.match(/UNTIL=([0-9TZ]+)/);

      if (match) {
        return dayjs(recurrence.match(/UNTIL=([0-9TZ]+)/)[1], 'YYYYMMDDTHHmmssZ').format('ddd MMM DD, YYYY');
      }
      return null;
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      return Promise.all([
        this.fetchTask(),
        this.commentsStore.fetchData(),
        subjectsStore.fetchData({ page: 0 }),
        this.isAdvisor && studentsStore.fetchData({ page: 0 }), // 学生不调取studentsStore
      ]).catch((e) => {
        // 该任务删除之后会找不到，去到404页面
        if (e.status === 404) {
          this.$MessageBox({
            title: 'Tips',
            message: 'The assignment has been deleted or the resource is not found',
            type: 'error',
          }).then(() => {
            this.$router.go(-1);
          });
        }
      });
    }

    async fetchTask() {
      const { data } = await this.$request.get(`tasks/${this.$route.params.id}`);
      this.task = data;
    }

    async handleAddFile(file) {
      const { data } = await this.$request.post('documents', {
        attachments: [file.signed_id],
        document_taggings_attributes: [
          { taggable_type: 'Task', taggable_id: this.$route.params.id, }
        ],
        student_id: this.task.student_id || this.$authStore.currentStudentId
      });
      this.task.documents.push(...data);
    }

    async handleTaskComplete() {
      const confirmTexts = this.isAdvisor ? {
        title: 'Confirmation',
        text: `Confirm that ${this.$get(this.task, 'student.name')} has completed this assignment?`,
        cancelText: 'No, it still needs work'
      } : {
        title: 'Done?',
        text: 'Submit all files associated with this assignment to your advisor for review?',
        cancelText: 'No, I forgot something'
      };

      await this.$confirm(confirmTexts.text, confirmTexts.title, {
        confirmButtonText: 'YES',
        cancelButtonText: confirmTexts.cancelText,
      });

      if (this.isAdvisor) {
        const { data } = await this.$autoLoading(this.$request.put(`tasks/${this.task.id}/advisor_complete`));
        this.task = data;
      } else {
        const { data } = await this.$autoLoading(this.$request.put(`tasks/${this.task.id}/student_complete`));
        this.task = data;
      }
    }

    async handleTaskReopen() {
      if (this.task.status !== 'init') {
        await this.$confirm('Are you sure you want to request revision for this assignment?', 'Confirmation');
        const { data } = await this.$autoLoading(this.$request.put(`/tasks/${this.$route.params.id}/reopen`));
        this.task = data;
      }
    }

    async handleDelete() {
      await this.$confirm('Are you sure you want to delete this assignment?');
      await this.$autoLoading(this.$request.delete(`tasks/${this.$route.params.id}`));
      this.$message({ message: 'Assignment deleted', type: 'success' });
      this.$router.replace({ name: 'tasks' });
    }

    @Vue.autoLoading
    async handleDeleteReminder() {
      await this.$autoLoading(
        this.$request.delete(`tasks/${this.$route.params.id}`, {
          params: {
            update_or_delete_method: this.repeatMethod
          }
        })
      );
      this.$message({ message: 'Assignment deleted', type: 'success' });
      this.$router.replace({ name: 'tasks' });
    }

    @Vue.autoLoading
    async handleEdit(body) {
      const { data } =  await this.$request.put(`/tasks/${this.$route.params.id}`, _.omit(body, ['document_taggings_attributes']));
      if (data.id !== this.task.id) {
        this.$router.replace({ name: 'task.detail', params: { id: data.id } });
        return;
      }
      this.task = data;
    }

    @Vue.autoLoading
    async handleDeleteFile(file) {
      await this.$request.delete(`documents/${file.id}`);
      const index = this.task.documents.findIndex(item => item.id === file.id);
      this.task.documents.splice(index, 1);
    }

    @Vue.autoLoading
    async handleDeleteCommentFile(file, comment) {
      const signedId = file.attachment.signed_id;
      const { data } = await this.$request.put(`comments/${comment.id}`, { document_taggings_attributes: [
        {
          id: file.id,
          attachment: signedId,
          _destroy: true
        }
      ] });
      this.commentsStore.replaceItem(data);
    }

    @Vue.autoLoading
    async handleDeleteComment(id) {
      await this.$request.delete(`comments/${id}`);
      this.commentsStore.removeItemById(id);
    }

    // 提交评论
    @Vue.autoLoading
    async handleSubmitComment(body, old) {
      const taskId = this.$route.params.id;
      if (_.get(old, 'id')) {
        const { data } = await this.$request.put(`comments/${old.id}`, { ...body });
        this.commentsStore.replaceItem(data);
        this.editComment = null;
      } else {
        const { data } = await this.$request.post('comments', {
          ...body,
          commentable_type: 'Task',
          commentable_id: taskId
        });
        this.commentsStore.unshift(data);
      }
    }

    @Vue.autoLoading
    handleLoadMore(store) {
      return store.fetchMoreData();
    }
  }
</script>

<style lang="scss" scoped>
  .back-btn {
    font-size: 18px;
    text-decoration: underline;
    color: var(--font-seventh-purple);
  }

  .task-wrapper {
    margin: 15px 0 50px;

    .header-wrap {
      @include media-lg-and-down {
        flex-direction: column;
        align-items: flex-start;
      }

      .title {
        max-width: 60%;
        font-size: 40px;
        color: var(--font-secondary-black);

        @include media-sm-and-up {
          @include text-overflow();
        }

        @include media-lg-and-down {
          width: 100%;
          max-width: 100%;
          font-size: 30px;

          @include text-overflow(2);
        }
      }

      .tag-wrap {
        margin-left: 10px;

        @include media-lg-and-down {
          margin: 10px 0;
        }

        .tag {
          margin: 0 10px 10px 0;
        }
      }

      .time-wrap {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        margin-left: 64px;

        @include media-lg-and-down {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin-left: 0;
        }
      }

      .due-time {
        flex-shrink: 0;
        font-size: 14px;
        color: var(--font-third-black);
      }
    }

    .desc-and-btns-wrap {
      display: flex;
      justify-content: space-between;

      @include media-lg-and-down {
        flex-direction: column;
      }

      .btns-group {
        margin-left: 170px;

        @include media-lg-and-down {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          margin: 20px 0 0;

          .btn:not(:last-child) {
            margin-right: 8px;
          }
        }

        .btn {
          box-sizing: border-box;
          width: 157px;
          height: auto;
          padding: 10px 0;
          border-radius: 10px;
          font-size: 18px;
          text-align: center;
          color: var(--font-primary-white);
          background: var(--bg-primary-purple);
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &.disabled {
            color: var(--font-secondary-gray);
            background: var(--bg-primary-gray);
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .files-wrapper {
    margin-bottom: 30px;

    .hide-input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .link {
      font-size: 18px;
      text-decoration: underline;
      color: var(--font-seventh-purple);
      cursor: pointer;
    }

    ::v-deep .upload-btn-wrapper {
      margin: 0;
    }
  }

  .comment-list {
    margin-bottom: 24px;
  }

  .comments-wrapper {
    color: var(--font-secondary-black);

    .comment-item {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 50px;
      }

      @include media-lg-and-down {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 30px;
        }
      }

      .item-header {
        @include media-lg-and-down {
          flex-wrap: wrap;
        }
      }

      ::v-deep .item {
        margin: 12px 0 0;
      }
    }

    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      border-radius: 50%;
      object-fit: cover;
    }

    .username {
      margin-top: 10px;
      margin-right: 13px;
      font-size: 24px;

      @include media-lg-and-down {
        width: 100%;
        margin: 0 0 10px;
      }
    }

    .comment-time {
      font-size: 16px;
    }

    .action-btn {
      margin-left: 10px;
      font-size: 16px;
      text-decoration: underline;
      color: var(--font-seventh-purple);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .message {
      margin-top: 12px;
      font-size: 18px;
    }
  }

  .popup-footer {
    margin: 40px 0;
  }

  .delete-reminder-popup {
    ::v-deep .popup-container {
      width: 650px;
    }
  }
</style>
