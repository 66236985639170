<template>
  <transition name="el-fade-in">
    <div v-show="show" class="dropdown-card">
      <div class="mask" @click="$emit('input', false)"></div>
      <div class="wrapper">
        <div class="switch-platform">
          <div class="platform" @click="handleLoginCAP">Senior Platform</div>
          <div class="platform active">Grades 9-11 Platform</div>
        </div>

        <div class="main-container">
          <div class="router-list">
            <div v-for="(item, index) in routerList" :key="index" class="router-item bs-flex bs-column bs-item-end">
              <router-link :to="{ name: item.name }">
                <span>{{ item.text }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="sign-out">
          <span @click="handleLogout">Sign out</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';
  import urlJoin from 'url-join';

  @Component
  export default class DropdownCard extends Vue {
    @Model('input', Boolean) show

    get routerList() {
      const isAdvisor = this.$authStore.isAdvisor;
      return [
        isAdvisor && {
          name: 'students',
          text: 'Students'
        },
        {
          name: 'profile',
          text: 'Your profile'
        },
        {
          name: 'preferences',
          text: 'Preferences'
        },
        !isAdvisor && {
          name: 'billing',
          text: 'Billing'
        }
      ].filter(Boolean);
    }

    @Watch('$route', { immediate: true })
    routeChange() {
      this.$emit('input', false);
    }

    // 直接登陆 CAP 高年级平台
    @Vue.autoLoadingProgress
    async handleLoginCAP() {
      const { data } = await this.$request.get('simple_sso/tokens/token');
      const href = urlJoin(process.env.VUE_APP_CAP_HOST, 'login/simple_sso', `?token=${data.token}`);
      location.href = href;
    }

    async handleLogout() {
      await this.$confirm('Confirm logout？');
      await this.$autoLoading(this.$authStore.logout());
      this.$router.replace({ name: 'login', query: { logout: Date.now() } });
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-card {
    position: absolute;
    z-index: 999;
    right: 26px;
    box-sizing: border-box;
    width: 277px;
    margin-top: 10px;
    font-size: 18px;
  }

  .wrapper {
    position: relative;
    z-index: 999;
    width: 278px;
    background-color: var(--bg-primary-gray);
    box-shadow: 0px 3px 18px var(--secondary-gray);

    .switch-platform {
      padding: 18px 16px;

      .platform {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 50px;
        padding: 0 23px;
        font-size: 18px;
        background: transparent;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &.active,
        &:hover {
          border-radius: 7px;
          color: var(--font-primary-white);
          background: var(--bg-forth-purple);
          cursor: pointer;
        }
      }
    }

    .main-container {
      padding: 20px 23px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.11);
      border-bottom: 1px solid rgba(0, 0, 0, 0.11);

      .router-list {
        color: var(--font-primary-red);

        .router-item {
          margin-bottom: 16px;
        }
      }
    }

    .sign-out {
      padding: 17px 23px;
      text-align: right;
      color: var(--font-primary-red);

      span {
        cursor: pointer;
      }
    }
  }

  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
