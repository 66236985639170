import router from '../router';
import _ from 'lodash';

/**
 * 用来获取数组 query 参数
 *
 * @param  {String} key 需要获取的query key
 *
 * @return {Array}      处理成数组的数据
 */
export function getQueryArray(key) {
  const includeType = _.get(router, `currentRoute.query[${key}]`);
  if (!includeType) {
    return [];
  } else if (includeType instanceof Array) {
    return includeType.map(String);
  }
  return _.split(includeType, ',');
}
