<template>
  <div class="list-container">
    <div class="content bs-flex">
      <div class="task-status">
        <common-task-status :task="lineItem" @finish="$emit('finish')" :to="to" />
      </div>

      <component v-if="lineItem.comments_count" class="msg-wrap hidden-md-and-up" :is="to ? 'router-link' : 'div'" :to="to">
        <common-prod-msg :msgText="lineItem.comments_count"/>
      </component>
    </div>

    <div class="due-time">{{time}}</div>

    <div class="tag-msg-btn-wrap bs-flex bs-lineItem-center">
      <slot name="tagSlot"/>

      <component v-if="lineItem.comments_count" class="msg-wrap hidden-sm-and-down" :is="to ? 'router-link' : 'div'" :to="to">
        <common-prod-msg :msgText="lineItem.comments_count"/>
      </component>

      <div class="btn-wrap hidden-md-and-up">
        <slot name="options"/>
      </div>
    </div>

    <div class="btn-wrap hidden-sm-and-down">
      <slot name="options"/>
    </div>

  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ListLine extends Vue {
    @Prop(String) time
    @Prop([String, Object]) to
    @Prop({ type: Object, default: () => ({}) }) lineItem
  }
</script>

<style lang="scss" scoped>
  .list-container {
    display: flex;
    flex: 1;

    @include media-md-and-down {
      display: block;
    }
  }

  .content {
    width: 40%;

    @include media-md-and-down {
      justify-content: space-between;
      width: 100%;
    }
  }

  .task-status {
    margin-right: 50px;

    @include media-md-and-down {
      margin-right: 0;
    }
  }

  .due-time {
    width: 15%;
    font-size: 14px;
    white-space: pre-wrap;
    color: var(--font-primary-blue);

    @include media-md-and-down {
      width: 100%;
      margin: 10px 0 0;
    }
  }

  .tag-msg-btn-wrap {
    width: 60%;

    @include media-md-and-down {
      justify-content: space-between;
      width: 100%;
      margin: 10px 0 0;
    }

    .msg-wrap {
      margin-left: 27px;
    }
  }

  .btn-wrap {
    width: 10%;

    @include media-xs-only {
      width: auto;
    }
  }
</style>
