<template>
  <el-select
    ref="selectUsersRef"
    v-model="checkedUsers"
    :placeholder="placeholder"
    filterable
    :multiple="multiple"
    remote
    clearable
    :collapse-tags="multiple"
    :remote-method="fetchUsers"
    :loading="loading"
    :size="size"
    :multiple-limit="multipleLimit"
    @change="handleChange"
    @clear="$emit('clear')"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      :disabled="eventKind === 'task' && $authStore.isAdvisor && item.role === 'advisor'"
    />
  </el-select>
</template>

<script>
  import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class SelectUsers extends Vue {
    @Model('change', { type: [Array, Number, String], default: () => [] }) value
    @Prop({ type: Array, default: () => [] }) defaultOptions
    @Prop({ type: String, default: 'Add guests' }) placeholder
    @Prop({ type: Boolean, default: true }) multiple
    @Prop({ type: String, default: '' }) size
    @Prop({ type: Boolean, default: false }) isStudent
    @Prop({ type: Number, default: 0 }) multipleLimit
    @Prop({ type: String, default: 'event' }) eventKind // event/task

    checkedUsers = []
    users = []
    loading = false

    get options() {
      return _.uniqBy([...this.defaultOptions, ...this.users], 'id');
    }

    @Watch('value', { immediate: true, deep: true })
    valueChange(val) {
      if (!_.isEqual(val, this.checkedUsers)) {
        this.checkedUsers = val;
      }
    }

    @Watch('checkedUsers')
    checkedUsersChange(val) {
      this.$emit('change', val);
    }

    created() {
      this.fetchUsers();
    }

    async fetchUsers(keyword) {
      this.loading = true;
      // 如果isStudent为true，则获取学生列表，否则获取会议参与者接口
      const { data } = await this.$request
        .get(this.isStudent ? 'mine/students' : 'mine/attendees', {
          params: { keyword, on_dashboard: true }
        })
        .finally(() => this.loading = false);
      // 如果defaultOptions有值，则合并defaultOptions和data，否则直接赋值data
      this.users = this.defaultOptions.length ? _.uniqBy([...data, ...this.users], 'id') : data;
    }

    async handleChange() {
      // 多选时，点击选项后，利用blur事件，让select失去焦点，从而让select的下拉框收起
      if (this.multiple) {
        await this.$nextTick();
        this.$refs.selectUsersRef.blur();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
