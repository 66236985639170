<template>
  <VApp>
    <div class="bs-flex bs-content-between actions-wrapper">
      <div class="bs-flex bs-item-center action-items">
        <div class="">
          <el-button @click="setToady" class="today-btn" :size="size">Today</el-button>
          <el-button icon="el-icon-arrow-left" :size="size" circle @click="$refs.calendar.prev()"/>
          <el-button icon="el-icon-arrow-right" :size="size" circle @click="$refs.calendar.next()"/>
        </div>
        <common-prod-picker-wrapper>
          <div class="current-calendar" v-if="currentCalendarTitle">{{ currentCalendarTitle }}</div>
          <el-date-picker
            slot="picker"
            :value="currentDate"
            value-format="yyyy-MM-dd"
            @input="currentDate = $event"
            :clearable="false"
            :editable="false"
            type="month"
          />
        </common-prod-picker-wrapper>
      </div>
      <div class="bs-flex bs-item-center action-items">
        <SelectUsers
          v-if="$authStore.isAdvisor"
          v-model="userId"
          placeholder="Student"
          :size="size"
          :multiple="false"
          is-student
          @clear="calendarEventsStore.clearEvents()"
        />
        <el-select v-model="type" placeholder="type" :size="size" >
          <el-option
            v-for="(label, value) in types"
            :key="value"
            :label="label"
            :value="value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="calendar-wrapper">
      <VCalendar
        @click:date="handleClickDate"
        @click:day="handleClickDay"
        @click:day-category="handleClickDay"
        @click:time="handleClickTime"
        @click:event="handleClickEvent"
        @click:more="handleClickMore"
        @change="handleCalendarChange"
        v-model="currentDate"
        :type="type"
        :events="[...temporaryEvents, ...events]"
        ref="calendar"
      >
        <template #event="slotProps">
          <div class="event-slot">
            <strong class="time" v-if="slotProps.eventParsed.input.timed && type === 'month'">{{ formatTime(slotProps.eventParsed.start.time) }}</strong>
            <span class="name">{{ slotProps.event.name }}</span>
            <span class="date" v-if="type === 'day'">
              <template v-if="slotProps.eventParsed.input.timed">, {{ formatTime(slotProps.eventParsed.start.time) }} - {{ formatTime(slotProps.eventParsed.end.time) }}</template>
              <template v-else>, All day</template>
            </span>
          </div>
        </template>
      </VCalendar>
    </div>
    <common-prod-dialog :visible.sync="dialogFormVisible" @close="handleCancelEventForm">
      <EventForm
        @submit="handleSubmit"
        @cancel="handleCancelEventForm"
        @change="handleEventFormChange"
        :form-data="form"
      />
    </common-prod-dialog>
    <common-prod-dialog
      :visible.sync="eventDetailVisible"
      :title="eventDetail.title"
      @close="handleCancelEventForm"
      class="detail-dialog"
    >
      <EventDetail
        :event="eventDetail"
        @delete="handleDeleteEvent"
        @edit="handleEditEvent"
        @toggle-task-status="handleToggleTaskStatus"
      />
    </common-prod-dialog>
  </VApp>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import {
    VCalendar,
    VApp,
  } from 'vuetify/lib';
  import EventForm from './components/event-form.vue';
  import EventDetail from './components/event-detail.vue';
  import { sleep } from '@/utils';
  import { calendarEventsStore } from '@/stores';
  import SelectUsers from './components/select-users.vue';

  @Component({
    components: {
      VCalendar,
      VApp,
      EventForm,
      EventDetail,
      SelectUsers
    }
  })
  export default class Calender extends Vue {
    type = 'month'
    types = {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 days',
    }
    currentDate = dayjs().format('YYYY-MM-DD')
    currentCalendarTitle = ''
    dialogFormVisible = false
    form = {}
    temporaryEvents = []
    events = []
    eventDetail = {}
    userId = null

    calendarEventsStore = calendarEventsStore

    get eventDetailVisible() {
      return !_.isEmpty(this.eventDetail);
    }

    set eventDetailVisible(val) {
      return this.eventDetail = {};
    }

    get title() {
      return _.get(this.$refs.calendar, 'title');
    }

    get size() {
      return window.innerWidth > 500 ? 'medium' : 'mini';
    }

    @Watch('currentDate')
    @Watch('userId')
    currentDateChange(val, old) {
      if (val !== old) {
        this.fetchEvents();
      }
    }

    @Vue.autoLoadingProgress
    created() {
      this.resetForm();
      return Promise.all([
        this.fetchEvents(),
        this.$authStore.tryFetchData(),
      ]);
    }

    mounted() {
      this.$refs.calendar.checkChange();
    }

    // 离开页面后清空缓存，防止在其他页面更改了task repeat的情况下，回到calendar页面时，显示数据不正确
    beforeDestroy() {
      calendarEventsStore.clearEvents();
    }

    /**
     * 获取当前日历显示event
     * 默认获取上一月、当前月、下一月三个月的events
     *
     */
    async fetchEvents() {
      return this.events = await calendarEventsStore.tryFetchData(this.currentDate, this.userId);
    }

    resetForm(data = {}) {
      const start_date = data.start_date || dayjs().format('YYYY-MM-DD');
      const start_time = data.start_time || dayjs().format('HH:mm');

      const form = {
        title: '', // Title of the event.
        kind: 'event',
        desc: '',
        start_time_zone: dayjs.tz.guess(),
        end_time_zone: dayjs.tz.guess(),
        ...data,
        start_date,
        start_time,
      };

      return this.form = form;
    }

    formatTime(time) {
      return dayjs(time, 'HH:mm').format('hh:mma');
    }

    setToady() {
      this.currentDate = dayjs().format('YYYY-MM-DD');
    }

    /**
     * 转换默认event时间，分钟需要停留在 00 或 30
     *
     * @param  {dayjs} date dayjs 时间对象
     *
     * @return {dayjs}      dayjs 时间对象
     */
     translateDefaultEventStartTime(date, isRound) {
      date = dayjs(date);
      const originHour = date.hour();
      const originMinute = date.minute();
      if (isRound) {
        if (originMinute > 45) {
          date = date.set('minute', originHour < 23 ? 60 : 30);
        } else {
          date = date.set('minute', originMinute > 15 ? 30 : 0);
        }
      } else {
        // 如果点击的是天，没带小时，需要设置默认分钟为下一个 30 分钟或 00
        date = date.set('minute', (originMinute > 30 && originHour < 23) ? 60 : 30);
      }
      return date;
    }

    handleClickDay(e) {
      const { date } = e;
      this.dialogFormVisible = true;
      const start = this.translateDefaultEventStartTime(`${date} ${dayjs().format('HH:mm')}`);
      this.createTemporaryEvent(start);
      const end = start.add(1, 'hour');
      this.resetForm({
        start_date: start.format('YYYY-MM-DD'),
        start_time: start.format('HH:mm'),
        end_date: end.format('YYYY-MM-DD'),
        end_time: end.format('HH:mm'),
      });
    }

    handleClickTime(e) {
      const { date, time } = e;
      this.dialogFormVisible = true;
      const start = this.translateDefaultEventStartTime(`${date} ${time}`, true);
      this.createTemporaryEvent(start);
      const end = start.add(1, 'hour');
      this.resetForm({
        start_date: start.format('YYYY-MM-DD'),
        start_time: start.format('HH:mm'),
        end_date: end.format('YYYY-MM-DD'),
        end_time: end.format('HH:mm'),
      });
    }

    /**
     * 创建临时event，等表单填写完成后，再创建正式event
     * 如果取消表单，需要删除当前临时event
     *
     * @param  {String} time event开始时间
     *
     */
    createTemporaryEvent(first) {
      const start = dayjs(first);
      const end = dayjs(first).add(1, 'hour');
      this.temporaryEvents = [
        {
          name: '(No title)',
          start: start.toDate(),
          end: end.toDate(),
          timed: true,
        }
      ];
    }

    handleCancelEventForm() {
      this.dialogFormVisible = false;
      this.temporaryEvents = [];
    }

    handleEventFormChange(data) {
      //  如果是新建，实时更新 calendar event
      if (this.temporaryEvents[0]) {
        const {
          start_date,
          start_time,
          end_date,
          end_time,
          is_all_day,
          title,
        } = data;
        const start = is_all_day ? new Date(start_date) : new Date(`${start_date} ${start_time}`);
        const end = is_all_day ? new Date(end_date) : new Date(`${end_date} ${end_time}`);
        Object.assign(this.temporaryEvents[0], {
          name: title || '(No title)',
          start,
          end,
          timed: !is_all_day,
        });
      }
    }

    handleClickDate(e) {
      this.currentDate = e.date;
      this.type = 'day';
    }

    handleClickEvent(eventData, e) {
      e.stopPropagation();
      this.eventDetail = eventData.event;
    }

    handleClickMore(eventData, e) {
      e.stopPropagation();
      this.handleClickDate(eventData);
    }

    /**
     * 日历上显示的天数范围已更改。这在初始化时触发。传递的事件是一个具有开始日期和结束日期对象的对象。
     *
     * 修改顶部 title 年月
     */
    handleCalendarChange({ start, end }) {
      const mouths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
      ];
      let monthText = '';
      if (start.year === end.year) {
        if (start.month === end.month) {
          monthText = mouths[start.month - 1];
        } else {
          monthText = `${mouths[start.month - 1]} - ${mouths[end.month - 1]}`;
        }
        this.currentCalendarTitle = `${monthText} ${start.year}`;
      } else {
        this.currentCalendarTitle = `${mouths[start.month - 1]} ${start.year} - ${mouths[end.month - 1]} ${end.year}`;
      }
    }

    @Vue.autoLoading
    async handleSubmit(body) {
      await calendarEventsStore.addAndUpdateEvent(body);
      // 需要先关闭form
      this.dialogFormVisible = false;
      calendarEventsStore.clearEvents();
      this.events = await calendarEventsStore.tryFetchData(this.currentDate);
      this.handleCancelEventForm();
    }

    async handleEditEvent() {
      this.form = _.cloneDeep(this.eventDetail);
      this.form.attendances_attributes = _.map(this.eventDetail.attendance_users, item => ({
        id: item.id,
        user_id: item.id
      }));
      this.eventDetail = {};
      await this.$nextTick();
      await sleep(200);
      this.dialogFormVisible = true;
    }

    @Vue.autoLoading
    async handleDeleteEvent() {
      const id = this.eventDetail.id;
      if (!id) {
        return;
      }
      await calendarEventsStore.deleteEvent(id);
      // 需要先关闭详情弹窗
      this.eventDetail = {};
      calendarEventsStore.clearEvents();
      this.events = await calendarEventsStore.tryFetchData(this.currentDate);
    }

    /**
     * 切换任务状态
     * @param {Object} task
     * @param {Boolean} reopen 是否重新打开
     */
    @Vue.autoLoading
    async handleToggleTaskStatus(task, reopen) {
      await calendarEventsStore.toggleTaskEventStatus(task, reopen);
      // 需要先关闭详情弹窗
      this.eventDetail = {};
      calendarEventsStore.clearEvents();
      this.events = await calendarEventsStore.tryFetchData(this.currentDate);
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    &.theme--light.v-application {
      background: transparent;
    }

    .v-application--wrap {
      min-height: initial;
    }
  }

  .actions-wrapper {
    @include media-xs-only {
      flex-direction: column;

      .action-items {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    ::v-deep {
      .el-select {
        width: 140px;

        @include media-xs-only {
          width: 110px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .el-button {
        + .el-button {
          margin-left: 10px;
        }
      }
    }

    .today-btn {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .current-calendar {
    margin-left: 10px;
  }

  .calendar-wrapper {
    box-sizing: border-box;
    height: calc(100vh - 300px);
    margin-top: 20px;

    ::v-deep {
      :after,
      * {
        box-sizing: inherit;
      }
    }
  }

  .event-slot {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .time {
      margin: 0 5px
    }

    .name {
      padding: 0 5px;
    }
  }

  .detail-dialog {
    ::v-deep {
      .el-dialog__body {
        padding-top: 0;
      }
    }
  }
</style>
