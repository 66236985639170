<template>
  <div class="help" :class="{ 'show-guide': guideHighlight }">
    <div v-if="showBig" class="mask" @click="showBig = false"></div>
    <div @click="showBig = true" class="box box-small" :class="showBig && 'box-large'">
      <transition name="fade">
        <div v-show="!showBig" class="help-container bs-flex bs-column">
          <img class="help-chat-icon hidden-xs-only" src="@/assets/help/Help-chat-icon.png" alt="">
          <span class="chat font-bold">Need Help?</span>
        </div>
      </transition>
      <div v-show="showBig" class="help-container">
        <div class="info-container">
          <div class="title">Need help?</div>
          <div class="content">
            <div v-for="(item, index) in helpList" :key="index" class="info bs-flex bs-item-center">
              <img class="info-icon" :src="item.icon" alt="">
              <div class="info-text">
                <component :is="item.isA ? 'a' : 'router-link'" :to="item.link" :href="item.link" :target="item.isA ? '_blank': ''">
                  {{ item.text }}
                </component>
                <!-- <span v-if="index === 0" class="or">or</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="close" @click.stop="showBig = false">
          <img src="@/assets/help/close-help.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Help extends Vue {
    @Prop(Boolean) guideHighlight

    showBig = false

    get helpList() {
      return [
        {
          icon: require('@/assets/help/help-icon1.png'),
          text: `Contact ${this.$get(this.$authStore, 'user.head_advisor.name')} on WhatsApp`,
          isA: true,
          link: this.$get(this.$authStore, 'user.head_advisor.whats_app_url') || 'https://api.whatsapp.com/send?phone=16465082593'
        },
        // { icon: require('@/assets/help/help-icon2.png'), text: 'Schedule a call with someone on your team', link: { name: 'schedule.new' }, isA: false },
      ];
    }
  }
</script>

<style lang="scss" scoped>
  .help {
    z-index: 999;

    &.show-guide {
      z-index: 1001;
    }
  }

  .box {
    position: fixed;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    border-radius: 50%;
    color: var(--font-primary-white);
    transform: translate(30%, 30%);

    @include media-xs-only {
      bottom: 100px;
      right: 5px;
    }

    &.box-small {
      width: 187px;
      height: 187px;
      font-size: 16px;
      background: var(--radial-gradient-purple);
      transition: all 1.5s;
      cursor: pointer;

      @include media-xs-only {
        width: 140px;
        height: 70px;
        border-radius: 67px;
      }
    }

    &.box-large {
      z-index: 1001;
      width: 900px;
      height: 900px;
      background: var(--linear-gradient-purple);
      transition: all 1.5s;
      cursor: default;

      @include media-xs-only {
        width: 550px;
        height: 550px;
        border-radius: 50%;
      }
    }

    .help-container {
      position: relative;
      padding-bottom: 150px;
      margin: 30px 40px;

      @include media-xs-only {
        padding-bottom: 0;
        margin: 15px;
      }

      .info-container {
        position: absolute;
        top: 200px;
        left: 100px;

        @include media-xs-only {
          top: 150px;
          left: 50px;
        }
      }

      .help-chat-icon {
        width: 50px;
        height: 50px;
        margin-top: 10px;
        margin-left: 15px;
        object-fit: cover;
      }

      .help-chat-icon + span {
        width: 90px;
      }

      .chat {
        width: 75px;
        overflow: hidden;
        line-height: 40px;
        text-overflow: ellipsis;
      }

      .title {
        font-size: 56px;
        white-space: nowrap;

        @include media-xs-only {
          font-size: 40px;
        }
      }

      .content {
        padding-top: 70px;
        font-size: 20px;

        @include media-xs-only {
          padding-top: 40px;
        }

        .info {
          &:not(:first-of-type) {
            margin-top: 80px;
          }

          .info-icon {
            width: 30px;
            height: 30px;
          }

          .info-text {
            position: relative;
            margin-left: 25px;
            text-decoration: underline;
            white-space: nowrap;
            cursor: pointer;

            @include media-xs-only {
              width: 300px;
              font-size: 14px;
            }
          }

          .or {
            position: absolute;
            top: 50px;
            left: 0;
            font-size: 16px;
            opacity: 0.5;
          }
        }
      }

      .close {
        position: absolute;
        bottom: -400px;
        left: 65%;
        padding: 10px;
        cursor: pointer;

        @include media-xs-only {
          left: 60%;
        }

        img {
          width: 13px;
          height: 13px;

          @include media-xs-only {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .fade-enter-active {
    animation: move-fade 1.5s;
  }

  @keyframes move-fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

</style>
