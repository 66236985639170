import { Collection } from './helper/collection';
import { request } from '@/utils';

export class RegionStore extends Collection {
  constructor (params) {
    super(params);
    const { Storage } = require('@/utils/storage.js');
    this.storage = new Storage(`${process.env.VUE_APP_ENV}_${params.type}_list`, 24 * 3600 * 1000 * 30);
  }

  async fetch(params) {
    const apiRequest = {
      country: { url: 'countries', id: 1 },
      state: { url: 'states', id: params.country_id_eq },
      city: { url: 'cities', id: params.state_id_eq }
    }[this.type];
    const res = { data: this.storage.get(apiRequest.id) || [], meta: {} };

    if (this.storage.get(apiRequest.id)) {
      return res;
    }

    if (apiRequest.id) {
      const { data, meta } = await request.get(apiRequest.url, { params: { ...params, page: 0 } });
      this.storage.set(apiRequest.id, data);
      return { data, meta };
    }

    return res;
  }
}

export const countriesStore = RegionStore.create({ type: 'country' });
export const statesStore = RegionStore.create({ type: 'state' });
export const citiesStore = RegionStore.create({ type: 'city' });
