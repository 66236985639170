<template>
  <div class="file-item">
    <span @click="showPopup = true" class="text text-overflow">
      {{ $get(file, 'attachment.filename') }}
    </span>
    <common-popup v-model="showPopup">
      <div class="popup-wrapper bs-flex bs-item-center bs-content-center bs-column">
        <div class="popup-content bs-flex bs-item-center bs-content-center">
          <img v-if="contentType === 'image'" :src="url"/>
          <video v-else-if="contentType === 'video'" :src="url" controls/>
          <audio v-else-if="contentType === 'audio'" :src="url" controls/>
          <span v-else class="text">{{ $get(file, 'attachment.filename') }}</span>
        </div>
        <div class="popup-footer bs-flex bs-content-center bs-item-center">
          <a class="file-btn" :href="url" target="_blank" v-if="contentType === 'pdf'" >
            <common-prod-button class="uppercase">Preview</common-prod-button>
          </a>
          <a class="file-btn" :href="`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`" target="_blank" v-if="contentType === 'officedocument'" >
            <common-prod-button class="uppercase">Preview</common-prod-button>
          </a>
          <!-- 图片无法使用a标签下载，改为通过 js 下载文件 -->
          <div class="file-btn" @click="handleDownload">
            <common-prod-button class="uppercase" :disabled="Boolean(progress)">{{ progress ? 'Downloading...' : 'Download' }}</common-prod-button>
          </div>
        </div>
        <div v-if="progress" class="download-progress-wrapper">
          <el-progress
            :percentage="Number(progress)"
          />
        </div>
      </div>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import download from 'downloadjs';
  import { randomString } from '@/utils';
  import urlJoin from 'url-join';

  @Component
  export default class FileItem extends Vue {
    @Prop({ type: Object, defaule: () => ({}) }) file

    showPopup = false
    progress = null

    get url() {
      return this.$get(this.file, 'attachment.url');
    }

    handleDownload() {
      if (this.progress) {
        return;
      }
      /**
       * 因为<img>标签加载了同样的图片，下载的时候，浏览器直接使用了缓存，但是该缓存的请求是不支持跨域的。
       * 添加一个随机参数，解决缓存问题
       * https://blog.csdn.net/dreamer2020/article/details/89322384
       * 注意：如果是 s3 链接，? 后面添加query参数可能会导致请求失败
       * 这里采用我们自己的域名链接，通过服务端端重定向， 所以采用的 query 参数
       */
      const ajax = download(urlJoin(this.url, `?t=${randomString()}`));
      if (ajax instanceof XMLHttpRequest) {
        const originOnError = ajax.onerror;
        const originOnLoadStart = ajax.onloadstart;
        const originOnProgress = ajax.onprogress;
        const originOnLoadend = ajax.onloadend;
        ajax.onerror = (e) => {
          this.progress = null;
          this.$alertMsg('Download failed!');
          originOnError && originOnError(e);
        };
        ajax.onloadstart = (e) => {
          this.progress = '0';
          originOnLoadStart && originOnLoadStart(e);
        };
        ajax.onprogress = (e) => {
          this.progress = (e.loaded / e.total * 100).toFixed();
          originOnProgress && originOnProgress(e);
        };
        ajax.onloadend = (e) => {
          this.progress = 100;
          setTimeout(() => {
            // 小文件让用户看到进度条变化过程
            this.progress = null;
          }, 5000);
          originOnLoadend && originOnLoadend(e);
        };
      }
    }

    get contentType() {
      const contentType = this.$get(this.file, 'attachment.content_type');
      if (/image/gi.test(contentType)) {
        return 'image';
      } else if (/video/gi.test(contentType)) {
        return 'video';
      } else if (/audio/gi.test(contentType)) {
        return 'audio';
      } else if (/officedocument/gi.test(contentType)) {
        return 'officedocument';
      } else if (/pdf/gi.test(contentType)) {
        return 'pdf';
      }
      return 'other';
    }
  }
</script>

<style lang="scss" scoped>
  .text {
    margin-right: 12px;
    text-decoration: underline;
    word-break: break-all;
    color: var(--font-primary-purple);
    cursor: pointer;

    @include media-xs-only {
      margin-right: 0;
    }
  }

  .popup-content {
    width: 100%;

    > video, audio, img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 16 / 9;
    }
  }

  .popup-footer {
    margin-top: 40px;

    .file-btn {
      margin: 0 10px;
    }
  }

  .download-progress-wrapper {
    width: 100%;
    margin-top: 20px;
  }
</style>
